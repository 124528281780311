import { DatePicker, DateValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';
import { InputAdornment, Stack, SxProps, TextField, Theme, Typography } from '@mui/material';

import { DateRange } from '@mui/x-date-pickers-pro/internals/models';
import { DateRangeEntry } from '../../application-details-page/accordions/modules/policy.component';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DateRangeValidationError } from '@mui/x-date-pickers-pro/models';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { parseISO } from 'date-fns';

interface Props {
    label: string;
    onChange: (value: DateRange<Date>, context: PickerChangeHandlerContext<DateRangeValidationError>) => void;
    value?: DateRangeEntry[];
    width?: string;
    smallField?: boolean;
    bold?: boolean;
    sx?: SxProps<Theme>;
}

export const SimpleDateRangeInput = ({
    label,
    onChange,
    value,
    width = '100%',
    smallField = false,
    bold = true,
    sx,
}: Props) => {
    return (
        <Stack direction="row" spacing={2} alignItems="center" width={width}>
            <Typography sx={{ fontWeight: bold ? '500' : undefined, width: smallField ? '70%' : '50%' }}>
                {label}
            </Typography>
            <DateRangePicker
                value={
                    value
                        ? [value[0] == null ? null : parseISO(value[0]), value[1] == null ? null : parseISO(value[1])]
                        : undefined
                }
                onChange={onChange}
                slots={{ field: SingleInputDateRangeField }}
                slotProps={{
                    layout: {
                        sx: {
                            '& .MuiDayCalendar-weekDayLabel': {
                                color: 'text.discrete',
                            },
                        },
                    },
                    textField: {
                        fullWidth: true,
                        size: 'small',
                        InputProps: {
                            sx: { backgroundColor: 'background.deep' },
                        },
                    },
                    day: {
                        sx: {
                            '&:disabled:not(.Mui-selected)': {
                                color: 'text.discrete',
                            },
                        },
                    },
                }}
                sx={{
                    color: '#CC5C5C',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderStyle: 'none',
                    },
                    label: {
                        color: 'text.primary',
                    },
                    ...sx,
                }}
            />
        </Stack>
    );
};
