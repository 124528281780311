import { Backdrop, Box, Button, CircularProgress, MenuItem, Paper, Stack, TextField, Typography } from '@mui/material';
import { orionApiUrl, setHeaders } from 'util/api/api.util';
import { useCallback, useEffect, useState } from 'react';

import { LoadingIndicator } from 'components/general/loading/loading-indicator.component';
import { LossRunsInformation } from './narrative-losses.component';
import { QuoteInformation } from './quote.component';
import { SimpleCheckboxInput } from '../../../add-application/field-components/simple-checkbox-input.component';
import { useParams } from 'react-router-dom';

export type QuotedPrice = {
    specific_deductible: number;
    corridor_deductible: number;
    include_loss_runs: boolean;
    licensed_premium: number;
    occupied_premium: number;
    imputed_loss_ratio: number;
};

export type ManualDebitResult = {
    quoted_rate: number;
    manual_debit_adjustment: number;
};

type Props = {
    index: number;
    price: QuotedPrice;
    licensed: boolean;
    occupied: boolean;
    providerNumber: string;
    claims: LossRunsInformation[];
    onPriceApplied: (
        price: number,
        lr_included: boolean,
        bedType: string,
        premium: number,
        lossRatio: number,
        manualDebit: number,
    ) => void;
};

export const PriceMatrixRowComponent = ({
    index,
    price,
    licensed,
    occupied,
    providerNumber,
    claims,
    onPriceApplied,
}: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [manualDebit, setManualDebit] = useState<number>(0);
    const [quotedRate, setQuotedRate] = useState<number>(0);
    const { id, facility_id } = useParams();

    const getData = useCallback(async () => {
        try {
            setLoading(true);
            const needsCalculation = price.imputed_loss_ratio > 0.43;

            if (!needsCalculation) {
                setManualDebit(0);
                setQuotedRate(price.licensed_premium + manualDebit);
                setLoading(false);

                return;
            }

            const { data } = await orionApiUrl.post<ManualDebitResult>(
                `/v1/calculator/manual_debit_adjustment?quoted_rate=${price.licensed_premium}&specific_deductible=${
                    price.specific_deductible
                }&corridor_deductible=${price.corridor_deductible}&target_loss_ratio=${0.43}`,
                claims,
                setHeaders(),
            );

            const correctedManualDebit = data.manual_debit_adjustment < 0 ? 0 : data.manual_debit_adjustment;

            setManualDebit(correctedManualDebit);
            setQuotedRate(data.quoted_rate + manualDebit);
            setLoading(false);
        } catch (error) {
            setLoading(false);

            console.log(JSON.stringify(error, null, 2));
        }
    }, [price, providerNumber]);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <>
            {(licensed || (!licensed && !occupied)) && (
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    sx={{
                        width: '100%',
                        height: '40px',
                        backgroundColor: licensed && !occupied && index % 2 != 0 ? '#1E242D' : undefined,
                    }}
                >
                    <Typography sx={{ textAlign: 'right', width: '15%', paddingX: '12px' }}>
                        ${' '}
                        {price.specific_deductible.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}
                    </Typography>
                    <Typography sx={{ textAlign: 'left', width: '10%', paddingX: '12px' }}>Licensed</Typography>
                    <Typography sx={{ textAlign: 'left', width: '10%', paddingX: '12px' }}>
                        {price.include_loss_runs ? 'Included' : 'Excluded'}
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: 'right',
                            width: '14%',
                            paddingLeft: '12px',
                            paddingRight: '3%',
                        }}
                    >
                        {price.imputed_loss_ratio == null ? '--' : `${(price.imputed_loss_ratio * 100).toFixed(0)}%`}
                    </Typography>
                    <Typography sx={{ textAlign: 'right', width: '10%', paddingX: '12px' }}>
                        ${' '}
                        {price.licensed_premium.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}
                    </Typography>
                    {loading && (
                        <Box sx={{ width: '10%' }}>
                            <CircularProgress size={'12px'} />
                        </Box>
                    )}
                    {!loading && (
                        <Typography sx={{ textAlign: 'right', width: '10%', paddingX: '12px' }}>
                            ${' '}
                            {manualDebit.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </Typography>
                    )}
                    {loading && (
                        <Box sx={{ width: '10%' }}>
                            <CircularProgress size={'12px'} />
                        </Box>
                    )}
                    {!loading && (
                        <Typography sx={{ textAlign: 'right', width: '10%', paddingX: '12px' }}>
                            ${' '}
                            {quotedRate.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </Typography>
                    )}
                    <Typography sx={{ textAlign: 'center', width: '10%', paddingX: '12px' }}>
                        <Button sx={{ marginY: '24px' }} disabled={loading}>
                            <Typography
                                fontWeight={500}
                                color="text.primary"
                                onClick={() =>
                                    onPriceApplied(
                                        price.specific_deductible,
                                        price.include_loss_runs,
                                        'Licensed',
                                        price.licensed_premium,
                                        price.imputed_loss_ratio,
                                        manualDebit,
                                    )
                                }
                            >
                                APPLY
                            </Typography>
                        </Button>
                    </Typography>
                </Stack>
            )}
            {(occupied || (!occupied && !licensed)) && (
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    sx={{
                        width: '100%',
                        height: '40px',
                        backgroundColor: occupied && !licensed && index % 2 == 0 ? undefined : '#1E242D',
                    }}
                >
                    <Typography sx={{ textAlign: 'right', width: '15%', paddingX: '12px' }}>
                        ${' '}
                        {price.specific_deductible.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}
                    </Typography>
                    <Typography sx={{ textAlign: 'left', width: '10%', paddingX: '12px' }}>Occupied</Typography>
                    <Typography sx={{ textAlign: 'left', width: '10%', paddingX: '12px' }}>
                        {price.include_loss_runs ? 'Included' : 'Excluded'}
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: 'right',
                            width: '14%',
                            paddingLeft: '12px',
                            paddingRight: '3%',
                        }}
                    >
                        {price.imputed_loss_ratio == null ? '--' : `${(price.imputed_loss_ratio * 100).toFixed(0)}%`}
                    </Typography>
                    <Typography sx={{ textAlign: 'right', width: '10%', paddingX: '12px' }}>
                        ${' '}
                        {price.licensed_premium.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}
                    </Typography>
                    {loading && (
                        <Box sx={{ width: '10%' }}>
                            <CircularProgress size={'12px'} />
                        </Box>
                    )}
                    {!loading && (
                        <Typography sx={{ textAlign: 'right', width: '10%', paddingX: '12px' }}>
                            ${' '}
                            {manualDebit.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </Typography>
                    )}
                    {loading && (
                        <Box sx={{ width: '10%' }}>
                            <CircularProgress size={'12px'} />
                        </Box>
                    )}
                    {!loading && (
                        <Typography sx={{ textAlign: 'right', width: '10%', paddingX: '12px' }}>
                            ${' '}
                            {quotedRate.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </Typography>
                    )}
                    <Typography sx={{ textAlign: 'center', width: '10%', paddingX: '12px' }}>
                        <Button sx={{ marginY: '24px' }}>
                            <Typography
                                fontWeight={500}
                                color="text.primary"
                                onClick={() =>
                                    onPriceApplied(
                                        price.specific_deductible,
                                        price.include_loss_runs,
                                        'Occupied',
                                        price.occupied_premium,
                                        price.imputed_loss_ratio,
                                        manualDebit,
                                    )
                                }
                            >
                                APPLY
                            </Typography>
                        </Button>
                    </Typography>
                </Stack>
            )}
        </>
    );
};
