import { InputAdornment, Stack, SxProps, TextField, Theme, Typography } from '@mui/material';

interface Props {
    value: string | undefined;
    id: string;
    placeholder: string;
    rows: number;
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    width?: string;
    smallField?: boolean;
    blue?: boolean;
    singleLine?: boolean;
    sx?: SxProps<Theme>;
}

export const SimpleTextBoxInput = ({
    id,
    placeholder,
    rows,
    value,
    onChange,
    width = '100%',
    blue = false,
    singleLine = false,
    sx,
}: Props) => {
    return (
        <Stack direction="row" spacing={2} alignItems="center" width={width}>
            <TextField
                fullWidth
                multiline={!singleLine}
                rows={singleLine ? undefined : rows}
                placeholder={placeholder}
                size="small"
                name={id}
                value={value}
                onChange={onChange}
                InputProps={{
                    sx: {
                        color: blue ? 'primary.main' : undefined,
                        backgroundColor: 'background.deep',
                    },
                }}
                sx={{
                    color: '#CC5C5C',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderStyle: 'none',
                    },
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                        display: 'none',
                    },
                    '& input[type=number]': {
                        MozAppearance: 'textfield',
                    },
                    ...sx,
                }}
            />
        </Stack>
    );
};
