import Leaflet from 'leaflet';
import ruralIcon from './svg/rural.svg';
import urbanIcon from './svg/urban.svg';

export const ruralFacilityMarker = new Leaflet.Icon({
    iconUrl: ruralIcon,
    iconSize: [16, 16],
});

export const urbanFacilityMarker = new Leaflet.Icon({
    iconUrl: urbanIcon,
    iconSize: [16, 16],
});
