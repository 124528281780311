import { ReactNode, createContext, useMemo, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';

import { darkPallette } from './colors/dark.palette';
import { lightPalette } from './colors/light.palette';

export const ColorContext = createContext({ toggleColorMode: () => {} });

type Props = {
    children: ReactNode;
};

export const OrionThemeProvider = ({ children }: Props) => {
    const [mode, setMode] = useState<'light' | 'dark'>('dark');
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode(previousMode => (previousMode === 'light' ? 'dark' : 'light'));
            },
        }),
        [],
    );

    const theme = useMemo(
        () =>
            createTheme({
                palette: mode === 'light' ? lightPalette : darkPallette,
            }),
        [mode],
    );

    return (
        <ColorContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ColorContext.Provider>
    );
};
