import { Box, SxProps, Theme } from '@mui/material';

import { ReactNode } from 'react';

type Props = {
    children: ReactNode;
    alt?: boolean;
    sx?: SxProps<Theme>;
};

export const CompactTableRowContainer = ({ children, alt, sx }: Props) => {
    return (
        <Box
            sx={{ borderRadius: '5px', padding: { xs: '10px' }, ...sx }}
            bgcolor={alt ? 'primary.gray' : 'background.paper'}
        >
            {children}
        </Box>
    );
};
