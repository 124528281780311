import { Box, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks/store.hook';

import Cookies from 'js-cookie';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import { MobileBar } from './mobile-bar.component';
import { authLogout } from 'store/features/auth/auth.slice';
import logo from 'assets/sidebar-logo.svg';
import { useNavigate } from 'react-router';
import { useState } from 'react';

function AppBar() {
    // This is gonna be used for the menu
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showMobileBar, setShowMobileBar] = useState<boolean>(false);

    // Handle navigate when you click on logo
    const navigate = useNavigate();

    const handleNavigateToMain = () => {
        navigate('/');
    };

    // Get state from redux
    const user = useAppSelector(st => st.auth.user);
    const dispatch = useAppDispatch();

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Options for menu
    const options = ['Logout'];

    // Responsible for menu bar
    const handleChange = () => {
        setShowMobileBar(st => !st);
    };

    // Handle logout
    const handleLogout = () => {
        setShowMobileBar(st => !st);
        dispatch(authLogout());
        Cookies.remove('token');
    };

    return (
        <>
            <MobileBar show={showMobileBar} handleChange={handleChange} />
            <Box bgcolor="background.default" px={1} position="fixed" top={0} width="100%" height="64px" zIndex={900}>
                <Stack direction="row" width="100%" height="100%" justifyContent="space-between" alignItems="center">
                    <IconButton
                        sx={{
                            display: {
                                sm: 'block',
                                md: 'none',
                            },
                        }}
                        onClick={handleChange}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ cursor: 'pointer' }} onClick={handleNavigateToMain}>
                        <img src={logo} alt="main-logo" />
                    </Box>
                    <Stack direction="row" alignItems="center">
                        <Typography
                            component="h4"
                            sx={{
                                fontSize: '16px',
                                display: {
                                    xs: 'none',
                                    md: 'block',
                                },
                            }}
                            color="text.primary"
                        >
                            Welcome {user?.first_name}
                        </Typography>
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                                sx: {
                                    backgroundImage: 'none',
                                    backgroundColor: 'primary.light',
                                },
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            {options.map(option => (
                                <MenuItem
                                    key={option}
                                    onClick={handleLogout}
                                    sx={{
                                        '&:hover': {
                                            color: 'common.black',
                                        },
                                        width: '150px',
                                    }}
                                >
                                    {option}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Stack>
                </Stack>
            </Box>
        </>
    );
}
export default AppBar;
