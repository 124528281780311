import { Checkbox, Stack, SxProps, Theme, Typography } from '@mui/material';
import { DatePicker, DateValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';

interface Props {
    value: boolean | undefined;
    id: string;
    label: string;
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    width?: string;
    smallField?: boolean;
    bold?: boolean;
    sx?: SxProps<Theme>;
    crossable?: boolean;
}

export const SimpleCheckboxInput = ({
    value,
    id,
    label,
    onChange,
    width = '100%',
    bold = true,
    crossable = false,
    sx,
}: Props) => {
    return (
        <Stack direction="row" spacing={2} alignItems="center" width={width}>
            <Checkbox
                size="medium"
                aria-label="test"
                name={id}
                checked={value}
                onChange={onChange}
                sx={{
                    color: 'background.default',
                    padding: '9px 0px',
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                }}
            />
            <Typography
                sx={{
                    fontWeight: bold ? '500' : undefined,
                    width: '50%',
                    textDecoration: crossable ? (value === true ? 'underline' : 'line-through') : undefined,
                    color: crossable && value !== true ? 'text.danger' : 'text.primary',
                    ...sx,
                }}
            >
                {label}
            </Typography>
        </Stack>
    );
};
