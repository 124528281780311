import {
    Box,
    InputAdornment,
    InputBaseComponentProps,
    Stack,
    SxProps,
    TextField,
    Theme,
    Typography,
} from '@mui/material';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { useEffect, useState } from 'react';

import { CurrencyInputSlot } from './currency-input-slot';
import React from 'react';

interface Props {
    value: string | number | undefined;
    id: string;
    label: string;
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onMaskedChange?: (key: string, value: string) => void;
    type?: 'numeric' | 'currency' | 'percentage' | 'none';
    width?: string;
    smallField?: boolean;
    bold?: boolean;
    blue?: boolean;
    readOnly?: boolean;
    rightAlign?: boolean;
    start_spacing?: string;
    end_spacing?: string;
    showMissing?: boolean;
    sx?: SxProps<Theme>;
}

export const SimpleInput = ({
    value,
    id,
    label,
    onChange,
    width = '100%',
    type = 'none',
    smallField = false,
    bold = true,
    blue = false,
    readOnly = false,
    rightAlign = false,
    start_spacing = '0%',
    end_spacing = '0%',
    showMissing = false,
    sx,
}: Props) => {
    const [maskValue, setMaskValue] = useState('');

    useEffect(() => {
        if (type === 'percentage' && value != null) {
            const numberValue = typeof value === 'string' ? parseFloat(value) : value;

            setMaskValue(isNaN(numberValue) ? '' : `${numberValue * 100}`);
        }
    }, [value, type]);

    function formatNumber(value: number | string | undefined) {
        if (value == null) return value;

        const numericValue = +value;

        if (isNaN(numericValue)) return value;

        if (type === 'currency') {
            return numericValue.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }

        return numericValue.toLocaleString('en-US');
    }

    return (
        <Stack direction="row" spacing={2} alignItems="center" width={width}>
            <Typography
                sx={{
                    fontWeight: bold ? '500' : undefined,
                    width: smallField ? '70%' : '50%',
                    color: blue ? 'primary.main' : undefined,
                }}
            >
                {label}
            </Typography>
            <Box sx={{ width: start_spacing }}></Box>
            {readOnly ? (
                <Typography
                    sx={{
                        fontWeight: bold ? '500' : undefined,
                        width: '50%',
                        paddingY: '8.5px',
                        color: blue ? 'primary.main' : undefined,
                        textAlign: rightAlign ? 'right' : undefined,
                    }}
                >
                    {type === 'currency' && '$ '}
                    {type === 'currency' ? formatNumber(value) : type === 'percentage' ? maskValue : value}
                    {type === 'percentage' && ' %'}
                </Typography>
            ) : (
                <TextField
                    fullWidth
                    size="small"
                    name={id}
                    value={type === 'percentage' ? maskValue : value}
                    onChange={onChange}
                    InputProps={{
                        inputComponent: type === 'currency' ? (CurrencyInputSlot as any) : undefined,

                        sx: {
                            color: blue ? 'primary.main' : undefined,
                            backgroundColor: 'background.deep',
                            paddingLeft: type !== 'currency' ? '6px' : undefined,
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <Typography sx={{ color: blue ? 'primary.main' : undefined }}>
                                    {type === 'currency' ? '$' : ''}
                                </Typography>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <Typography sx={{ color: blue ? 'primary.main' : undefined }}>
                                    {type === 'percentage' ? '%' : ''}
                                </Typography>
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        color: '#CC5C5C',
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderStyle: showMissing ? '1px solid' : 'none',
                            borderColor: '#CC5C5C',
                        },
                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                            display: 'none',
                        },
                        '& input[type=number]': {
                            MozAppearance: 'textfield',
                        },
                        '&:hover': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#CC5C5C',
                            },
                        },
                        input: { textAlign: rightAlign ? 'right' : undefined },
                        ...sx,
                    }}
                />
            )}
            <Box sx={{ width: end_spacing }}></Box>
        </Stack>
    );
};
