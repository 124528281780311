/* eslint-disable react/prop-types */
import { BarDatum, BarTooltipProps, ComputedDatum, ResponsiveBar } from '@nivo/bar';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { FunctionComponent, createContext, useContext, useEffect, useMemo, useState } from 'react';

import { AxisTickProps } from '@nivo/axes';
import { GraphTooltip } from 'components/pages/applications/specific-components/graph-tooltip.component';
import { Margin } from '@nivo/core';

interface TickContextType {
    data: BarDatum[];
    key: string;
}

const TickContext = createContext<TickContextType | undefined>(undefined);

const TickProvider: React.FC<{ children: React.ReactNode; value: TickContextType }> = ({ children, value }) => (
    <TickContext.Provider value={value}>{children}</TickContext.Provider>
);

export const useTickContext = () => {
    const context = useContext(TickContext);

    if (!context) {
        throw new Error('useTickContext must be used within a TickProvider');
    }

    return context;
};

const CustomTick = (tick: AxisTickProps<string>) => {
    const [empty, setEmpty] = useState<boolean>(false);
    const [previousYear, setPreviousYear] = useState<number>(0);
    const { data, key } = useTickContext();

    useEffect(() => {
        const target = data.find(entry => entry[key] == tick.value);

        if (target == null) return;

        const value = target['value'];

        if (typeof value != 'number') return;

        setPreviousYear(+tick.value - 1);

        setEmpty(target['provided'] === 'false');
    }, [data]);

    return (
        <Tooltip
            title={
                <div>
                    {`${previousYear}-${tick.value}*`}
                    <br />
                    No loss runs provided
                </div>
            }
            componentsProps={{
                tooltip: {
                    sx: {
                        fontSize: '12px',
                        padding: '8px',
                        width: '150px',
                        backgroundColor: 'primary.light',
                        borderStyle: 'solid',
                        borderRadius: '5px',
                        borderWidth: '1px',
                        borderColor: '#393E46',
                        position: 'absolute',
                        bottom: '10px',
                    },
                },
            }}
            disableHoverListener={!empty}
        >
            <g transform={`translate(${tick.x},${tick.y + 22})`}>
                <text
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{
                        fill: '#ffff',
                        fontFamily: 'Roboto',
                        fontSize: 12,
                    }}
                >
                    {tick.value}
                    {empty ? '*' : ''}
                </text>
            </g>
        </Tooltip>
    );
};

interface Props {
    data: BarDatum[];
    keys: string[];
    indexBy: string;
    scale: number | number[];
    tooltip: FunctionComponent<BarTooltipProps<any>>;
    onClick: (
        datum: ComputedDatum<BarDatum> & {
            color: string;
        },
        event: React.MouseEvent<SVGRectElement, MouseEvent>,
    ) => void;
    maxValue: number;
    minValue?: number;
    percentage?: boolean;
    margin?: Partial<Margin>;
    height?: string;
}

export const LossRunsBarChart = ({
    data,
    keys,
    indexBy,
    scale,
    tooltip,
    onClick,
    maxValue,
    minValue = 0,
    percentage = false,
    margin = { top: 30, left: 30, bottom: 30, right: 30 },
    height = '300px',
}: Props) => {
    return (
        <div style={{ width: '100%', height: height }}>
            <TickProvider value={{ data: data, key: indexBy }}>
                <ResponsiveBar
                    data={data}
                    keys={keys}
                    indexBy={indexBy}
                    padding={0.8}
                    innerPadding={5}
                    groupMode="grouped"
                    colors={['#7A94D7']}
                    onClick={onClick}
                    enableLabel={false}
                    enableGridX={false}
                    enableGridY={false}
                    margin={margin}
                    valueScale={{
                        type: 'linear',
                        max: maxValue,
                        min: minValue,
                    }}
                    axisLeft={{
                        format: percentage ? v => `${v}%` : undefined,
                        tickValues: scale,
                    }}
                    axisBottom={{
                        renderTick: CustomTick,
                    }}
                    tooltip={tooltip}
                    theme={{
                        axis: {
                            ticks: {
                                line: {
                                    stroke: 'transparent',
                                },
                                text: {
                                    fill: '#eee',
                                    fontFamily: 'Roboto',
                                    fontSize: '12px',
                                },
                            },
                        },
                    }}
                />
            </TickProvider>
        </div>
    );
};
