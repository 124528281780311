import { Tooltip, Typography } from '@mui/material';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack } from '@mui/system';

type Props = {
    label: string;
    tooltip: string;
    urban: number;
    rural: number;
    decimal?: boolean;
};

export const StateSummaryRow = ({ label, tooltip, urban, rural, decimal = true }: Props) => {
    return (
        <Stack direction="row-reverse">
            <Typography width="12%" sx={{ textAlign: 'end', color: 'primary.yellow' }}>
                {decimal ? urban.toFixed(2) : urban}
            </Typography>
            <Typography width="12%" sx={{ textAlign: 'end', color: 'primary.main' }}>
                {decimal ? rural.toFixed(2) : rural}
            </Typography>
            <Stack direction="row" width="76%" spacing={2} alignItems="center">
                <Typography>{label}</Typography>
                <Tooltip
                    disableFocusListener
                    disableTouchListener
                    title={<Typography>{tooltip}</Typography>}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                backgroundColor: 'primary.light',
                                border: '1px solid #393E46',
                                borderRadius: '10px',
                                padding: '10px',
                                fontSize: '14px',
                            },
                        },
                    }}
                >
                    <InfoOutlinedIcon
                        sx={{
                            fontSize: '16px',
                        }}
                    />
                </Tooltip>
            </Stack>
        </Stack>
    );
};
