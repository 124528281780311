import { Autocomplete, Box, InputAdornment, Stack, SxProps, TextField, Theme, Typography } from '@mui/material';
import { orionApiUrl, setHeaders } from 'util/api/api.util';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Search } from '@mui/icons-material';
import { useDebounce } from 'hooks/useDebounce';

interface Props {
    id: string;
    label: string;
    onChange: (event: React.SyntheticEvent<Element, Event>, newValue: undefined | FacilityInfo) => void;
    width?: string;
    smallField?: boolean;
    bold?: boolean;
    showMissing?: boolean;
    sx?: SxProps<Theme>;
}

export type FacilityInfo = {
    provider_number: string;
    facility_name: string;
    address: string;
    city: string;
    state_code: string;
    zipcode: string;
    licensed_beds: number;
    occupied_beds: number;
    overall_rating: number;
    has_pricing: boolean;
};

export const FacilitySearchInput = ({
    id,
    label,
    onChange,
    width = '100%',
    smallField = false,
    bold = true,
    showMissing = false,
    sx,
}: Props) => {
    const [options, setOptions] = useState<FacilityInfo[]>([]);
    const [value, setValue] = useState<FacilityInfo | undefined>(undefined);
    const [inputValue, setInputValue] = useState<string>('');
    const [searchLoading, setSearchLoading] = useState(false);
    const previousController = useRef<AbortController>();
    const debouncedSearch = useDebounce(inputValue, 300);

    const getData = useCallback(async (searchTerm: string) => {
        if (searchTerm.trim() === '') {
            setOptions([]);

            return;
        }

        try {
            if (previousController.current) {
                previousController.current.abort();
            }

            const controller = new AbortController();
            const signal = controller.signal;

            previousController.current = controller;
            setSearchLoading(true);

            const { data } = await orionApiUrl.get<FacilityInfo[]>(
                `/v1/facility/?underwrite=true&search=${searchTerm}`,
                setHeaders(),
            );

            setOptions(data.length > 0 ? data : []);

            setSearchLoading(false);
        } catch (error) {
            setSearchLoading(false);
            setOptions([]);
        }
    }, []);

    useEffect(() => {
        getData(debouncedSearch);
    }, [getData, debouncedSearch]);

    return (
        <Stack direction="row" spacing={2} alignItems="center" width={width}>
            <Typography sx={{ fontWeight: bold ? '500' : undefined, width: smallField ? '70%' : '50%' }}>
                {label}
            </Typography>
            <Box></Box>
            <Autocomplete
                fullWidth
                freeSolo
                id={id}
                getOptionLabel={(option: string | FacilityInfo) =>
                    typeof option === 'string' ? option : option.facility_name
                }
                value={value}
                inputValue={inputValue}
                filterOptions={x => x}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                noOptionsText={<Typography sx={{ color: 'text.primary' }}>No Results</Typography>}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        setInputValue(newValue);
                    } else if (newValue && newValue.provider_number) {
                        setValue(newValue);
                        onChange(event, newValue);
                    } else {
                        setValue(newValue);
                        onChange(event, newValue);
                    }
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                disableClearable={true}
                loading={searchLoading}
                loadingText={<Typography sx={{ color: 'text.primary' }}>Loading...</Typography>}
                renderOption={(props, option: FacilityInfo, selected) => (
                    <li {...props}>
                        <Typography sx={{ color: 'text.primary' }}>{option.facility_name}</Typography>
                    </li>
                )}
                renderInput={params => {
                    return (
                        <TextField
                            {...params}
                            size="small"
                            InputLabelProps={{
                                style: {
                                    color: 'background.paper',
                                    border: 'none',
                                    fontStyle: 'normal',
                                },
                            }}
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                sx: {
                                    backgroundColor: 'background.deep',
                                },
                                style: {
                                    border: showMissing ? '1px solid #CC5C5C' : 'none',
                                },
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <Search sx={{ color: 'text.primary' }} />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                backgroundColor: 'background.paper',
                                '&::placeholder': {
                                    color: 'text.discrete',
                                    fontStyle: 'normal !important',
                                    opacity: 1 /* Firefox */,
                                },

                                ':-ms-input-placeholder': {
                                    /* Internet Explorer 10-11 */ color: 'text.discrete',
                                    fontStyle: 'normal !important',
                                },

                                '::-ms-input-placeholder': {
                                    /* Microsoft Edge */ color: 'text.discrete',
                                    fontStyle: 'normal !important',
                                },

                                '& .MuiOutlinedInput-root': {
                                    '& ::placeholder': {
                                        fontStyle: 'normal',
                                    },
                                    '& fieldset': {
                                        border: 'none',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'common.white',
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: 'none',
                                    },
                                },
                            }}
                        />
                    );
                }}
            />
            <Box></Box>
        </Stack>
    );
};
