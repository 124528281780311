import { FacilityDataPoint, FacilityIndex } from 'types/underwriter/underwriter.type';
import { compareDesc, parseISO } from 'date-fns';

import { FacilityTrendResponse } from '../../types/underwriter.types';
import { IndexTable } from '../index-table.component';
import { useMemo } from 'react';

type Props = {
    trendData: FacilityTrendResponse[];
};

export const LostCostsTrends = ({ trendData }: Props) => {
    const data: FacilityIndex[] = useMemo(() => {
        if (trendData.length <= 0) return [];
        const indexes: FacilityIndex[] = [];
        const indexPoints: FacilityDataPoint[][] = [[], [], [], [], []];

        trendData.forEach(trend => {
            indexPoints[0].push({
                value: Number(trend.index_number_of_fines.toFixed(2)),
                date: trend.filedate,
            });
            indexPoints[1].push({
                value: Number(trend.index_total_amount_of_fines_in_dollars.toFixed(2)),
                date: trend.filedate,
            });
            indexPoints[2].push({
                value: Number(trend.index_number_of_payment_denials.toFixed(2)),
                date: trend.filedate,
            });
            indexPoints[3].push({
                value: Number(trend.total_number_of_penalties.toFixed(2)),
                date: trend.filedate,
            });
        });

        indexPoints[0].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[1].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[2].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[3].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));

        indexes.push(
            {
                label: 'Index Number of Fines',
                tooltip:
                    'The number of fines as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[0],
            },
            {
                label: 'Index Total Amount of Fines in Dollars',
                tooltip:
                    'The total amount of fines in dollars as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[1],
            },
            {
                label: 'Index Number of Payment Denials',
                tooltip:
                    'The number of payment denials as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[2],
            },
            {
                label: 'Index Total Number of Penalties',
                tooltip:
                    'The total number of penalties as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[3],
            },
        );

        return indexes;
    }, [trendData]);

    return <IndexTable title="Lost Costs" indexes={data} />;
};
