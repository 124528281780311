import { Autocomplete, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { FacilityData, StateData, StateFacilitySummary } from 'types/underwriter/underwriter.type';
import { FacilityLocationDetails, StateFacility } from './types/underwriter.types';
import { MockFacility, MockState } from './mock-data';
import { apiUrl, setHeadersNoAuth } from 'util/api/api.util';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Container } from 'components/general/layout/container.component';
import { FacilitySearchBar } from './facility-search.component';
import { PageTitle } from 'components/general/layout/page-title.component';
import { Search } from '@mui/icons-material';
import { SearchBar } from 'components/general/search-bar.component';
import { Stack } from '@mui/system';
import { UnderwriterFacility } from './facility-module/underwriter-facility.component';
import { UnderwriterState } from './state-module/underwriter-state.component';
import { UsState } from 'util/api/state-map.util';
import { useDebounce } from 'hooks/useDebounce';

//TODO:
// - Make index-row labels responsive
// - Make linechart left axis ticks responsive

const defaultFacility: FacilityLocationDetails = {
    id: '055002',
    name: 'Redlands Healthcare Center',
    address: '1620 West Fern Avenue, Redlands, CA 92373',
};

export const UnderwriterPage = () => {
    const [targetFacility, setFacility] = useState<FacilityLocationDetails>(defaultFacility);
    const handleFacilityChange = (newFacility: FacilityLocationDetails) => {
        setFacility(newFacility);
    };

    const handleFacilitySelected = (newFacility: StateFacility) => {
        setFacility({
            id: newFacility.provider_number,
            name: newFacility.facility_name,
            address: `${newFacility.address}, ${newFacility.city}, ${newFacility.state} ${newFacility.zipcode}`,
        });
    };

    return (
        <>
            <PageTitle text="Underwriter" />
            <Container>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Stack direction="column" spacing={2}>
                            <FacilitySearchBar onFacilityClick={handleFacilitySelected} />
                            <UnderwriterFacility facilityMetadata={targetFacility} />
                        </Stack>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <UnderwriterState state={UsState.CA} onFacilityClick={handleFacilityChange} />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};
