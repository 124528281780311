import { AssumptionData, QuoteStepAssumptions, defaultAssumptions } from './quote-step-assumptions.component';
import { Box, Stack } from '@mui/system';
import { Button, Step, StepButton, Stepper, Typography } from '@mui/material';
import { PriorLoss, QuoteStepClaims } from './quote-step-claims.component';
import { apiUrl, setHeadersNoAuth } from 'util/api/api.util';
import { useEffect, useState } from 'react';

import { QuoteStepQuote } from './quote-step-quote.component';
import { SubContainer } from 'components/general/layout/sub-container.component';

type Props = {
    facilityId: string;
    onCancel: () => void;
};

type QuoteData = {
    total_quoted_rate: number[];
    projected_loss_cost_per_bed: number[];
    quoted_per_bed_rate: number[];
    tax_rate: number[];
    tax_amount: number[];
    overhead_rate: number[];
    overhead_amount: number[];
    margin_rate: number[];
    margin_amount: number[];
    broker_fee_rate: number[];
    broker_fee_amount: number[];
    mga_fee_rate: number[];
    mga_fee_amount: number[];
    estimated_market_per_bed_rate: number[];
};

export type QuoteSummary = {
    total_quoted_rate: number;
    projected_loss_cost_per_bed: number;
    quoted_per_bed_rate: number;
    tax_rate: number;
    tax_amount: number;
    overhead_rate: number;
    overhead_amount: number;
    margin_rate: number;
    margin_amount: number;
    broker_fee_rate: number;
    broker_fee_amount: number;
    mga_fee_rate: number;
    mga_fee_amount: number;
    estimated_market_per_bed_rate: number;
};

export const QuoteStepper = ({ facilityId, onCancel }: Props) => {
    const [activeStep, setActiveStep] = useState(0);
    const [assumptions, setAssumptions] = useState<AssumptionData>(defaultAssumptions);
    const [claims, setClaims] = useState<PriorLoss[]>([]);
    const [quote, setQuote] = useState<QuoteSummary>();
    const [loading, setLoading] = useState<boolean>(false);
    const [nextEnabled, setNextEnabled] = useState<boolean>(false);

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    const handleNextStep = () => () => {
        if (activeStep == 2) onCancel();
        if (activeStep <= 1) setActiveStep(activeStep + 1);
    };

    const handleAssumptionsChange = (newData: AssumptionData) => {
        setAssumptions(newData);
    };

    const handleClaimsChange = (newData: PriorLoss[]) => {
        setClaims(newData);
    };

    useEffect(() => {
        if (activeStep === 0) {
            const canNext = assumptions.broker !== 'placeholder';

            setNextEnabled(canNext);
        }
    }, [activeStep, assumptions]);

    useEffect(() => {
        if (activeStep !== 2) return;
        const fetchData = async () => {
            try {
                setQuote(undefined);
                setLoading(true);

                const { data } = await apiUrl.get<QuoteData>(
                    `/underwrite_facility?&federal_provider_number=${facilityId}&broker=${
                        assumptions.broker
                    }&specific_deductible=${assumptions.specific_deductible}&agregate_deductible=${
                        assumptions.agregate_deductible
                    }&premium_tax=${assumptions.premium_tax / 100}&reinsurance_costs=${
                        assumptions.reinsurance_costs / 100
                    }&mga_fee=${assumptions.mga_fee / 100}&fronting_fee=${
                        assumptions.fronting_fee / 100
                    }&accelerant_fee=${assumptions.accelerant_fee / 100}&broker_fee=${assumptions.broker_fee / 100}`,
                    setHeadersNoAuth(),
                );

                setQuote({
                    total_quoted_rate: data.total_quoted_rate[0],
                    projected_loss_cost_per_bed: data.projected_loss_cost_per_bed[0],
                    quoted_per_bed_rate: data.quoted_per_bed_rate[0],
                    tax_rate: data.tax_rate[0],
                    tax_amount: data.tax_amount[0],
                    overhead_rate: data.overhead_rate[0],
                    overhead_amount: data.overhead_amount[0],
                    margin_rate: data.margin_rate[0],
                    margin_amount: data.margin_amount[0],
                    broker_fee_rate: data.broker_fee_rate[0],
                    broker_fee_amount: data.broker_fee_amount[0],
                    mga_fee_rate: data.mga_fee_rate[0],
                    mga_fee_amount: data.mga_fee_amount[0],
                    estimated_market_per_bed_rate: data.estimated_market_per_bed_rate[0],
                });
                setLoading(false);
            } catch (error) {
                console.log(JSON.stringify(error, null, 2));
                setLoading(true);
            }
        };

        fetchData();
    }, [activeStep]);

    return (
        <SubContainer
            sx={{
                width: {
                    xs: '90%',
                    sm: activeStep === 0 ? '75%' : activeStep === 2 ? '80%' : '90%',
                    md: activeStep === 0 ? '50%' : activeStep === 2 ? '60%' : '90%',
                    lg: activeStep === 0 ? '30%' : activeStep === 2 ? '40%' : '75%',
                },
                padding: '16px 0px 16px 32px',
            }}
        >
            <Stack direction="column" spacing={2}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h5">Quote</Typography>
                    <Box width={400}>
                        <Stepper
                            activeStep={activeStep}
                            nonLinear
                            alternativeLabel
                            sx={{
                                '& .MuiStepLabel-label': {
                                    color: 'text.discrete',
                                },
                                '& .MuiStepLabel-root .Mui-active': {
                                    color: 'divider',
                                },
                            }}
                        >
                            <Step
                                sx={{
                                    '& .MuiStepLabel-root .MuiSvgIcon-root': {
                                        fill: activeStep === 0 ? 'divider' : '#B0B0B0',
                                    },
                                }}
                            >
                                <StepButton onClick={handleStep(0)}>Assumptions</StepButton>
                            </Step>
                            <Step
                                disabled={!nextEnabled}
                                sx={{
                                    '& .MuiStepLabel-root .MuiSvgIcon-root': {
                                        fill: activeStep === 1 ? 'divider' : '#B0B0B0',
                                    },
                                }}
                            >
                                <StepButton onClick={handleStep(1)}>Claims</StepButton>
                            </Step>
                            <Step
                                disabled
                                sx={{
                                    '& .MuiStepLabel-root .MuiSvgIcon-root': {
                                        fill: activeStep === 2 ? 'divider' : '#B0B0B0',
                                    },
                                }}
                            >
                                <StepButton onClick={handleStep(2)}>Quote</StepButton>
                            </Step>
                        </Stepper>
                    </Box>
                </Stack>
                <div style={{ display: activeStep === 0 ? undefined : 'none' }}>
                    <QuoteStepAssumptions onChange={handleAssumptionsChange} />
                </div>
                <div style={{ display: activeStep === 1 ? undefined : 'none' }}>
                    <QuoteStepClaims onChange={handleClaimsChange} />
                </div>
                <div style={{ display: activeStep === 2 ? undefined : 'none' }}>
                    <QuoteStepQuote
                        loading={loading}
                        quote={quote}
                        claims={claims}
                        assumptions={assumptions}
                        onNext={() => {}}
                    />
                </div>
                <Stack direction="row" spacing={2}>
                    <Button variant="text" size="small" onClick={onCancel}>
                        CANCEL
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={handleNextStep()}
                        disabled={!nextEnabled}
                        sx={{
                            backgroundColor: 'primary.main',
                            '&:disabled': {
                                backgroundColor: 'background.paper',
                                color: 'text.disabled',
                                border: '1px solid',
                                borderColor: 'text.disabled',
                            },
                        }}
                    >
                        {activeStep === 0 ? 'NEXT' : activeStep === 1 ? 'QUOTE' : 'SAVE QUOTE'}
                    </Button>
                </Stack>
            </Stack>
        </SubContainer>
    );
};
