import AppBar from './nav-bar/app-bar.component';
import { Box } from '@mui/material';
import { Property } from 'csstype';
import { ReactNode } from 'react';
import { ResponsiveStyleValue } from '@mui/system/styleFunctionSx';
import { SideBar } from './side-bar/side-bar.component';
import { Stack } from '@mui/system';

type Props = {
    children: ReactNode;
    width?: ResponsiveStyleValue<Property.Width<string | number>>;
};

export const Layout = ({ children, width = '100%' }: Props) => {
    return (
        <Box width={width}>
            <AppBar />
            <Stack direction="row" mt="64px" width={width}>
                <SideBar />
                <Box
                    sx={{
                        padding: { xs: '20px 15px', md: '36px 32px' },
                        backgroundColor: 'primary.light',
                        color: 'text.primary',
                        width: width === '100%' ? '100vw' : width,
                        overflowX: 'auto',
                        minHeight: 'calc(100vh - 64px)',
                    }}
                >
                    {children}
                </Box>
            </Stack>
        </Box>
    );
};
