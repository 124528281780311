import './loading.styles.css';

import { Stack, Typography } from '@mui/material';

import spinnerLogo from 'assets/loading-spinner.svg';

type Props = {
    color?: string;
    text_hint?: string;
};

export const LoadingIndicator = ({ color, text_hint }: Props) => {
    return (
        <Stack
            direction="column"
            sx={{
                width: '100%',
                height: '100%',
                backgroundColor: color ? color : 'primary.light',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {text_hint && (
                <Typography variant="h5" sx={{ color: 'primary.main', paddingY: '12pt', fontStyle: 'bold' }}>
                    {text_hint}
                </Typography>
            )}
            <img src={spinnerLogo} alt="loading spinner" className="image" />
        </Stack>
    );
};
