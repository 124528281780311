import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Grid,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import {
    ApplicantInformation,
    ApplicantInformationComponent,
    extractApplicantInformation,
} from './modules/application-information.component';
import { ApplicationData, ApplicationQuoteSummary, FacilityInsuranceData } from '../application-details-page.component';
import {
    ApplicationLimitsComponent,
    LimitsInformation,
    extractLimitsInformation,
} from './modules/limits-liability.component';
import {
    ApplicationLossessComponent,
    LossRunsInformation,
    LossessInformation,
} from './modules/narrative-losses.component';
import { ApplicationPolicyComponent, PolicyInformation } from './modules/policy.component';
import { ApplicationQuoteComponent, QuoteInformation, extractQuoteInformation } from './modules/quote.component';
import { orionApiUrl, setHeaders } from 'util/api/api.util';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import { handleError } from 'util/error/handleError';

type Props = {
    data: ApplicationData;
    setLoadingState: (loadingState: boolean, hintIndicator: string) => void;
    onChange: () => void;
    onNoKnownLoss: () => void;
};

const defaultLossess: LossessInformation = {
    is_over_4_claims_per_year: false,
    claims: [],
};

type PatchDataType = Omit<FacilityInsuranceData, 'claims' | 'facility_provider_number'> & {
    id?: string;
    application_id?: string;
};

export const UnderwriterApplicationAccordion = ({ data, setLoadingState, onChange, onNoKnownLoss }: Props) => {
    const [open, setOpen] = useState<boolean>(false);
    const [applicantInfo, setApplicantInfo] = useState<ApplicantInformation>();
    const [quote, setQuote] = useState<QuoteInformation>();
    const [losses, setLosses] = useState<LossessInformation>(defaultLossess);

    const [policy, setPolicy] = useState<PolicyInformation>();
    const [combinedQuote, setCombinedQuote] = useState<ApplicationQuoteSummary>();
    const [limits, setLimits] = useState<LimitsInformation>({});
    const [claim_limit_policy, setClaim_limit_policy] = useState<number | undefined>();
    const { id, facility_id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (data.facilities.length <= 0) return;

        const targetFacility = data.facilities.find(facility => {
            return facility.id == facility_id;
        });

        if (!targetFacility) return;

        setApplicantInfo(extractApplicantInformation(targetFacility));
        setQuote(extractQuoteInformation(targetFacility));
        setLosses({
            is_over_4_claims_per_year: targetFacility.is_over_4_claims_per_year,
            notes: targetFacility.notes,
            claims: targetFacility.claims,
            //PDF ONLY
            historical_losses_incurred: targetFacility.historical_losses_incurred,
            historical_policy_duration_months: targetFacility.historical_policy_duration_months,
            historical_losses_incurred_over_deductible: targetFacility.historical_losses_incurred_over_deductible,
            historical_premium_paid: targetFacility.historical_premium_paid,
        });
        setLimits(extractLimitsInformation(targetFacility));
        setCombinedQuote(data.quote_summary);
        setClaim_limit_policy(data.claim_limit_policy);
    }, [data, facility_id]);

    const deleteData = async () => {
        try {
            setLoadingState(true, 'Removing Facility');

            const deleteRequest = await orionApiUrl.delete(`/v1/application/${id}`, setHeaders());

            if (deleteRequest.status != 200) throw Error(`Unable to delete facility ${facility_id}`);
            setLoadingState(false, '');
            onChange();
        } catch (error) {
            handleError(error, () => {
                setLoadingState(false, '');
                console.log(JSON.stringify(error, null, 2));
            });
        }
    };

    const updateData = async (
        priceOverride: number | undefined = undefined,
        lrOverride: boolean | undefined = undefined,
        bedOverride: string | undefined = undefined,
        premiumOverride: number | undefined = undefined,
        lossRatioOverride: number | undefined = undefined,
        manualDebitOverride: number | undefined = undefined,
    ) => {
        try {
            setLoadingState(true, 'Saving Changes');
            const parsedApplicantInfo: ApplicantInformation = applicantInfo != undefined ? applicantInfo : {};
            const parsedQuote: QuoteInformation = quote != undefined ? quote : {};
            const parsedLimits: LimitsInformation = limits != undefined ? limits : {};
            const parsedPolicy: PolicyInformation = policy != undefined ? policy : {};

            delete parsedApplicantInfo.facility_provider_number;
            delete parsedQuote.facility_state_code;
            delete parsedQuote.prior_year_number_of_incidents;
            delete parsedQuote.prior_year_number_of_claims;
            delete parsedQuote.prior_year_total_settlement_amount;
            delete parsedQuote.prior_year_average_settlement_amount;
            delete parsedQuote.first_claim_date;
            delete parsedQuote.total_settlement_amount;
            delete parsedQuote.total_settlement_amount;
            delete parsedQuote.total_number_of_claims;
            delete parsedQuote.average_settlement_amount;
            delete parsedQuote.max_settlement_amount;
            delete parsedQuote.max_number_of_claims_per_year;
            const dataUpdates: PatchDataType[] = [
                {
                    ...parsedQuote,
                    ...parsedApplicantInfo,
                    ...parsedLimits,
                    manual_debit: manualDebitOverride != null ? manualDebitOverride : quote?.manual_debit,
                    specific_deductible: priceOverride != null ? priceOverride : quote?.specific_deductible,
                    bed_type: bedOverride != null ? bedOverride : quote?.bed_type,
                    include_loss_runs: lrOverride != null ? lrOverride : quote?.include_loss_runs,
                    quoted_rate: premiumOverride != null ? premiumOverride : quote?.quoted_rate,
                    is_over_4_claims_per_year: losses.is_over_4_claims_per_year,
                    imputed_loss_ratio: lossRatioOverride != null ? lossRatioOverride : quote?.imputed_loss_ratio,
                    notes: losses.notes,
                    id: facility_id,
                    application_id: id,
                    //PDF ONLY
                    historical_losses_incurred: losses.historical_losses_incurred,
                    historical_policy_duration_months: losses.historical_policy_duration_months,
                    historical_losses_incurred_over_deductible: losses.historical_losses_incurred_over_deductible,
                    historical_premium_paid: losses.historical_premium_paid,
                },
            ];
            const { data: patchedData } = await orionApiUrl.patch<FacilityInsuranceData>(
                `/v1/application/${id}/facilities`,
                dataUpdates,
                setHeaders(),
            );

            const { data: patchedApplication } = await orionApiUrl.patch<ApplicationData>(
                `/v1/application/${id}`,
                {
                    ...policy,
                    claim_limit_policy: claim_limit_policy,
                },
                setHeaders(),
            );

            setLoadingState(false, '');
            onChange();
        } catch (error) {
            handleError(error, () => {
                setLoadingState(false, '');
                console.log(JSON.stringify(error, null, 2));
            });
        }
    };

    return (
        <Grid item xs={12}>
            <Accordion
                square={false}
                expanded={open}
                sx={{
                    borderRadius: '5px',
                    padding: 0,
                    backgroundColor: 'background.default',
                    width: '100%',
                    '& .MuiAccordion-root:before': {
                        backgroundColor: 'unset',
                    },
                }}
            >
                <AccordionSummary>
                    <Stack direction="row" spacing={2} sx={{ width: '100%' }} alignItems="center">
                        <Stack
                            direction="column"
                            onClick={() => {
                                setOpen(!open);
                            }}
                        >
                            <Typography variant="h6">Underwriter</Typography>
                            {!open && (
                                <Typography>
                                    Applicant information, quote per facility, combined quote, narrative of losses and
                                    limits of liability
                                </Typography>
                            )}
                        </Stack>
                        {open && data.facilities.length > 0 && (
                            <Stack>
                                <TextField
                                    select
                                    value={facility_id}
                                    onChange={event => {
                                        const { value } = event.target;

                                        if (value == 'add_new_facility') {
                                            navigate(`/applications/${id}/new`);

                                            return;
                                        }

                                        const newValue = parseInt(value);

                                        if (isNaN(newValue)) return;

                                        navigate(`/applications/${id}/${newValue}`);
                                    }}
                                    InputProps={{
                                        sx: {
                                            height: '30px',
                                            backgroundColor: 'background.deep',
                                        },
                                    }}
                                    sx={{
                                        height: '30px',
                                        color: '#CC5C5C',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderStyle: 'none',
                                        },
                                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                            display: 'none',
                                        },
                                        '& input[type=number]': {
                                            MozAppearance: 'textfield',
                                        },
                                    }}
                                    label=""
                                >
                                    <MenuItem disabled value="placeholder">
                                        <Typography
                                            sx={{
                                                color: 'text.discrete',
                                                fontStyle: 'oblique',
                                            }}
                                        >
                                            Facility
                                        </Typography>
                                    </MenuItem>
                                    {data.facilities.map((facility: FacilityInsuranceData, index: number) => (
                                        <MenuItem value={facility.id} key={index}>
                                            <Stack direction="row">
                                                <Typography>
                                                    {facility.facility_name ?? `Facility #${index + 1}`}
                                                </Typography>
                                            </Stack>
                                        </MenuItem>
                                    ))}
                                    <MenuItem value="add_new_facility">
                                        <Stack direction="row">
                                            <Typography>+ Add New Facility</Typography>
                                        </Stack>
                                    </MenuItem>
                                </TextField>
                            </Stack>
                        )}
                        <Box
                            onClick={() => {
                                setOpen(!open);
                            }}
                            sx={{ flexGrow: 1, height: '100%' }}
                        />
                        <Typography
                            sx={{
                                color: '#EEEEEE',
                            }}
                            onClick={() => {
                                setOpen(!open);
                            }}
                        >
                            {!open && <ExpandMoreIcon />}
                            {open && <ExpandLessIcon />}
                        </Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        {applicantInfo && (
                            <ApplicantInformationComponent data={applicantInfo} onChange={setApplicantInfo} />
                        )}
                        {applicantInfo && applicantInfo.facility_provider_number && losses && (
                            <ApplicationLossessComponent
                                data={losses}
                                onNoKnownLosses={onNoKnownLoss}
                                onChange={setLosses}
                                providerNumber={applicantInfo?.facility_provider_number}
                                refreshLoss={() => updateData()}
                            />
                        )}
                        {applicantInfo && limits && applicantInfo.facility_provider_number && quote && (
                            <ApplicationQuoteComponent
                                providerNumber={applicantInfo.facility_provider_number}
                                facilityName={applicantInfo.facility_name ?? `Facility #${facility_id}`}
                                data={quote}
                                claims={losses.claims}
                                onChange={setQuote}
                                onPriceApplied={(price, lr_included, bed, premium, lossRatio, manualDebit) => {
                                    setQuote({ ...quote, specific_deductible: price, manual_debit: manualDebit });
                                    updateData(price, lr_included, bed, premium, lossRatio, manualDebit);
                                }}
                            />
                        )}
                        {policy && <ApplicationPolicyComponent data={policy} onChange={setPolicy} />}
                        {limits && combinedQuote && (
                            <ApplicationLimitsComponent
                                facility_state_code={
                                    data.facilities.length <= 0
                                        ? 'N/A'
                                        : data.facilities[0].facility_state_code ?? 'N/A'
                                }
                                quoteData={combinedQuote}
                                data={limits}
                                onChange={setLimits}
                                claim_limit_policy={claim_limit_policy}
                                onPolicyChange={setClaim_limit_policy}
                            />
                        )}
                        <Grid item xs={12}>
                            <Stack direction="row" sx={{ width: '100%' }} justifyContent="center" spacing={2}>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    onClick={() => updateData()}
                                    sx={{ backgroundColor: 'primary.main' }}
                                >
                                    <Stack direction="row" alignItems="center">
                                        <SaveIcon sx={{ mr: 1 }} />
                                        <Typography sx={{ fontWeight: 500 }}>SAVE</Typography>
                                    </Stack>
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};
