import { InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { InputFieldNumeric } from './inputs/field-numeric.component';
import { InputFieldSelect } from './inputs/field-select.component';
import { Stack } from '@mui/system';

type Props = {
    onChange: (data: AssumptionData) => void;
};

export type AssumptionData = {
    broker: string;
    specific_deductible: number;
    agregate_deductible: number;
    margin: number;
    premium_tax: number;
    reinsurance_costs: number;
    mga_fee: number;
    fronting_fee: number;
    accelerant_fee: number;
    broker_fee: number;
};

export const defaultAssumptions: AssumptionData = {
    broker: 'placeholder',
    specific_deductible: 100000,
    agregate_deductible: 300000,
    margin: 0,
    premium_tax: 6,
    reinsurance_costs: 19,
    mga_fee: 15,
    fronting_fee: 5,
    accelerant_fee: 9,
    broker_fee: 10,
};

export const QuoteStepAssumptions = ({ onChange }: Props) => {
    const [formData, setFormData] = useState<AssumptionData>(defaultAssumptions);

    const handleSelectFieldChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
        const { name, value } = event.target;

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleNumericFieldChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
        const { name, value } = event.target;
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (regex.test(value)) {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    useEffect(() => {
        onChange(formData);
    }, [formData]);

    return (
        <Stack direction="column" spacing={2} alignItems="start" sx={{ paddingRight: '32px' }}>
            <Typography>Assumptions</Typography>
            <Stack direction="row" width="100%">
                <InputFieldSelect
                    fullWidth
                    value={formData.broker}
                    id="broker"
                    label="Broker"
                    onChange={handleSelectFieldChange}
                >
                    <MenuItem disabled value="placeholder">
                        <Typography sx={{ color: 'text.discrete', fontStyle: 'oblique' }}>Select a Broker</Typography>
                    </MenuItem>
                    <MenuItem value="propel">Propel</MenuItem>
                    <MenuItem value="hum">HUB</MenuItem>
                    <MenuItem value="shomer">Shomer</MenuItem>
                    <MenuItem value="omni">Omni</MenuItem>
                    <MenuItem value="grandview">Grandview</MenuItem>
                </InputFieldSelect>
            </Stack>
            <Stack direction="row" spacing={2} width="100%">
                <InputFieldNumeric
                    value={formData.specific_deductible}
                    id="specific_deductible"
                    type="currency"
                    label="Specific Deductible"
                    onChange={handleNumericFieldChange}
                    sx={{ width: '50%' }}
                />
                <InputFieldNumeric
                    value={formData.agregate_deductible}
                    id="agregate_deductible"
                    type="currency"
                    label="Aggregate Deductible"
                    onChange={handleNumericFieldChange}
                    sx={{ width: '50%' }}
                />
            </Stack>
            <Stack direction="row" spacing={2} width="100%">
                {/* <InputFieldNumeric
                    value={formData.margin}
                    id="margin"
                    type="percentage"
                    label="Margin"
                    onChange={handleNumericFieldChange}
                    sx={{ width: '50%' }}
                /> */}
                <InputFieldNumeric
                    value={formData.broker_fee}
                    id="broker_fee"
                    type="percentage"
                    label="Broker Fee"
                    onChange={handleNumericFieldChange}
                    sx={{ width: '50%' }}
                />
                <InputFieldNumeric
                    value={formData.premium_tax}
                    id="premium_tax"
                    type="percentage"
                    label="Premium Tax"
                    onChange={handleNumericFieldChange}
                    sx={{ width: '50%' }}
                />
            </Stack>
            <Stack direction="row" spacing={2} width="100%">
                <InputFieldNumeric
                    value={formData.reinsurance_costs}
                    id="reinsurance_costs"
                    type="percentage"
                    label="Reinsurance Costs"
                    onChange={handleNumericFieldChange}
                    sx={{ width: '50%' }}
                />
                <InputFieldNumeric
                    value={formData.mga_fee}
                    id="mga_fee"
                    type="percentage"
                    label="MGA Free"
                    onChange={handleNumericFieldChange}
                    sx={{ width: '50%' }}
                />
            </Stack>
            <Stack direction="row" spacing={2} width="100%">
                <InputFieldNumeric
                    value={formData.fronting_fee}
                    id="fronting_fee"
                    type="percentage"
                    label="Fronting Fee"
                    onChange={handleNumericFieldChange}
                    sx={{ width: '50%' }}
                />
                <InputFieldNumeric
                    value={formData.accelerant_fee}
                    id="accelerant_fee"
                    type="percentage"
                    label="Accelerant Fee"
                    onChange={handleNumericFieldChange}
                    sx={{ width: '50%' }}
                />
            </Stack>
            {/* <Stack direction="row" spacing={2} width="100%">
                <InputFieldNumeric
                    value={formData.broker_fee}
                    id="broker_fee"
                    type="percentage"
                    label="Broker Fee"
                    onChange={handleNumericFieldChange}
                    sx={{ width: '50%' }}
                />
            </Stack> */}
        </Stack>
    );
};
