import { Component, ErrorInfo, ReactNode } from 'react';

import { ErrorPage } from 'components/pages/error/error-page.component';

type Props = {
    children?: ReactNode;
};

type State = {
    hasError: boolean;
};

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(_: Error): State {
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo);
        console.log(error.message);
    }

    public eraseError = () => {
        this.setState({ hasError: false });
    };

    render(): ReactNode {
        if (this.state.hasError) {
            return <ErrorPage eraseError={this.eraseError} />;
        } else {
            return this.props.children;
        }
    }
}

export default ErrorBoundary;
