import { SxProps, Typography } from '@mui/material';

type Props = {
    danger?: boolean;
    label: string;
    italic?: boolean;
    mt?: boolean;
    sx?: SxProps;
};

export const BlockText = ({ danger, label, italic = false, mt, sx }: Props) => {
    return (
        <Typography
            sx={{
                fontSize: '14px',
                color: danger ? 'text.danger' : 'text.primary',
                fontStyle: italic ? 'italic' : 'normal',
                mt: mt ? 1 : 0,
                ...sx,
            }}
        >
            {label}
        </Typography>
    );
};
