export const lightPalette = {
    common: {
        black: '#000',
        white: '#fff',
    },

    primary: {
        main: '#7A94D7',
        light: 'rgb(223, 223, 223)',
    },
    secondary: {
        main: '#000',
    },
    background: {
        paper: '#fff',
        default: '#c7c5c5',
    },
    divider: '#FFD369',
    text: {
        primary: '#000',
        secondary: '#eeeeee',
        danger: '#CC5C5C',
    },
    action: {
        hover: '#FFD369',
    },
};
