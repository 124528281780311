import { DatePicker, DateValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';
import { InputAdornment, SxProps, TextField, Theme, Typography } from '@mui/material';

interface Props {
    value: string | undefined;
    onChange: (value: string | null, context: PickerChangeHandlerContext<DateValidationError>) => void;
    disableFuture?: boolean;
    sx?: SxProps<Theme>;
    altBorder?: boolean;
    disableManualInput?: boolean;
}

export const InputFieldDate = ({
    value,
    onChange,
    disableFuture = false,
    altBorder = false,
    disableManualInput = false,
    sx,
}: Props) => {
    return (
        <>
            <DatePicker
                disableFuture={disableFuture}
                value={value === undefined ? null : value}
                onChange={onChange}
                slotProps={{
                    field: {
                        readOnly: disableManualInput,
                    },
                    day: {
                        sx: {
                            '&:disabled:not(.Mui-selected)': {
                                color: 'text.discrete',
                            },
                        },
                    },
                }}
                sx={{
                    color: '#CC5C5C',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: altBorder ? '#393E46' : 'primary.main',
                    },
                    label: {
                        color: 'text.primary',
                    },
                    ...sx,
                }}
            />
        </>
    );
};
