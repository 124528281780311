import { Button, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { ApplicationQuoteSummary } from '../../application-details-page.component';
import { InputFieldSelect } from 'components/pages/underwriter/facility-module/quote-dialogue/inputs/field-select.component';
import { SimpleDateInput } from '../../../add-application/field-components/simple-date-input.component';
import { SimpleInput } from '../../../add-application/field-components/simple-input.component';
import { SubContainer } from 'components/general/layout/sub-container.component';
import { ValuePercentageInput } from 'components/pages/applications/add-application/field-components/value-percentage-input.component';

export type LimitsInformation = {
    claim_limit?: number;
    claim_limit_annual?: number;
    medical_pay?: number;
    professional_general_deductible?: number;
    employee_benefits_deductible?: number;
};

export function extractLimitsInformation(data: unknown): LimitsInformation {
    const applicantInformation: Partial<LimitsInformation> = {};
    const validKeys: Array<keyof LimitsInformation> = [
        'claim_limit',
        'claim_limit_annual',
        'medical_pay',
        'professional_general_deductible',
        'employee_benefits_deductible',
    ];

    if (typeof data === 'object' && data !== null) {
        for (const key of validKeys) {
            if (key in data) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'unknown'.
                applicantInformation[key] = data[key];
            }
        }
    }

    return applicantInformation as LimitsInformation;
}

type Props = {
    data: LimitsInformation;
    claim_limit_policy: number | undefined;
    quoteData: ApplicationQuoteSummary;
    facility_state_code: string;
    onChange: (changes: LimitsInformation) => void;
    onPolicyChange: (change: number | undefined) => void;
};

export const ApplicationLimitsComponent = ({
    data,
    claim_limit_policy,
    quoteData,
    facility_state_code,
    onChange,
    onPolicyChange,
}: Props) => {
    const handleFieldChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
        const { name, value } = event.target;
        const parsedValue = value.trim().length <= 0 ? null : value;

        onChange({
            ...data,
            [name]: parsedValue,
        });
    };

    useEffect(() => {
        onChange(data);
    }, [data]);

    const handlePolicyChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
        const { name, value } = event.target;
        const parsedValue = value.trim().length <= 0 ? null : value;

        onPolicyChange(+(parsedValue || 0));
    };

    useEffect(() => {
        onChange(data);
    }, [data]);

    return (
        <>
            <Grid
                item
                xs={12}
                lg={6}
                sx={{
                    '& .MuiBox-root': {
                        height: '100%',
                    },
                }}
            >
                <SubContainer
                    sx={{
                        padding: '20px 30px',
                    }}
                >
                    <Stack direction="column" spacing={1}>
                        <Stack direction="row" alignItems="center">
                            <Typography variant="h6" sx={{ flexGrow: '1' }}>
                                Limits of Liability
                            </Typography>
                        </Stack>
                        <Grid container rowGap={4}>
                            <Grid item xs={12}>
                                <Stack direction="column" spacing={1}>
                                    <Typography sx={{ fontWeight: 500 }}>Healthcare General Liability</Typography>
                                    <SimpleInput
                                        bold={false}
                                        smallField
                                        rightAlign
                                        type="currency"
                                        label="Each Claim Limit"
                                        id="claim_limit"
                                        value={data.claim_limit}
                                        start_spacing="40%"
                                        end_spacing="40%"
                                        width="100%"
                                        onChange={handleFieldChange}
                                    />
                                    <SimpleInput
                                        bold={false}
                                        smallField
                                        rightAlign
                                        type="currency"
                                        label="Annual Aggregate Limit"
                                        id="claim_limit_annual"
                                        value={data.claim_limit_annual}
                                        start_spacing="40%"
                                        end_spacing="40%"
                                        width="100%"
                                        onChange={handleFieldChange}
                                    />
                                    <SimpleInput
                                        bold={false}
                                        smallField
                                        rightAlign
                                        type="currency"
                                        label="Medical Pay"
                                        id="medical_pay"
                                        value={data.medical_pay}
                                        start_spacing="40%"
                                        end_spacing="40%"
                                        width="100%"
                                        onChange={handleFieldChange}
                                    />
                                    <SimpleInput
                                        bold={false}
                                        smallField
                                        rightAlign
                                        type="currency"
                                        label="Policy Aggregate Limit"
                                        id="claim_limit_policy"
                                        value={claim_limit_policy}
                                        start_spacing="40%"
                                        end_spacing="40%"
                                        width="100%"
                                        onChange={handlePolicyChange}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="column" spacing={1}>
                                    <Typography sx={{ fontWeight: 500 }}>Deductible</Typography>
                                    <SimpleInput
                                        bold={false}
                                        smallField
                                        rightAlign
                                        type="currency"
                                        label="Professional/General Liability"
                                        id="professional_general_deductible"
                                        value={data.professional_general_deductible}
                                        start_spacing="40%"
                                        end_spacing="40%"
                                        width="100%"
                                        onChange={handleFieldChange}
                                    />
                                    <SimpleInput
                                        bold={false}
                                        smallField
                                        rightAlign
                                        type="currency"
                                        label="Employee Benefits Liability"
                                        id="employee_benefits_deductible"
                                        value={data.employee_benefits_deductible}
                                        start_spacing="40%"
                                        end_spacing="40%"
                                        width="100%"
                                        onChange={handleFieldChange}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                </SubContainer>
            </Grid>
            <Grid item lg={6} xs={12}>
                <SubContainer sx={{ padding: '20px 30px' }}>
                    <Stack direction="column" spacing={1}>
                        <Stack direction="row" alignItems="center">
                            <Typography variant="h6" sx={{ flexGrow: '1' }}>
                                Combined Quote (All Facilities)
                            </Typography>
                        </Stack>
                        <Grid container gap={5} sx={{ paddingTop: '12px' }}>
                            <Grid item xs={12}>
                                <Stack direction="column" spacing={1}>
                                    <SimpleInput
                                        readOnly
                                        blue
                                        bold={false}
                                        smallField
                                        rightAlign
                                        type="currency"
                                        label="Combined Quote Rate"
                                        id="claim_limit"
                                        value={quoteData.total_manual_debit + quoteData.total_quoted_rate}
                                        onChange={handleFieldChange}
                                        width="70%"
                                    />
                                    <SimpleInput
                                        readOnly
                                        bold={false}
                                        smallField
                                        rightAlign
                                        type="currency"
                                        label="Combined Manual Debit"
                                        id="claim_limit"
                                        value={quoteData.total_manual_debit}
                                        onChange={handleFieldChange}
                                        width="70%"
                                    />
                                    <ValuePercentageInput
                                        smallField
                                        readonly
                                        bold={false}
                                        label="Combined Margin"
                                        percentageId="margin_rate"
                                        valueType="currency"
                                        percentageType="percentage"
                                        value={quoteData.total_margin}
                                        percentageValue={quoteData.margin_rate}
                                        onPercentageChange={handleFieldChange}
                                        onPercentageMaskedChange={() => {}}
                                    />
                                    <ValuePercentageInput
                                        smallField
                                        readonly
                                        bold={false}
                                        label="Combined Broker Fee"
                                        percentageId="broker_fee_rate"
                                        valueType="currency"
                                        percentageType="percentage"
                                        value={quoteData.total_broker_fee}
                                        percentageValue={quoteData.broker_fee_rate}
                                        onPercentageChange={handleFieldChange}
                                        onPercentageMaskedChange={() => {}}
                                    />
                                    <ValuePercentageInput
                                        smallField
                                        readonly
                                        bold={false}
                                        label="Combined MGA Fee"
                                        percentageId="mga_fee_rate"
                                        valueType="currency"
                                        percentageType="percentage"
                                        value={quoteData.total_mga_fee}
                                        percentageValue={quoteData.mga_fee_rate}
                                        onPercentageChange={handleFieldChange}
                                        onPercentageMaskedChange={() => {}}
                                    />
                                    {/* Missing state tax rate */}
                                    <ValuePercentageInput
                                        smallField
                                        readonly
                                        bold={false}
                                        label="Combined State Tax"
                                        percentageId="total_state_tax"
                                        valueType="currency"
                                        percentageType="percentage"
                                        value={quoteData.total_state_tax}
                                        percentageValue={quoteData.state_tax_rate}
                                        onPercentageChange={handleFieldChange}
                                        onPercentageMaskedChange={() => {}}
                                    />
                                    <ValuePercentageInput
                                        smallField
                                        readonly
                                        bold={false}
                                        label="Combined Stamp Fee Tax"
                                        percentageId="total_stamp_fee"
                                        valueType="currency"
                                        percentageType="percentage"
                                        value={quoteData.total_stamp_fee}
                                        percentageValue={quoteData.stamp_fee_rate}
                                        onPercentageChange={handleFieldChange}
                                        onPercentageMaskedChange={() => {}}
                                    />
                                    {/* Missing total MCare for PA facilities*/}
                                    <ValuePercentageInput
                                        width="100%"
                                        smallField
                                        readonly
                                        bold={false}
                                        label="Combined Technology Fee"
                                        percentageId="mga_fee_rate"
                                        valueType="currency"
                                        percentageType="percentage"
                                        value={quoteData.total_technology_fee}
                                        percentageValue={quoteData.technology_fee_rate}
                                        onPercentageChange={handleFieldChange}
                                        onPercentageMaskedChange={() => {}}
                                    />
                                    {facility_state_code === 'PA' && (
                                        <SimpleInput
                                            readOnly
                                            bold={false}
                                            smallField
                                            rightAlign
                                            type="currency"
                                            label="Combined MCare"
                                            id="total_mcare_fee"
                                            value={quoteData.total_mcare_fee}
                                            onChange={handleFieldChange}
                                            width="70%"
                                        />
                                    )}
                                    <SimpleInput
                                        readOnly
                                        bold={false}
                                        smallField
                                        rightAlign
                                        type="currency"
                                        label="Combined TRIA (not included in quote or total)"
                                        id="total_tria_fee"
                                        value={quoteData.total_tria_fee}
                                        onChange={handleFieldChange}
                                        width="70%"
                                    />
                                    <SimpleInput
                                        readOnly
                                        rightAlign
                                        bold={false}
                                        smallField
                                        type="currency"
                                        label="Combined Total Cost"
                                        id="claim_limit"
                                        value={
                                            quoteData.total_manual_debit +
                                            quoteData.total_quoted_rate +
                                            quoteData.total_state_tax +
                                            quoteData.total_stamp_fee +
                                            quoteData.total_technology_fee +
                                            quoteData.total_mcare_fee
                                        }
                                        width="70%"
                                        onChange={handleFieldChange}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                </SubContainer>
            </Grid>
        </>
    );
};
