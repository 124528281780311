import { Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { authLogin, authLogout } from 'store/features/auth/auth.slice';
import axios, { AxiosError } from 'axios';
import { useAppDispatch, useAppSelector } from 'store/hooks/store.hook';
import { useEffect, useRef, useState } from 'react';

import Cookies from 'js-cookie';
import { UserResponse } from 'types/user.interface';
import logo from 'assets/logo.svg';
import { orionApiUrl } from 'util/api/api.util';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export const LoginPage = () => {
    const boxRef = useRef<HTMLDivElement>();
    const user = useAppSelector(st => st.auth.user);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (user) navigate('/');
    }, [navigate, user]);

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string | null>();
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const error = validateErrors();

        if (error !== '') {
            setError(error);

            return;
        }
        try {
            setLoading(true);
            setError(null);
            const token = await orionApiUrl.post(
                '/v1/auth/token',
                {
                    username: email.trim(),
                    password: password.trim(),
                },
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                },
            );

            const { data: user } = await orionApiUrl.get<UserResponse>('/v1/user/me/', {
                headers: {
                    Authorization: `Bearer ${token.data.access_token}`,
                },
            });

            Cookies.set(
                'token',
                JSON.stringify({
                    token: token.data.access_token,
                }),
            );

            setLoading(false);
            dispatch(authLogin(user));
            navigate('/home');
        } catch (e) {
            const error = e as AxiosError | Error;

            if (axios.isAxiosError(error)) {
                if (error && error.response) {
                    setError(error.response.data.detail);
                    setLoading(false);
                    Cookies.remove('token');
                    dispatch(authLogout());
                } else {
                    setLoading(false);
                    Cookies.remove('token');
                    dispatch(authLogout());
                    toast.error(error.message);
                }
            } else {
                setLoading(false);
                Cookies.remove('token');
                dispatch(authLogout());
                toast.error(error.message);
            }
        }
    };

    const validateErrors = (): string => {
        if (!email.trim().includes('@') || password.trim().length < 8) {
            return 'Email or password is incorrect';
        }

        return '';
    };

    return (
        <Stack
            width="100%"
            direction="column"
            justifyContent="start"
            minHeight="100vh"
            sx={{
                backgroundColor: 'primary.light',
            }}
            p={3}
        >
            <Stack
                width="100%"
                minHeight="100%"
                direction="column"
                justifyContent="center"
                alignItems="center"
                bgcolor={'background.primary'}
                spacing="50px"
            >
                <Box sx={{ width: { xs: '50%', md: '25%', lg: '10%' } }} ref={boxRef}>
                    <img src={logo} style={{ display: 'block', width: '100%', height: '100%' }} alt="Logo" />
                </Box>
                <Box
                    mt={4}
                    width={{ xs: '100%', md: '480px' }}
                    sx={{ backgroundColor: 'background.default' }}
                    borderRadius={3}
                    py={{ xs: 2, md: 5 }}
                    px={{ xs: 2, md: 8 }}
                    color="text.primary"
                >
                    <Typography
                        textAlign="center"
                        sx={{
                            fontSize: { xs: '24px', md: '24px' },
                            mb: 4,
                        }}
                    >
                        Welcome back
                    </Typography>
                    <Typography fontSize="16px" mb="25px">
                        Please enter your details
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            value={email}
                            onChange={({ target }) => {
                                setEmail(target.value);
                            }}
                            type="text"
                            size="small"
                            fullWidth
                            label="Email"
                            placeholder="Enter your email"
                            InputLabelProps={{
                                style: { color: '#fff' },
                                shrink: true,
                            }}
                            sx={{
                                marginBottom: '32px',
                                '&::placeholder': {
                                    color: '#fff !important',
                                },
                                '& label': {
                                    color: 'text.primary',
                                },
                                '& label.Mui-focused': {
                                    color: 'text.primary',
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: 'yellow',
                                },
                                '&:-webkit-autofill': {
                                    transitionDelay: '9999s',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'divider',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'divider',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'divider',
                                    },
                                },
                            }}
                        />
                        <TextField
                            value={password}
                            onChange={({ target }) => setPassword(target.value)}
                            type="password"
                            size="small"
                            fullWidth
                            label="Password"
                            placeholder="Enter your password"
                            InputLabelProps={{ shrink: true }}
                            sx={{
                                marginBottom: '32px',
                                '& label': {
                                    color: 'text.primary',
                                },
                                '& label.Mui-focused': {
                                    color: 'text.primary',
                                },
                                '&::placeholder': {
                                    fontStyle: 'normal',
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: 'yellow',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'divider',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'divider',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'divider',
                                    },
                                },
                            }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={email.trim().length === 0 || password.trim().length === 0 || loading}
                            sx={{
                                backgroundColor: 'divider',
                                color: 'text.secondary',
                                borderRadius: '100px',
                                '&:disabled': {
                                    backgroundColor: 'action.inactive',
                                    color: 'text.discrete',
                                },
                            }}
                        >
                            {!loading ? 'Login' : <CircularProgress size="20px" />}
                        </Button>
                        {error && (
                            <Typography mt="12px" textAlign="center" color="text.danger">
                                {error}
                            </Typography>
                        )}
                        <Button
                            variant="text"
                            size="small"
                            fullWidth
                            sx={{
                                color: 'text.primary',
                                fontSize: '16px',
                                textAlign: 'center',
                                textTransform: 'capitalize',
                                fontWeight: '400',
                                mt: { xs: !error ? '32px' : '16px' },
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                            component="label"
                        >
                            Forgot Password
                        </Button>
                    </form>
                </Box>
            </Stack>
        </Stack>
    );
};
