import { Datum, ResponsiveLine } from '@nivo/line';

import { SubContainer } from 'components/general/layout/sub-container.component';
import { Typography } from '@mui/material';

type Props = {
    id: string;
    leftAxisSteps: number;
    height: number;
    data: Datum[];
};

export const CompactLineChart = ({ id, leftAxisSteps, height, data }: Props) => {
    return (
        <div style={{ width: '100%', height: height }}>
            <ResponsiveLine
                data={[{ id: id, data: data, color: '#7A94D7' }]}
                yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false }}
                xScale={{
                    type: 'time',
                    format: '%Y-%m-%d',
                    precision: 'month',
                }}
                axisLeft={{
                    format: value => {
                        return String(value);
                    },
                    tickValues: leftAxisSteps,
                    tickPadding: 5,
                }}
                enableSlices="x"
                enableCrosshair={false}
                sliceTooltip={({ slice }) => {
                    const value = slice.points[0].data.yFormatted;

                    return (
                        <SubContainer minimal sx={{ padding: '5px' }}>
                            <Typography>
                                {typeof value === 'number'
                                    ? value.toFixed(2)
                                    : !isNaN(Number(value))
                                    ? Number(value).toFixed(2)
                                    : value}
                            </Typography>
                        </SubContainer>
                    );
                }}
                xFormat={value => {
                    const date = new Date(value);
                    const quarter = Math.floor(date.getMonth() / 3) + 1; // Calculate the quarter
                    const year = date.getFullYear();

                    return `Q${quarter} ${year}`; // Display as "Q1 2023", "Q2 2023", etc.
                }}
                axisBottom={{
                    format: value => {
                        const date = new Date(value);
                        const quarter = Math.floor(date.getMonth() / 3) + 1; // Calculate the quarter
                        const year = date.getFullYear();

                        return (
                            <>
                                <tspan x="0" dy="1em">
                                    Q{quarter}
                                </tspan>
                                <tspan x="0" dy="1em">
                                    {year}
                                </tspan>
                            </>
                        );
                    },
                    tickPadding: 10,
                    tickValues: 'every 3 months',
                }}
                margin={{ top: 10, left: 40, bottom: 60, right: 30 }}
                enableGridX={false}
                enableGridY={false}
                colors={d => d.color}
                enablePoints={false}
                theme={{
                    axis: {
                        ticks: {
                            line: {
                                stroke: 'transparent',
                            },
                            text: {
                                fill: '#eee',
                                fontFamily: 'Roboto',
                            },
                        },
                    },
                }}
            />
        </div>
    );
};
