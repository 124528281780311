import { Box, Typography } from '@mui/material';
import { FacilityLocationDetails, StateFacility, StateSummary } from '../types/underwriter.types';
import Leaflet, { LatLngExpression } from 'leaflet';
import { MapContainer, Tooltip as MapTooltip, Marker, TileLayer } from 'react-leaflet';
import { StateData, StateFacilitySummary, StateStatEntry } from 'types/underwriter/underwriter.type';
import { UsState, getUsState } from 'util/api/state-map.util';
import { apiUrl, setHeadersNoAuth } from 'util/api/api.util';
import { ruralFacilityMarker, urbanFacilityMarker } from '../markers/markers';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { CompactTableRowContainer } from '../facility-module/compact-table-row-container.component';
import { LoadingIndicator } from 'components/general/loading/loading-indicator.component';
import { Stack } from '@mui/system';
import { StateSummaryRow } from './state-summary-row.component';
import { SubContainer } from 'components/general/layout/sub-container.component';

type Props = {
    state: UsState;
    onFacilityClick: (newFacility: FacilityLocationDetails) => void;
};

export const UnderwriterState = ({ state, onFacilityClick }: Props) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [stateSummaries, setStateSummaries] = useState<StateSummary[]>([]);
    const [facilities, setFacilities] = useState<StateFacility[]>([]);

    const mapData = useMemo(() => {
        const stateData = getUsState(state);

        const bounds = Leaflet.latLngBounds(
            Leaflet.latLng(stateData.bounds.south, stateData.bounds.west),
            Leaflet.latLng(stateData.bounds.north, stateData.bounds.east),
        );
        const center: LatLngExpression = [
            (stateData.bounds.north + stateData.bounds.south) / 2,
            stateData.bounds.west + stateData.bounds.east,
        ];

        return {
            bounds: bounds,
            center: center,
        };
    }, [state]);

    const getData = useCallback(async () => {
        try {
            setLoading(true);

            const { data: summaryData } = await apiUrl.get<StateSummary[]>(
                `/get_state_summary?input_state=${state}`,
                setHeadersNoAuth(),
            );
            const { data: stateFacilities } = await apiUrl.get<StateFacility[]>(
                `/list_states_underwritable_facilities?state_code=${state}`,
                setHeadersNoAuth(),
            );

            setStateSummaries(summaryData);
            setFacilities(stateFacilities);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        getData();
    }, [state, getData]);

    return (
        <>
            <Stack>
                <SubContainer>
                    {(stateSummaries.length <= 0 || facilities.length <= 0 || loading) && (
                        <Stack direction="column" justifyItems="center" sx={{ width: '100%', height: '200px' }}>
                            <LoadingIndicator />
                        </Stack>
                    )}
                    {stateSummaries.length > 0 && facilities.length > 0 && !loading && (
                        <Stack direction="column" spacing={2}>
                            <Typography>{getUsState(state).name}</Typography>
                            <Box sx={{ width: '100%', height: 400, borderRadius: '5px' }}>
                                <MapContainer
                                    zoomControl={false}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        margin: 0,
                                        padding: 0,
                                    }}
                                    zoom={7}
                                    maxZoom={12}
                                    minZoom={5}
                                    maxBounds={mapData.bounds}
                                    center={mapData.center}
                                >
                                    <TileLayer url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png" />
                                    {facilities.map((facility: StateFacility, index: number) => {
                                        if (facility.latitude === undefined || facility.longitude === undefined) {
                                            return;
                                        }

                                        return (
                                            <Marker
                                                key={facility.provider_number + index}
                                                position={[facility.latitude, facility.longitude]}
                                                icon={
                                                    facility.geo_class === 'Urban'
                                                        ? urbanFacilityMarker
                                                        : ruralFacilityMarker
                                                }
                                                eventHandlers={{
                                                    click: e => {
                                                        onFacilityClick({
                                                            id: facility.provider_number,
                                                            name: facility.facility_name,
                                                            address: `${facility.address}, ${facility.city}, ${state} ${facility.zipcode}`,
                                                        });
                                                    },
                                                }}
                                            >
                                                <MapTooltip direction={'top'}>
                                                    {facility.facility_name} <br />{' '}
                                                    {`${facility.address}, ${facility.city}, ${state} ${facility.zipcode}`}
                                                </MapTooltip>
                                            </Marker>
                                        );
                                    })}
                                </MapContainer>
                            </Box>
                            <Stack direction="column">
                                <CompactTableRowContainer>
                                    <Stack direction="row-reverse">
                                        <Typography width="12%" sx={{ textAlign: 'end', color: 'primary.yellow' }}>
                                            Rural
                                        </Typography>
                                        <Typography width="12%" sx={{ textAlign: 'end', color: 'primary.main' }}>
                                            Urban
                                        </Typography>
                                    </Stack>
                                </CompactTableRowContainer>
                                <CompactTableRowContainer alt={true}>
                                    <StateSummaryRow
                                        label="State Number Facilities"
                                        tooltip="The number facilities as reported by CMS for facilities in the state."
                                        decimal={false}
                                        rural={stateSummaries[1].state_number_facilities}
                                        urban={stateSummaries[0].state_number_facilities}
                                    />
                                </CompactTableRowContainer>
                                <CompactTableRowContainer alt={false}>
                                    <StateSummaryRow
                                        label="State Total Beds"
                                        tooltip="The total beds as reported by CMS for facilities in the state."
                                        decimal={false}
                                        rural={stateSummaries[1].state_total_beds}
                                        urban={stateSummaries[0].state_total_beds}
                                    />
                                </CompactTableRowContainer>
                                <CompactTableRowContainer alt={true}>
                                    <StateSummaryRow
                                        label="State Average Number of Beds"
                                        tooltip="The average number beds as reported by CMS for facilities in the state."
                                        rural={stateSummaries[1].state_avg_number_beds}
                                        urban={stateSummaries[0].state_avg_number_beds}
                                    />
                                </CompactTableRowContainer>
                                <CompactTableRowContainer alt={false}>
                                    <StateSummaryRow
                                        label="State Average Occupancy"
                                        tooltip="The average occupancy as reported by CMS for facilities in the state."
                                        rural={stateSummaries[1].state_avg_occupancy}
                                        urban={stateSummaries[0].state_avg_occupancy}
                                    />
                                </CompactTableRowContainer>
                                <CompactTableRowContainer alt={true}>
                                    <StateSummaryRow
                                        label="State Average Overall Rating"
                                        tooltip="The average overall rating as reported by CMS for facilities in the state."
                                        rural={stateSummaries[1].state_avg_overall_rating}
                                        urban={stateSummaries[0].state_avg_overall_rating}
                                    />
                                </CompactTableRowContainer>
                                <CompactTableRowContainer alt={false}>
                                    <StateSummaryRow
                                        label="State Average Health Inspection Rating"
                                        tooltip="The average health inspection rating as reported by CMS for facilities in the state."
                                        rural={stateSummaries[1].state_avg_health_inspection_rating}
                                        urban={stateSummaries[0].state_avg_health_inspection_rating}
                                    />
                                </CompactTableRowContainer>
                                <CompactTableRowContainer alt={true}>
                                    <StateSummaryRow
                                        label="State Average Quality Measure (QM) Rating"
                                        tooltip="The average quality measure rating as reported by CMS for facilities in the state."
                                        rural={stateSummaries[1].state_avg_qm_rating}
                                        urban={stateSummaries[0].state_avg_qm_rating}
                                    />
                                </CompactTableRowContainer>
                                <CompactTableRowContainer alt={false}>
                                    <StateSummaryRow
                                        label="State Average Staffing Rating"
                                        tooltip="The average staffing rating as reported by CMS for facilities in the state."
                                        rural={stateSummaries[1].state_avg_staffing_rating}
                                        urban={stateSummaries[0].state_avg_staffing_rating}
                                    />
                                </CompactTableRowContainer>
                                <CompactTableRowContainer alt={true}>
                                    <StateSummaryRow
                                        label="State Average Nurse Aid Daily Per Resident Hours"
                                        tooltip="The average nurse aid daily per resident hours as reported by CMS for facilities in the state."
                                        rural={stateSummaries[1].state_avg_nurse_aid_daily_per_resident_hours}
                                        urban={stateSummaries[0].state_avg_nurse_aid_daily_per_resident_hours}
                                    />
                                </CompactTableRowContainer>
                                <CompactTableRowContainer alt={false}>
                                    <StateSummaryRow
                                        label="State Average LPN Daily Per Resident Hours"
                                        tooltip="The average LPN daily per resident hours as reported by CMS for facilities in the state."
                                        rural={stateSummaries[1].state_avg_lpn_daily_per_resident_hours}
                                        urban={stateSummaries[0].state_avg_lpn_daily_per_resident_hours}
                                    />
                                </CompactTableRowContainer>
                                <CompactTableRowContainer alt={true}>
                                    <StateSummaryRow
                                        label="State Average RN Daily Per Resident Hours"
                                        tooltip="The average RN daily per resident hours as reported by CMS for facilities in the state."
                                        rural={stateSummaries[1].state_avg_rn_daily_per_resident_hours}
                                        urban={stateSummaries[0].state_avg_rn_daily_per_resident_hours}
                                    />
                                </CompactTableRowContainer>
                                <CompactTableRowContainer alt={false}>
                                    <StateSummaryRow
                                        label="State Average Total Nurse Daily Per Resident Hours"
                                        tooltip="The average total nurse daily per resident hours as reported by CMS for facilities in the state."
                                        rural={stateSummaries[1].state_avg_total_nurse_daily_per_resident_hours}
                                        urban={stateSummaries[0].state_avg_total_nurse_daily_per_resident_hours}
                                    />
                                </CompactTableRowContainer>
                                <CompactTableRowContainer alt={true}>
                                    <StateSummaryRow
                                        label="State Average Total Nurse Daily Per Resident Hours (Weekend)"
                                        tooltip="The average total nurse daily per resident hours weekend as reported by CMS for facilities in the state."
                                        rural={stateSummaries[1].state_avg_total_nurse_daily_per_resident_hours_weekend}
                                        urban={stateSummaries[0].state_avg_total_nurse_daily_per_resident_hours_weekend}
                                    />
                                </CompactTableRowContainer>
                                <CompactTableRowContainer alt={false}>
                                    <StateSummaryRow
                                        label="State Average RN Daily Per Resident Hours (Weekend)"
                                        tooltip="The average RN daily per resident hours weekend as reported by CMS for facilities in the state."
                                        rural={stateSummaries[1].state_avg_rn_daily_per_resident_hours_weekend}
                                        urban={stateSummaries[0].state_avg_rn_daily_per_resident_hours_weekend}
                                    />
                                </CompactTableRowContainer>
                                <CompactTableRowContainer alt={true}>
                                    <StateSummaryRow
                                        label="State Average Months Since Last Survey"
                                        tooltip="The average months since last survey as reported by CMS for facilities in the state."
                                        rural={stateSummaries[1].state_avg_months_since_last_survey}
                                        urban={stateSummaries[0].state_avg_months_since_last_survey}
                                    />
                                </CompactTableRowContainer>
                            </Stack>
                        </Stack>
                    )}
                </SubContainer>
            </Stack>
        </>
    );
};
