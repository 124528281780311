import { IconButton, Stack, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import { orionApiUrl, setHeaders } from 'util/api/api.util';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { FacilityInsuranceData } from '../../application-details-page/application-details-page.component';
import { FileInformation } from '../../application-details-page/accordions/modules/files.component';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { handleError } from 'util/error/handleError';
import { useMemo } from 'react';

interface Props {
    value: FileInformation[];
    facilities: FacilityInsuranceData[];
    attachment_type: string;
    label: string;
    onChange: (newValue: FileInformation[]) => void;
    setLoading: (loading: boolean, message: string) => void;
    width?: string;
    smallField?: boolean;
    readonly?: boolean;
    bold?: boolean;
    sx?: SxProps<Theme>;
}

export const FilesDisplay = ({
    value,
    facilities,
    attachment_type,
    label,
    onChange,
    setLoading,
    width = '100%',
    bold = true,
    readonly = false,
    sx,
}: Props) => {
    const filesToDisplay: FileInformation[] = useMemo(() => {
        const validFiles: FileInformation[] = value.filter(file => file.attachment_type === attachment_type);

        return validFiles;
    }, [value, attachment_type]);

    const getFacilityName = (search: string) => {
        for (const facility of facilities) {
            if (facility.facility_provider_number == search) {
                return facility.facility_name;
            }
        }

        return search;
    };

    const deleteAttachment = async (file: FileInformation) => {
        try {
            setLoading(true, 'Removing File...');

            await orionApiUrl.delete(`/v1/attachment/${file.id}`, setHeaders());

            const newAttachments = value.filter(attachment => attachment.id !== file.id);

            onChange(newAttachments);

            setLoading(false, 'Removing File...');
        } catch (error) {
            handleError(error, () => {
                setLoading(false, 'Removing File...');
                console.log(`Oops..something went wrong deleting attachment ${file.id}`);
            });
        }
    };

    const downloadAttachment = async (file: FileInformation) => {
        try {
            setLoading(true, 'Downloading File...');

            const { data } = await orionApiUrl.get<FileInformation>(`/v1/attachment/${file.id}`, setHeaders());

            window.open(data.presigned_url);

            setLoading(false, 'Downloading File...');
        } catch (error) {
            handleError(error, () => {
                setLoading(false, 'Downloading File...');
                console.log(`Oops..something went wrong deleting attachment ${file.id}`);
            });
        }
    };

    return (
        <Stack direction="row" spacing={2} alignItems="flex-start" width={width}>
            <Typography
                sx={{
                    fontWeight: bold ? '500' : undefined,
                    width: '40%',
                    height: '40px',
                    color: 'text.primary',
                    ...sx,
                }}
            >
                {label}
            </Typography>
            <Stack
                direction="column"
                spacing={2}
                sx={{
                    width: '50%',
                    ...sx,
                }}
            >
                {filesToDisplay.length > 0 ? (
                    <Stack direction="column" spacing={1} width={'100%'}>
                        {filesToDisplay.map((file: FileInformation, index: number) => (
                            <Stack
                                key={index}
                                direction="row"
                                justifyContent="space-between"
                                sx={{ backgroundColor: '#1E242D', width: '100%', padding: '2px', borderRadius: '4px' }}
                            >
                                <IconButton
                                    disabled={readonly}
                                    onClick={() => downloadAttachment(file)}
                                    sx={{ height: '24px', width: '24px' }}
                                >
                                    <AttachFileIcon sx={{ height: '16px' }} />
                                </IconButton>
                                <Typography height={'24px'}>{file.file_name}</Typography>
                                <IconButton
                                    disabled={readonly}
                                    onClick={() => deleteAttachment(file)}
                                    sx={{ height: '24px', width: '24px' }}
                                >
                                    <CloseIcon sx={{ height: '16px' }} />
                                </IconButton>
                                <Tooltip
                                    disableFocusListener
                                    disableTouchListener
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                backgroundColor: 'primary.light',
                                                border: '1px solid #393E46',
                                                borderRadius: '10px',
                                                padding: '10px',
                                                fontSize: '14px',
                                            },
                                        },
                                    }}
                                    title={
                                        <Stack>
                                            <Typography>Facilities:</Typography>
                                            {file.facilities.map((facility: string, index: number) => (
                                                <Typography key={index}>- {getFacilityName(facility)}</Typography>
                                            ))}
                                            <Typography>Notes: {file.description}</Typography>
                                        </Stack>
                                    }
                                >
                                    <IconButton sx={{ height: '24px', width: '24px' }}>
                                        <InfoOutlinedIcon sx={{ height: '16px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        ))}
                    </Stack>
                ) : (
                    <Typography sx={{ height: '40px', width: '100%' }} textAlign="center">
                        --
                    </Typography>
                )}
            </Stack>
        </Stack>
    );
};
