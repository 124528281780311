import { Box, Button, MenuItem, Stack, TextField, Typography } from '@mui/material';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

type Props = {
    id: string;
    disabled?: boolean;
    onOpen: () => void;
    onSubmit: React.ChangeEventHandler<HTMLInputElement>;
};

export const UploadButton = ({ id, disabled = true, onOpen, onSubmit }: Props) => {
    return (
        <>
            <TextField disabled={disabled} sx={{ display: 'none' }} id={id} type="file" onChange={onSubmit} />
            <label htmlFor={id}>
                <MenuItem
                    disabled={disabled}
                    onClick={disabled ? () => {} : onOpen}
                    sx={{
                        backgroundColor: 'primary.main',
                        borderRadius: '8px',
                        paddingY: '8px',
                    }}
                >
                    <Stack direction="row" spacing={2}>
                        <CloudUploadIcon sx={{ color: 'background.paper' }} />
                        <Typography sx={{ color: 'background.paper' }}>Choose & Upload file</Typography>
                    </Stack>
                </MenuItem>
            </label>
        </>
    );
};
