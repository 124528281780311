import { formatISO, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';

import { AssumptionData } from './quote-step-assumptions.component';
import { LoadingIndicator } from 'components/general/loading/loading-indicator.component';
import { PriorLoss } from './quote-step-claims.component';
import { QuoteSummary } from './quote-stepper.component';
import { QuoteSummaryComponent } from './quote-summary.component';
import { Stack } from '@mui/material';

type Props = {
    quote: QuoteSummary | undefined;
    claims: PriorLoss[];
    assumptions: AssumptionData;
    loading: boolean;
    onNext: () => void;
};

export type ClaimsSummary = {
    incidents_of_years: number;
    prior_year_total_paid: number;
    prior_year_total_claims: number;
    avg_amount_paid_annually: number;
    max_amount_paid: number;
    max_claims_per_year: number;
};

export const QuoteStepQuote = ({ quote, claims, assumptions, loading, onNext }: Props) => {
    const [claimData, setClaimsData] = useState<ClaimsSummary>();

    useEffect(() => {
        if (loading === false) return;
        const groupedEntries: Record<string, PriorLoss[]> = {};
        const data = [];

        claims.forEach(entry => {
            const date = new Date(entry.incident_date);
            const year = formatISO(date).slice(0, 4);

            if (!groupedEntries[year]) groupedEntries[year] = [];
            groupedEntries[year].push(entry);
        });

        console.log(JSON.stringify(groupedEntries, null, 2));

        let total = 0;
        let priorYearPaid = 0;
        let priorYearTotal = 0;
        const avgPaidAnually: number[] = [];
        let maxAmountPaid = 0;
        let maxClaimsPerYear = 0;

        for (const [key, value] of Object.entries(groupedEntries)) {
            let totalPerYear = 0;
            let totalPaidPerYear = 0;

            value.forEach(entry => {
                const amount = parseInt(`${entry.claim_amount}`);

                totalPerYear += 1;
                totalPaidPerYear += amount;
                total += 1;
                maxAmountPaid = maxAmountPaid < amount ? amount : maxAmountPaid;
            });

            if (key === '2022') {
                priorYearPaid = totalPaidPerYear;
                priorYearTotal = totalPerYear;
            }

            maxClaimsPerYear = maxClaimsPerYear < totalPerYear ? totalPerYear : maxClaimsPerYear;
            avgPaidAnually.push(totalPaidPerYear);
        }

        let sum = 0;

        avgPaidAnually.forEach(value => {
            sum += value;
        });

        const average: number = sum / avgPaidAnually.length;

        setClaimsData({
            incidents_of_years: total,
            prior_year_total_paid: priorYearPaid,
            prior_year_total_claims: priorYearTotal,
            avg_amount_paid_annually: average,
            max_amount_paid: maxAmountPaid,
            max_claims_per_year: maxClaimsPerYear,
        });
    }, [claims, loading]);

    return (
        <>
            {loading && (
                <Stack direction="column" justifyItems="center" sx={{ width: '100%', height: '200px' }}>
                    <LoadingIndicator />
                </Stack>
            )}
            {quote !== undefined && claimData !== undefined && !loading && (
                <QuoteSummaryComponent
                    scrollable
                    claimsData={claimData}
                    assumptions={assumptions}
                    quote={quote}
                    maxTableHeight="340px"
                />
            )}
        </>
    );
};
