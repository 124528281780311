import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Stack, TextField, Typography } from '@mui/material';
import { ApplicationData, FacilityInsuranceData } from '../application-details-page.component';
import { ApplicationFilesComponent, FileInformation } from './modules/files.component';
import { useEffect, useState } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LossessInformation } from './modules/narrative-losses.component';
import { useParams } from 'react-router-dom';

type Props = {
    data: ApplicationData;
    setLoadingState: (loadingState: boolean, hintIndicator: string) => void;
    onChange: () => void;
};

const defaultLossess: LossessInformation = {
    is_over_4_claims_per_year: false,
    claims: [],
};

export const FilesApplicationAccordion = ({ data, setLoadingState, onChange }: Props) => {
    const [open, setOpen] = useState<boolean>(false);
    const [files, setFiles] = useState<FileInformation[]>([]);
    const [facilities, setFacilities] = useState<FacilityInsuranceData[]>([]);

    const { id, facility_id } = useParams();

    useEffect(() => {
        setFacilities([...data.facilities]);
        setFiles([...data.attachments]);
    }, [data, facility_id]);

    return (
        <Grid item xs={12}>
            <Accordion
                expanded={open}
                sx={{
                    borderRadius: '5px',
                    padding: 0,
                    backgroundColor: 'background.default',
                    width: '100%',
                }}
            >
                <AccordionSummary>
                    <Stack direction="row" spacing={2} sx={{ width: '100%' }} alignItems="center">
                        <Stack
                            direction="column"
                            onClick={() => {
                                setOpen(!open);
                            }}
                        >
                            <Typography variant="h6">Files</Typography>
                        </Stack>
                        <Box
                            onClick={() => {
                                setOpen(!open);
                            }}
                            sx={{ flexGrow: 1, height: '100%' }}
                        />
                        <Typography
                            sx={{
                                color: '#EEEEEE',
                            }}
                            onClick={() => {
                                setOpen(!open);
                            }}
                        >
                            {!open && <ExpandMoreIcon />}
                            {open && <ExpandLessIcon />}
                        </Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <ApplicationFilesComponent
                            byInsured
                            uploadId="filesInsured"
                            facilities={facilities}
                            applicationId={id ? id : ''}
                            onChange={setFiles}
                            data={files}
                        />
                        <ApplicationFilesComponent
                            uploadId="filesObsidian"
                            facilities={facilities}
                            applicationId={id ? id : ''}
                            onChange={setFiles}
                            data={files}
                        />
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};
