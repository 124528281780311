import { Box, Stack, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import { ReactNode } from 'react';

type Props = {
    text: string;
    href: string;
    active?: boolean;
    icon: ReactNode;
};

export const SideBarLink = ({ text, href, active, icon }: Props) => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                width: '100%',
                padding: '10px',
                '&:hover': {
                    cursor: 'pointer',
                    '& > div': {
                        '& > .side-bar-link-icon': {
                            backgroundColor: 'primary.main',
                            color: 'common.black',
                        },
                        '& > .side-bar-link-label': {
                            color: 'primary.main',
                        },
                    },
                },
                color: active ? 'primary.main' : 'text.primary',
                borderRadius: '4px',
            }}
            className={active ? 'active' : ''}
            onClick={() => navigate(href)}
        >
            <Stack
                direction="column"
                width="90%"
                mx="auto"
                alignItems="center"
                alignContent="center"
                justifyContent="center"
                justifyItems="center"
                spacing={0.5}
            >
                <Typography
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minWidth: '90%',
                        minHeight: '35px',
                        backgroundColor: active ? 'primary.main' : '',
                        color: active ? 'common.black' : 'text.primary',
                        borderRadius: '15px',
                    }}
                    className="side-bar-link-icon"
                >
                    {icon}
                </Typography>
                <Typography
                    color="inherit"
                    className="side-bar-link-label"
                    sx={{
                        textDecoration: 'none',
                        fontSize: '12px',
                        fontWeight: '400',
                        lineHeight: '14px',
                        letterSpacing: '0.25px',
                    }}
                >
                    <Link to={href} style={{ textDecoration: 'none', color: 'inherit' }}>
                        {text}
                    </Link>
                </Typography>
            </Stack>
        </Box>
    );
};
