import { Autocomplete, Box, InputAdornment, Stack, SxProps, TextField, Theme, Typography } from '@mui/material';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { orionApiUrl, setHeaders } from 'util/api/api.util';

import { AddBrokerComponent } from '../add-broker-popup.component copy';
import { Search } from '@mui/icons-material';
import { useDebounce } from 'hooks/useDebounce';

interface Props {
    id: string;
    label: string;
    onChange: (newValue: undefined | BrokerInfo) => void;
    onClear: () => void;
    width?: string;
    smallField?: boolean;
    bold?: boolean;
    filter?: boolean;
    sx?: SxProps<Theme>;
    showMissing?: boolean;
}

export interface BrokerSearchInputRef {
    clearInput: () => void;
}

export type BrokerInfo = {
    id: string;
    name: string;
    address: string;
    created_at?: string;
    updated_at?: string;
};

export const BrokerSearchInput = forwardRef<BrokerSearchInputRef, Props>(
    (
        {
            id,
            label,
            onChange,
            onClear,
            width = '100%',
            smallField = false,
            bold = true,
            filter = false,
            showMissing = false,
            sx,
        }: Props,
        ref,
    ) => {
        const [options, setOptions] = useState<BrokerInfo[]>([]);
        const [value, setValue] = useState<BrokerInfo | undefined>(undefined);
        const [inputValue, setInputValue] = useState<string>('');
        const [searchLoading, setSearchLoading] = useState(false);
        const [open, setOpen] = useState<boolean>(false);
        const [displayPopper, setDisplayPopper] = useState<boolean>(true);
        const previousController = useRef<AbortController>();
        const debouncedSearch = useDebounce(inputValue, 300);

        const clearInput = () => {
            setValue(undefined);
            setInputValue('');
        };

        useImperativeHandle(ref, () => ({
            clearInput,
        }));

        const getData = useCallback(async (searchTerm: string) => {
            if (searchTerm.trim() === '') {
                setOptions([]);

                return;
            }

            try {
                if (previousController.current) {
                    previousController.current.abort();
                }

                const controller = new AbortController();
                const signal = controller.signal;

                previousController.current = controller;
                setSearchLoading(true);

                const { data } = await orionApiUrl.get<BrokerInfo[]>(
                    `/v1/brokerage/?search=${searchTerm}`,
                    setHeaders(),
                );

                setOptions(data.length > 0 ? data : []);

                setSearchLoading(false);
            } catch (error) {
                setSearchLoading(false);
                setOptions([]);
            }
        }, []);

        useEffect(() => {
            getData(debouncedSearch);
        }, [getData, debouncedSearch]);

        useEffect(() => {
            if (debouncedSearch == null || debouncedSearch.length <= 0) {
                onClear();
            }
        }, [debouncedSearch]);

        return (
            <>
                <AddBrokerComponent
                    open={open}
                    nameValue={inputValue}
                    onClose={() => {
                        setDisplayPopper(true);
                        setOpen(false);
                    }}
                    onNewBroker={(newBroker: BrokerInfo) => {
                        const isPresent = options.includes(newBroker);

                        if (!isPresent) {
                            setOptions([newBroker, ...options]);
                        }
                        setValue(newBroker);
                        setInputValue(newBroker.name);
                        onChange(newBroker);
                    }}
                />
                <Stack direction="row" spacing={2} alignItems={filter ? 'unset' : 'center'} width={width}>
                    {!filter && (
                        <Typography sx={{ fontWeight: bold ? '500' : undefined, width: smallField ? '70%' : '50%' }}>
                            {label}
                        </Typography>
                    )}
                    {!filter && <Box></Box>}
                    <Autocomplete
                        fullWidth
                        freeSolo
                        componentsProps={{ popper: { open: displayPopper } }}
                        id={id}
                        getOptionLabel={(option: string | BrokerInfo) =>
                            typeof option === 'string' ? option : option.name
                        }
                        value={value}
                        inputValue={inputValue}
                        filterOptions={x => x}
                        options={
                            filter ? [...options] : [{ id: 'add-new', name: 'Add New', address: 'address' }, ...options]
                        }
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        noOptionsText={<Typography sx={{ color: 'text.primary' }}>No Results</Typography>}
                        onChange={(event, newValue, reason) => {
                            if (reason === 'clear') {
                                onClear();

                                return;
                            }
                            if (typeof newValue === 'string') {
                                setInputValue(newValue);
                            } else if (newValue && newValue.id) {
                                setValue(newValue);
                                onChange(newValue);
                            } else {
                                setValue(newValue == null ? undefined : newValue);
                                onChange(newValue == null ? undefined : newValue);
                            }
                        }}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        disableClearable={true}
                        loading={searchLoading}
                        loadingText={<Typography sx={{ color: 'text.primary' }}>Loading...</Typography>}
                        renderOption={(props, option: BrokerInfo, selected) => {
                            if (option.id == 'add-new') {
                                return (
                                    <li
                                        {...props}
                                        onClick={event => {
                                            setTimeout(() => {
                                                if (document.activeElement instanceof HTMLElement)
                                                    document.activeElement.blur();
                                            }, 0);
                                            event.stopPropagation();
                                            setDisplayPopper(false);
                                            setOpen(true);
                                        }}
                                    >
                                        <Typography sx={{ color: 'text.primary' }}>+ Add New Broker</Typography>
                                    </li>
                                );
                            } else {
                                return (
                                    <li {...props}>
                                        {option.id == 'add-new' ? (
                                            <Typography sx={{ color: 'text.primary' }}>+ Add New Broker</Typography>
                                        ) : (
                                            <Typography sx={{ color: 'text.primary' }}>{option.name}</Typography>
                                        )}
                                    </li>
                                );
                            }
                        }}
                        renderInput={params => {
                            return (
                                <TextField
                                    {...params}
                                    size="small"
                                    placeholder={filter ? 'Brokerage' : undefined}
                                    InputLabelProps={{
                                        style: {
                                            color: 'background.paper',
                                            border: 'none',
                                            fontStyle: 'normal',
                                        },
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                        sx: {
                                            backgroundColor: 'background.deep',
                                        },
                                        style: {
                                            border: showMissing ? '1px solid #CC5C5C' : 'none',
                                        },
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <Search sx={{ color: 'text.primary' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        borderRadius: filter ? '8px' : undefined,
                                        backgroundColor: 'background.paper',
                                        '&::placeholder': {
                                            color: 'text.discrete',
                                            fontStyle: 'normal !important',
                                            opacity: 1 /* Firefox */,
                                        },

                                        ':-ms-input-placeholder': {
                                            /* Internet Explorer 10-11 */ color: 'text.discrete',
                                            fontStyle: 'normal !important',
                                        },

                                        '::-ms-input-placeholder': {
                                            /* Microsoft Edge */ color: 'text.discrete',
                                            fontStyle: 'normal !important',
                                        },

                                        '& .MuiOutlinedInput-root': {
                                            '& ::placeholder': {
                                                fontStyle: 'normal',
                                            },
                                            '& fieldset': {
                                                border: 'none',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'common.white',
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: 'none',
                                            },
                                        },
                                    }}
                                />
                            );
                        }}
                    />
                    {!filter && <Box></Box>}
                </Stack>
            </>
        );
    },
);

BrokerSearchInput.displayName = 'BrokerSearchInput';
