import './App.css';
import 'leaflet/dist/leaflet.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer, toast } from 'react-toastify';
import { authLogin, authLogout, authStopLoading } from 'store/features/auth/auth.slice';
import axios, { AxiosError } from 'axios';
import { orionApiUrl, setHeaders } from 'util/api/api.util';
import { useAppDispatch, useAppSelector } from 'store/hooks/store.hook';
import { useCallback, useEffect } from 'react';

import Cookies from 'js-cookie';
import { LoadingIndicator } from 'components/general/loading/loading-indicator.component';
import { MainRouter } from 'routes/main-router.component';
import { OrionThemeProvider } from 'theme/theme-provider';
import { Stack } from '@mui/material';
import { UserResponse } from 'types/user.interface';
import { handleError } from 'util/error/handleError';

function App() {
    const loading = useAppSelector(st => st.auth.loading);
    const dispatch = useAppDispatch();

    const getUser = useCallback(
        async (token: string) => {
            console.log(`Getting user with token: ${token}`);
            try {
                const { data: user } = await orionApiUrl.get<UserResponse>('/v1/user/me/', {
                    headers: { Authorization: `Bearer ${token}` },
                });

                dispatch(authLogin(user));
                dispatch(authStopLoading());
            } catch (e) {
                const error = e as AxiosError | Error;

                if (axios.isAxiosError(error) && error.response?.status === 401) {
                    Cookies.remove('token');
                    dispatch(authLogout());
                }
                dispatch(authStopLoading());
            }
        },
        [dispatch],
    );

    const startTokenVerification = useCallback(() => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const token = JSON.parse(Cookies.get('token')!);

            if (token) {
                getUser(token.token);
            }
        } catch (error) {
            handleError(error, () => {
                dispatch(authLogout());
                dispatch(authStopLoading());
            });
        }
    }, [dispatch, getUser]);

    const loopForRequest = useCallback(async () => {
        for (let i = 0; i < 6; i++) {
            try {
                const { data } = await orionApiUrl.request({
                    timeout: 5000,
                    method: 'GET',
                    url: 'health',
                });

                if (data.status === 'healthy') {
                    startTokenVerification();
                    break;
                }
            } catch (error) {
                // If timeout throw error I have to wait 10-15 seconds to wait for the application to wake up
                if (i === 5) {
                    toast.error('Internal server error, please try again later');
                }
                console.log(error);
            }
        }
    }, [startTokenVerification]);

    useEffect(() => {
        loopForRequest();
    }, [loopForRequest]);

    return (
        <OrionThemeProvider>
            <ToastContainer position="top-center" />
            {loading ? (
                <Stack
                    sx={{
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: 'primary.light',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <LoadingIndicator />
                </Stack>
            ) : (
                <MainRouter />
            )}
        </OrionThemeProvider>
    );
}

export default App;
