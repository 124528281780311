import { Box, InputAdornment, Stack, SxProps, TextField, Theme, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

interface Props {
    value: string | number | undefined;
    percentageValue: string | number | undefined;
    percentageId: string;
    label: string;
    onPercentageChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onPercentageMaskedChange?: (key: string, value: string) => void;
    valueType?: 'numeric' | 'currency' | 'percentage' | 'none';
    percentageType?: 'numeric' | 'currency' | 'percentage' | 'none';
    width?: string;
    smallField?: boolean;
    bold?: boolean;
    blue?: boolean;
    sx?: SxProps<Theme>;
    readonly?: boolean;
}

export const ValuePercentageInput = ({
    label,
    value,
    percentageValue,
    percentageId,
    width = '100%',
    valueType = 'currency',
    percentageType = 'percentage',
    onPercentageChange: onChange,
    onPercentageMaskedChange: onMaskedChange = () => {},
    smallField = false,
    bold = true,
    blue = false,
    readonly = false,
    sx,
}: Props) => {
    const [maskValue, setMaskValue] = useState('');

    useEffect(() => {
        if (percentageType === 'percentage') {
            if (percentageValue == null) {
                setMaskValue('');
            } else {
                const numberValue = typeof percentageValue === 'string' ? parseFloat(percentageValue) : percentageValue;

                if (isNaN(numberValue)) {
                    setMaskValue('');
                } else {
                    setMaskValue(`${Math.round(numberValue * 1000) / 10}`);

                    // setMaskValue(`${Math.round(numberValue * 100)}`);
                }
            }
        }
    }, [percentageValue]);

    const maskedChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
        const { name, value } = event.target;

        let result = '';

        if (percentageType === 'percentage') {
            if (value == null) {
                result = '';
            } else {
                const numberValue = typeof value === 'string' ? parseFloat(value) : value;

                if (isNaN(numberValue)) {
                    result = '';
                } else {
                    result = `${numberValue / 100}`;
                }
            }
        }

        onMaskedChange(name, result);
    };

    function formatNumber(value: number | string | undefined) {
        if (value == null) return value;

        const numericValue = +value;

        if (isNaN(numericValue)) return value;

        if (percentageType === 'currency') {
            return numericValue.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }

        return numericValue.toLocaleString('en-US');
    }

    return (
        <Stack direction="row" spacing={2} alignItems="center" width={width}>
            <Typography
                sx={{
                    fontWeight: bold ? '500' : undefined,
                    width: smallField ? '63%' : '50%',
                    color: blue ? 'primary.main' : undefined,
                }}
            >
                {label}
            </Typography>
            <Typography
                sx={{
                    fontWeight: bold ? '500' : undefined,
                    width: readonly ? '47%' : '20%',
                    paddingY: '8.5px',
                    color: blue ? 'primary.main' : undefined,
                    textAlign: 'right',
                }}
            >
                {valueType === 'currency' && '$ '}
                {formatNumber(value)}
                {valueType === 'percentage' && ' %'}
            </Typography>
            <Box sx={{ width: '15%' }} />
            {readonly && (
                <Typography
                    sx={{
                        fontWeight: bold ? '500' : undefined,
                        minWidth: '19%',
                        paddingY: '8.5px',
                        paddingRight: '12px',
                        color: blue ? 'primary.main' : undefined,
                        textAlign: 'right',
                    }}
                >
                    {formatNumber(maskValue)}
                    {percentageType === 'percentage' && ' %'}
                </Typography>
            )}
            {!readonly && (
                <TextField
                    size="small"
                    name={percentageId}
                    value={percentageType === 'percentage' ? maskValue : percentageValue}
                    onChange={percentageType === 'percentage' ? maskedChange : onChange}
                    type={percentageType !== 'none' ? 'number' : undefined}
                    InputProps={{
                        sx: {
                            color: blue ? 'primary.main' : undefined,
                            backgroundColor: 'background.deep',
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <Typography sx={{ color: blue ? 'primary.main' : undefined }}>
                                    {percentageType === 'currency' ? '$' : ''}
                                </Typography>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <Typography sx={{ color: blue ? 'primary.main' : undefined }}>
                                    {percentageType === 'percentage' ? '%' : ''}
                                </Typography>
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        color: '#CC5C5C',
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderStyle: 'none',
                        },
                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                            display: 'none',
                        },
                        '& input[type=number]': {
                            MozAppearance: 'textfield',
                        },
                        input: { textAlign: 'right' },
                        width: '20%',
                        ...sx,
                    }}
                />
            )}
        </Stack>
    );
};
