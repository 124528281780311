import { Backdrop, Box, Button, Grid, MenuItem, Paper, Stack, TextField, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { orionApiUrl, setHeaders } from 'util/api/api.util';
import { useCallback, useEffect, useState } from 'react';

import { BrokerInfo } from './field-components/broker-search-input.component';
import { LoadingIndicator } from 'components/general/loading/loading-indicator.component';
import { SimpleInput } from './field-components/simple-input.component';

type Props = {
    open: boolean;
    nameValue?: string;
    addressValue?: string;
    onClose: () => void;
    onNewBroker: (broker: BrokerInfo) => void;
};

export const AddBrokerComponent = ({ open, nameValue, addressValue, onClose, onNewBroker }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>();
    const [address, setAddress] = useState<string>();

    const pushData = useCallback(async () => {
        try {
            setLoading(true);

            const { data } = await orionApiUrl.post<BrokerInfo>(
                '/v1/brokerage/',
                {
                    name: name,
                    address: address,
                },
                setHeaders(),
            );

            if (data != null) onNewBroker(data);
            setLoading(false);
            onClose();
        } catch (error) {
            setLoading(false);

            console.log(JSON.stringify(error, null, 2));
        }
    }, [name, address]);

    useEffect(() => {
        if (open == true) {
            setName(nameValue ? nameValue : '');
            setAddress(addressValue ? addressValue : '');
        }
    }, [open]);

    return (
        <Backdrop sx={{ zIndex: theme => theme.zIndex.drawer + 3 }} open={open} onClick={onClose}>
            <Box
                onClick={event => event.stopPropagation()}
                sx={{
                    borderRadius: '5px',
                    padding: '24px',
                    overflowX: 'auto',
                    border: '0px none',
                    borderColor: 'primary.gray',
                    backgroundColor: 'background.paper',
                    width: { xs: '90%', lg: '60%' },
                }}
            >
                <Stack direction="column" spacing={2}>
                    {loading && <LoadingIndicator />}
                    {!loading && (
                        <Stack direction="column" spacing={2}>
                            <SimpleInput
                                label="Broker"
                                id="broker_name"
                                value={name}
                                width="100%"
                                onChange={event => {
                                    const { value } = event.target;

                                    setName(value);
                                }}
                            />
                            <SimpleInput
                                label="Broker Address"
                                id="broker_address"
                                value={address}
                                width="100%"
                                onChange={event => {
                                    const { value } = event.target;

                                    setAddress(value);
                                }}
                            />
                            <Stack direction="row" justifyContent="flex-end" spacing={2}>
                                <Button onClick={onClose}>
                                    <Typography fontWeight={500} color={'text.primary'}>
                                        Cancel
                                    </Typography>
                                </Button>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    onClick={pushData}
                                    disabled={name == null || name.length < 3 || address == null || address.length < 3}
                                    sx={{ backgroundColor: 'primary.main' }}
                                >
                                    <Typography sx={{ size: '14px', fontWeight: '500' }}>Save New Broker</Typography>
                                </Button>
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            </Box>
        </Backdrop>
    );
};
