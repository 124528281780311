import { InputAdornment, SxProps, TextField, Theme, Typography } from '@mui/material';

interface Props {
    value: string | number;
    id: string;
    label: string;
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    fullWidth?: boolean;
    type?: 'currency' | 'percentage' | 'none';
    sx?: SxProps<Theme>;
    altBorder?: boolean;
}

export const InputFieldNumeric = ({
    value,
    id,
    label,
    onChange,
    sx,
    type = 'none',
    altBorder = false,
    fullWidth = false,
}: Props) => {
    return (
        <>
            <TextField
                fullWidth={fullWidth}
                variant="outlined"
                value={value}
                name={id}
                label={label}
                onChange={onChange}
                InputProps={{
                    inputMode: 'numeric',
                    startAdornment: (
                        <InputAdornment position="start">
                            <Typography>{type === 'currency' ? '$' : ''}</Typography>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography>{type === 'percentage' ? '%' : ''}</Typography>
                        </InputAdornment>
                    ),
                }}
                sx={{
                    color: '#CC5C5C',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: altBorder ? '#393E46' : 'primary.main',
                    },
                    label: {
                        color: 'text.primary',
                    },
                    ...sx,
                }}
            />
        </>
    );
};
