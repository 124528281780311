import { Box, InputAdornment, Stack, SxProps, TextField, Theme, Typography } from '@mui/material';
import { DatePicker, DateValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';

import { format } from 'path';
import { parseISO } from 'date-fns';

interface Props {
    label: string;
    onChange: (value: Date | null, context: PickerChangeHandlerContext<DateValidationError>) => void;
    value?: string;
    format?: string;
    width?: string;
    smallField?: boolean;
    bold?: boolean;
    start_spacing?: string;
    end_spacing?: string;
    sx?: SxProps<Theme>;
    showMissing?: boolean;
}

export const SimpleDateInput = ({
    label,
    onChange,
    value,
    format,
    width = '100%',
    smallField = false,
    bold = true,
    start_spacing = '0%',
    end_spacing = '0%',
    showMissing = false,
    sx,
}: Props) => {
    return (
        <Stack direction="row" spacing={2} alignItems="center" width={width}>
            <Typography sx={{ fontWeight: bold ? '500' : undefined, width: smallField ? '70%' : '50%' }}>
                {label}
            </Typography>
            <Box sx={{ width: start_spacing }}></Box>
            <DatePicker
                value={value ? parseISO(value) : undefined}
                onChange={onChange}
                format={format ? format : undefined}
                slotProps={{
                    layout: {
                        sx: {
                            '& .MuiDayCalendar-weekDayLabel': {
                                color: 'text.discrete',
                            },
                        },
                    },
                    textField: {
                        fullWidth: true,
                        size: 'small',
                        InputProps: {
                            sx: { backgroundColor: 'background.deep' },
                        },
                    },
                    day: {
                        sx: {
                            '&:disabled:not(.Mui-selected)': {
                                color: 'text.discrete',
                            },
                        },
                    },
                }}
                sx={{
                    color: '#CC5C5C',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderStyle: showMissing ? '1px solid' : 'none',
                        borderColor: '#CC5C5C',
                    },
                    '&:hover': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderStyle: showMissing ? '1px solid' : 'none',
                            borderColor: '#CC5C5C',
                        },
                    },
                    label: {
                        color: 'text.primary',
                    },
                    ...sx,
                }}
            />
            <Box sx={{ width: end_spacing }}></Box>
        </Stack>
    );
};
