import { FacilityDataPoint, FacilityIndex } from 'types/underwriter/underwriter.type';
import { compareDesc, parseISO } from 'date-fns';

import { FacilityTrendResponse } from '../../types/underwriter.types';
import { IndexTable } from '../index-table.component';
import { useMemo } from 'react';

type Props = {
    trendData: FacilityTrendResponse[];
};

export const RatingTrends = ({ trendData }: Props) => {
    const data: FacilityIndex[] = useMemo(() => {
        if (trendData.length <= 0) return [];
        const indexes: FacilityIndex[] = [];
        const indexPoints: FacilityDataPoint[][] = [[], [], [], []];

        trendData.forEach(trend => {
            indexPoints[0].push({ value: Number(trend.index_overall_rating.toFixed(2)), date: trend.filedate });
            indexPoints[1].push({
                value: Number(trend.index_health_inspection_rating.toFixed(2)),
                date: trend.filedate,
            });
            indexPoints[2].push({
                value: Number(trend.index_long_stay_qm_rating.toFixed(2)),
                date: trend.filedate,
            });
            indexPoints[3].push({
                value: Number(trend.index_short_stay_qm_rating.toFixed(2)),
                date: trend.filedate,
            });
        });

        indexPoints[0].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[1].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[2].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[3].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));

        indexes.push(
            {
                label: 'Index Overall Rating',
                tooltip:
                    'The overall rating as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[0],
            },
            {
                label: 'Index Health Inspection Rating',
                tooltip:
                    'The health inspection rating as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[1],
            },
            {
                label: 'Index Long Stay Qm Rating',
                tooltip:
                    'The long stay quality measure rating as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[2],
            },
            {
                label: 'Index Short Stay Qm Rating',
                tooltip:
                    'The short stay quality measure rating as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[3],
            },
        );

        return indexes;
    }, [trendData]);

    return <IndexTable title="Rating" indexes={data} />;
};
