import {
    ApplicantInformation,
    ApplicantInformationComponent,
} from '../application-details-page/accordions/modules/application-information.component';
import { ApplicationData, FacilityInsuranceData } from '../application-details-page/application-details-page.component';
import { Box, Breadcrumbs, Button, Fab, Grid, Link, Typography } from '@mui/material';
import { orionApiUrl, setHeaders } from 'util/api/api.util';
import { useCallback, useEffect, useState } from 'react';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Container } from 'components/general/layout/container.component';
import { LoadingIndicator } from 'components/general/loading/loading-indicator.component';
import { PageTitle } from 'components/general/layout/page-title.component';
import SaveIcon from '@mui/icons-material/Save';
import { Stack } from '@mui/system';
import { SubContainer } from 'components/general/layout/sub-container.component';
import { allPropertiesExist } from './add-application-page.component';
import { handleError } from 'util/error/handleError';
import { useAppDispatch } from 'store/hooks/store.hook';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

export const AddApplicationFacilityPage = () => {
    const [missingProperties, setMissingProperties] = useState<string[]>([]);
    const [showError, setShowError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [canSave, setCanSave] = useState<boolean>(true);
    const [summary, setSummary] = useState<ApplicationData | null>(null);

    const [applicantInfo, setApplicantInfo] = useState<ApplicantInformation>({});
    const { id } = useParams();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const checkIfValid = useCallback((): boolean => {
        const missingProps = allPropertiesExist(applicantInfo, [
            'insured_name',
            'insured_address',
            'facility_name',
            'facility_provider_number',
            'facility_address',
            'facility_rating',
            'facility_beds_licensed',
            'facility_beds_occupied',
            'submission_date',
            'renewal_date',
            'brokerage_name',
            'brokerage_address',
            'agent_name',
            'agent_email',
            'agent_id',
            // 'retroactive_date',
            // 'expiring_deductible',
        ]);

        setShowError(missingProps.length > 0);
        setMissingProperties(missingProps);

        return missingProps.length <= 0;
    }, [applicantInfo]);

    const saveData = async () => {
        const isValid = checkIfValid();

        if (!isValid) return;

        try {
            setLoading(true);
            setCanSave(false);

            const intitialFacility: FacilityInsuranceData = {
                ...applicantInfo,
                submission_status: 'Draft',
                claims: [],
            };

            const { data } = await orionApiUrl.post<ApplicationData>(
                `/v1/application/${id}/facilities`,
                intitialFacility,
                setHeaders(),
            );

            if (!data.id || data.id === null) {
                throw new Error('Unable to attach facility to the application');
            }

            navigate(`/applications/${id}/${data.id}`);

            setLoading(false);
            setCanSave(true);
        } catch (error) {
            console.log(JSON.stringify(error, null, 2));
        }
    };

    useEffect(() => {
        if (!showError) return;

        checkIfValid();
    }, [missingProperties, showError]);

    const getData = useCallback(async () => {
        try {
            setLoading(true);

            const { data } = await orionApiUrl.get<ApplicationData>(`/v1/application/${id}`, setHeaders());

            setSummary(data);

            if (data.facilities.length > 0) {
                setApplicantInfo({
                    insured_name: data.facilities[0].insured_name,
                    insured_address: data.facilities[0].insured_address,
                    submission_date: data.facilities[0].submission_date,
                    renewal_date: data.facilities[0].renewal_date,
                    retroactive_date: data.facilities[0].retroactive_date,
                    expiring_deductible: data.facilities[0].expiring_deductible,
                    brokerage_name: data.facilities[0].brokerage_name,
                    brokerage_address: data.facilities[0].brokerage_address,
                    agent_name: data.facilities[0].agent_name,
                    agent_email: data.facilities[0].agent_email,
                    agent_id: data.facilities[0].agent_id,
                });
            }

            setLoading(false);
        } catch (error) {
            handleError(error, () => {
                console.log(JSON.stringify(error, null, 2));
            });
        }
    }, [id]);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <>
            <PageTitle text="Applications" />
            <Stack direction="row">
                <Breadcrumbs
                    separator={<ArrowForwardIosIcon sx={{ height: '12px' }} />}
                    sx={{ color: 'text.primary', marginBottom: '24px' }}
                >
                    <Link underline="hover" key="1" color="text.primary" onClick={() => navigate('/applications')}>
                        Applications
                    </Link>
                    <Typography
                        fontWeight={500}
                        color="secondary.main"
                        onClick={() => navigate(`/applications/${id}/${summary?.facilities[0].id}`)}
                    >
                        {`Application #${id}`}
                    </Typography>
                    <Typography fontWeight={500} color="secondary.main">
                        Add New Facility
                    </Typography>
                </Breadcrumbs>
            </Stack>
            <Container>
                <Grid container spacing={2} alignItems="stretch">
                    {loading && (
                        <Grid item xs={12}>
                            <SubContainer>
                                <Stack direction="column" justifyItems="center" sx={{ width: '100%', height: '200px' }}>
                                    <LoadingIndicator />
                                </Stack>
                            </SubContainer>
                        </Grid>
                    )}
                    {!loading && (
                        <>
                            <Grid item xs={12}>
                                <Stack direction="column" spacing={2}>
                                    <Stack direction="row" justifyContent={'end'} spacing={2} width="100%">
                                        <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                            Add Facility Application
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <ApplicantInformationComponent
                                hideStatus
                                autocompleteFacility
                                showRequired
                                data={applicantInfo}
                                onChange={setApplicantInfo}
                                missingProperties={missingProperties}
                            />
                        </>
                    )}
                    <Grid item xs={12}>
                        <Stack direction="column">
                            <Stack direction="row" justifyContent="center" sx={{ marginTop: 4 }}>
                                <Button
                                    onClick={() => {
                                        if (!canSave) return;
                                        saveData();
                                    }}
                                    sx={{
                                        margin: 0,
                                        paddingY: 1,
                                        top: 'auto',
                                        bottom: 20,
                                        backgroundColor: 'primary.main',
                                        color: '#000000',
                                        borderRadius: '8px',
                                        '&:hover': {
                                            backgroundColor: '#556796',
                                        },
                                    }}
                                >
                                    <Stack direction="row" alignItems="center">
                                        <SaveIcon sx={{ mr: 1 }} />
                                        <Typography sx={{ fontWeight: 500 }}> CALCULATE & SAVE</Typography>
                                    </Stack>
                                </Button>
                            </Stack>
                            {showError && (
                                <Stack direction="row" justifyContent="center">
                                    <Typography color={'#CC5C5C'}>
                                        Please, fill all of the required fields in order to proceed.
                                    </Typography>
                                </Stack>
                            )}
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};
