import { Navigate, Outlet } from 'react-router-dom';

import { Layout } from 'components/general/layout/layout.component';
import { useAppSelector } from 'store/hooks/store.hook';

export const PrivateRoutes = () => {
    const user = useAppSelector(st => st.auth.user);

    return user ? (
        <Layout>
            <Outlet />
        </Layout>
    ) : (
        <Navigate to="/login" />
    );
};
