import * as ExcelJS from 'exceljs';

import { Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { addDays, format, parse } from 'date-fns';
import { orionApiUrl, setHeaders } from 'util/api/api.util';
import { useCallback, useEffect, useRef, useState } from 'react';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { LossRunsItem } from 'components/pages/applications/add-application/field-components/loss-runs-item.component';
import { SimpleCheckboxInput } from '../../../add-application/field-components/simple-checkbox-input.component';
import { SimpleTextBoxInput } from '../../../add-application/field-components/simple-text-box-input.component';
import { SubContainer } from 'components/general/layout/sub-container.component';
import { handleError } from 'util/error/handleError';

export type LossessInformation = {
    claims: LossRunsInformation[];
    is_over_4_claims_per_year?: boolean;
    notes?: string;
    //PDF ONLY
    historical_losses_incurred?: number;
    historical_policy_duration_months?: number;
    historical_losses_incurred_over_deductible?: number;
    historical_premium_paid?: number;
};

export type LossInformation = {
    id?: number;
    application_id?: number;
    facility_provider_number?: number;
    claim_number?: string;
    loss_type?: string[];
    loss_date?: string;
    cause?: string;
    amount?: number;
    description?: string;
    is_deceased?: boolean;
    is_closed?: boolean;
    liability_type?: string[];
};

export type LossRunsInformation = {
    id?: number;
    application_id?: number;
    facility_provider_number?: number;
    carrier_name?: string;
    effective_start_date?: string;
    effective_end_date?: string;
    status: 'Loss Runs Provided' | 'No Loss Runs Provided' | 'No Loss Runs Available';
    amounts?: number[];
    total_amount?: number;
};

type Props = {
    data: LossessInformation;
    providerNumber: string;
    onNoKnownLosses: () => void;
    onChange: (changes: LossessInformation) => void;
    refreshLoss: () => void;
};

export const ApplicationLossessComponent = ({
    data,
    providerNumber,
    onNoKnownLosses,
    onChange,
    refreshLoss,
}: Props) => {
    const uploadFileRef = useRef<HTMLInputElement | null>(null);
    const [uploadValue, setUploadValue] = useState('');
    const [uploadFile, setUploadFile] = useState<File | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const onExcelFileUploaded: React.ChangeEventHandler<HTMLInputElement> = event => {
        const { files } = event.target;

        if (!files || files.length <= 0) return;
        setUploadFile(files[0]);
    };

    const parseExcelFile = useCallback(async () => {
        if (uploadFile == null) return;

        try {
            setLoading(true);
            const workbook = new ExcelJS.Workbook();
            const buffer = await uploadFile.arrayBuffer();

            await workbook.xlsx.load(buffer);

            const worksheet = workbook.getWorksheet(1);

            if (worksheet == null) throw Error('Workbook is empty');

            const lossRunRows: number[] = [];

            worksheet.getColumn('A').eachCell((cell, index) => {
                if (index <= 1 || typeof cell.value != 'string' || cell.value === '') {
                    return;
                }
                if (cell.value.includes('5 Year Total Incurred')) {
                    return;
                }
                lossRunRows.push(index);
            });

            const lrData: LossRunsInformation[] = [];

            lossRunRows.forEach((row, index) => {
                const targetRow = worksheet.getRow(row);
                const lossRunInfo: LossRunsInformation = { status: 'No Loss Runs Provided' };
                const rawStartDate =
                    targetRow.getCell('C').text != null ? Date.parse(targetRow.getCell('C').text) : undefined;
                const rawEndDate =
                    targetRow.getCell('D').text != null ? Date.parse(targetRow.getCell('D').text) : undefined;

                lossRunInfo.effective_start_date =
                    rawStartDate !== undefined ? format(addDays(rawStartDate, 1), 'yyyy-MM-dd') : undefined;
                lossRunInfo.effective_end_date =
                    rawEndDate !== undefined ? format(addDays(rawEndDate, 1), 'yyyy-MM-dd') : undefined;
                lossRunInfo.carrier_name =
                    targetRow.getCell('B').value != null ? targetRow.getCell('B').text : undefined;

                targetRow.eachCell((cell, column) => {
                    if (column <= 4) return;

                    const value = cell.value != null ? cell.text : null;

                    if (value == null) return;

                    const numericValue = parseFloat(value);

                    if (isNaN(numericValue)) return;
                    lossRunInfo.amounts = lossRunInfo.amounts == null ? [] : lossRunInfo.amounts;

                    lossRunInfo.amounts.push(numericValue);
                });
                lossRunInfo.amounts = lossRunInfo.amounts == null ? [] : lossRunInfo.amounts;

                if (lossRunInfo.amounts.length >= 0) lossRunInfo.status = 'Loss Runs Provided';

                lossRunInfo.total_amount = lossRunInfo.amounts.reduce((sum, a) => sum + a, 0);

                lrData.push(lossRunInfo);
            });

            const newLossess: LossRunsInformation[] = [...data.claims, ...lrData];

            onChange({
                ...lrData,
                claims: newLossess,
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(JSON.stringify(error, null, 2));
        }
    }, [uploadFile]);

    useEffect(() => {
        parseExcelFile();
    }, [uploadFile]);

    const handleFieldChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
        const { name, value } = event.target;
        const parsedValue = value.trim().length <= 0 ? null : value;

        onChange({
            ...data,
            [name]: parsedValue,
        });
    };

    const handleCheckBoxChange: React.ChangeEventHandler<HTMLInputElement> = event => {
        const { name, checked } = event.target;

        onChange({
            ...data,
            [name]: checked,
        });
    };

    const addLoss = () => {
        const newLossess: LossRunsInformation[] = [...data.claims, { status: 'No Loss Runs Available' }];

        onChange({
            ...data,
            claims: newLossess,
        });
    };

    const removeLoss = async (index: number) => {
        try {
            if (data.claims[index] && data.claims[index].id) {
                const updateRequest = await orionApiUrl.delete(`/v1/claim/${data.claims[index].id}`, setHeaders());

                if (updateRequest.status !== 200) {
                    throw new Error('Unable to update');
                }
                refreshLoss();
            }

            const newLossess = data.claims.filter((loss: LossRunsInformation, i: number) => i !== index);

            onChange({
                ...data,
                claims: newLossess,
            });
        } catch (error) {
            console.log(`unable to update claim with index ${index}`);
        }
    };

    useEffect(() => {
        onChange(data);
    }, [data]);

    return (
        <Grid
            item
            lg={6}
            xs={12}
            sx={{
                '& .MuiBox-root': {
                    height: '100%',
                },
            }}
        >
            <SubContainer sx={{ padding: '20px 30px' }}>
                <Stack direction="column" spacing={2}>
                    <Stack direction="row" alignItems="center">
                        <Typography variant="h6" sx={{ flexGrow: '1' }}>
                            Narrative of Losses
                        </Typography>
                        <Stack direction="row" spacing={2} justifyContent="center">
                            {data.claims.length <= 0 && (
                                <Button
                                    variant="contained"
                                    size="medium"
                                    onClick={onNoKnownLosses}
                                    sx={{ backgroundColor: 'primary.main' }}
                                    startIcon={<FileDownloadOutlinedIcon />}
                                >
                                    <Typography sx={{ size: '14px', fontWeight: '500' }} onClick={() => {}}>
                                        NO KNOWN LOSS
                                    </Typography>
                                </Button>
                            )}
                            <input
                                hidden
                                value={uploadValue}
                                ref={uploadFileRef}
                                accept=".xlsx, .xls"
                                type="file"
                                onChange={onExcelFileUploaded}
                            />
                            <label htmlFor="raised-button-file">
                                <Button
                                    sx={{ margingY: '24px' }}
                                    startIcon={<CloudUploadIcon />}
                                    onClick={() => uploadFileRef.current && uploadFileRef.current.click()}
                                    disabled={loading}
                                >
                                    <Typography fontWeight={500}>UPLOAD LOSS RUNS ANALYSIS</Typography>
                                </Button>
                            </label>
                        </Stack>
                    </Stack>
                    <Grid container rowGap={2}>
                        <Grid item xs={12}>
                            <Stack direction="column" spacing={1}>
                                <SimpleCheckboxInput
                                    label="More than 4 Claims in Year"
                                    id="is_over_4_claims_per_year"
                                    value={data.is_over_4_claims_per_year}
                                    onChange={handleCheckBoxChange}
                                />
                                {data.is_over_4_claims_per_year && (
                                    <SimpleTextBoxInput
                                        id="notes"
                                        value={data.notes}
                                        rows={2}
                                        placeholder="Notes"
                                        onChange={handleFieldChange}
                                    />
                                )}
                                <Paper
                                    sx={{
                                        maxHeight: data.is_over_4_claims_per_year ? '1400px' : '1470px',
                                        overflowY: 'auto',
                                        backgroundColor: 'transparent',
                                        backgroundImage: 'none',
                                    }}
                                >
                                    <Stack direction="column" spacing={1}>
                                        {data.claims.map((loss: LossRunsInformation, index: number) => (
                                            <LossRunsItem
                                                key={index}
                                                data={loss}
                                                providerNumber={providerNumber}
                                                onChange={refreshLoss}
                                                onRemove={() => removeLoss(index)}
                                            />
                                        ))}
                                    </Stack>
                                </Paper>
                                <Button sx={{ margingY: '24px' }} disabled={loading} onClick={addLoss}>
                                    <Typography fontWeight={500}>+ ADD CLAIM</Typography>
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>
            </SubContainer>
        </Grid>
    );
};
