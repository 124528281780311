import { FacilityData, FacilityDataPoint, FacilityIndex } from 'types/underwriter/underwriter.type';
import { FacilityDetails, FacilityLocationDetails, FacilityTrendResponse } from '../types/underwriter.types';
import { apiUrl, setHeadersNoAuth } from 'util/api/api.util';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { FacilityDetailsComponent } from './facility-details.component';
import { LoadingIndicator } from 'components/general/loading/loading-indicator.component';
import { LostCostsTrends } from './trends/lost-costs-trends.component';
import { OccupancyTrends } from './trends/occupancy-trends.component';
import { RatingTrends } from './trends/rating-trends.component';
import { RegulatoryTrends } from './trends/regulatory-trends.component';
import { Stack } from '@mui/system';
import { StaffingTrends } from './trends/staffing-trends.component';
import { SubContainer } from 'components/general/layout/sub-container.component';

type Props = {
    facilityMetadata: FacilityLocationDetails;
};

//Test facility numbers: 055001, 055002
export const UnderwriterFacility = ({ facilityMetadata }: Props) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [facilityDetails, setFacilityDetails] = useState<FacilityDetails>();
    const [trendData, setTrendData] = useState<FacilityTrendResponse[]>([]);

    const getData = useCallback(async () => {
        try {
            setLoading(true);

            const { data: details } = await apiUrl.get<FacilityDetails[]>(
                `/get_facility_info?federal_provider_number=${facilityMetadata.id}`,
                setHeadersNoAuth(),
            );
            const { data: trends } = await apiUrl.get<FacilityTrendResponse[]>(
                `/get_facility_trends?federal_provider_number=${facilityMetadata.id}&rollback=12`,
                setHeadersNoAuth(),
            );

            if (details.length > 0) setFacilityDetails(details[0]);

            setTrendData(trends);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }, [facilityMetadata]);

    useEffect(() => {
        getData();
    }, [facilityMetadata, getData]);

    return (
        <>
            {(trendData.length <= 0 || !facilityDetails || loading) && (
                <SubContainer sx={{ padding: '20px 30px' }}>
                    <Stack direction="column" justifyItems="center" sx={{ width: '100%', height: '200px' }}>
                        <LoadingIndicator />
                    </Stack>
                </SubContainer>
            )}
            {trendData.length > 0 && facilityDetails && !loading && (
                <Stack direction="column" spacing={2}>
                    <SubContainer sx={{ padding: '20px 30px' }}>
                        <FacilityDetailsComponent
                            metadata={facilityMetadata}
                            details={facilityDetails}
                            trendData={trendData}
                        />
                    </SubContainer>
                    <OccupancyTrends trendData={trendData} />
                    <RatingTrends trendData={trendData} />
                    <StaffingTrends trendData={trendData} />
                    <RegulatoryTrends trendData={trendData} />
                    <LostCostsTrends trendData={trendData} />
                </Stack>
            )}
        </>
    );
};
