/* eslint-disable indent */

import { Collapse, Tooltip, Typography } from '@mui/material';
import { FacilityDataPoint, FacilityIndex } from 'types/underwriter/underwriter.type';
import { useEffect, useRef, useState } from 'react';

import { CompactLineChart } from './compact-line-chart.component';
import { CompactTableRowContainer } from './compact-table-row-container.component';
import { Datum } from '@nivo/line';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack } from '@mui/system';
import { SubContainer } from 'components/general/layout/sub-container.component';
import { parse } from 'date-fns';
import { useDebounce } from 'hooks/useDebounce';

type Props = {
    index: FacilityIndex;
    alt?: boolean;
};

export const IndexRow = ({ index, alt }: Props) => {
    const { label, data, tooltip, format = 'default', displayTrend = true } = index;

    const [trend, setTrend] = useState<'up' | 'down' | 'equal'>();
    const [expand, setExpand] = useState<boolean>(false);
    const [chartData, setChartData] = useState<Datum[]>([]);
    const [lineCount, setLineCount] = useState<number>(1);
    const labelRef = useRef<HTMLDivElement | null>(null);

    const debouncedExpand = useDebounce(expand, 300);

    const handleClick = () => {
        if (data.length > 1) {
            setExpand(!expand);

            return;
        }
        if (!expand) return;
        setExpand(false);
    };

    useEffect(() => {
        const getTrend = () => {
            if (data.length < 2 || displayTrend == false) return 'equal';
            if (data[0].value > data[1].value) return 'up';
            if (data[0].value < data[1].value) return 'down';

            return 'equal';
        };
        const targetTrend = getTrend();

        setTrend(targetTrend);

        if (data.length > 1) {
            const mapedData: Datum[] = data.map((dataPoint: FacilityDataPoint) => ({
                x: parse(dataPoint.date, 'yyyy-MM-dd', new Date()),
                y: dataPoint.value,
            }));

            setChartData(mapedData);
        }
    }, [data, displayTrend]);

    useEffect(() => {
        const element = labelRef.current;

        if (element) {
            const rect = element.getBoundingClientRect();
            const lines = Math.round(rect.height / 24);

            setLineCount(Math.max(lines, 1));
        }
    }, []);

    return (
        <CompactTableRowContainer alt={alt}>
            {data.length > 1 ? (
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    }}
                    onClick={handleClick}
                >
                    <Collapse in={debouncedExpand} collapsedSize={`${24 * lineCount}px`}>
                        <Stack direction="column" spacing={1}>
                            <Stack direction="row">
                                <Stack direction="row" width="75%" spacing={2} alignItems="center">
                                    <Typography ref={labelRef}>{label}</Typography>
                                    {tooltip && (
                                        <Tooltip
                                            disableFocusListener
                                            disableTouchListener
                                            title={<Typography>{tooltip}</Typography>}
                                            componentsProps={{
                                                tooltip: {
                                                    sx: {
                                                        backgroundColor: 'primary.light',
                                                        border: '1px solid #393E46',
                                                        borderRadius: '10px',
                                                        padding: '10px',
                                                        fontSize: '14px',
                                                    },
                                                },
                                            }}
                                        >
                                            <InfoOutlinedIcon
                                                sx={{
                                                    fontSize: '16px',
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                </Stack>
                                <Stack
                                    direction="row"
                                    width="25%"
                                    spacing={2}
                                    alignItems="center"
                                    justifyContent="flex-end"
                                >
                                    <Typography
                                        sx={{
                                            color:
                                                trend === 'up'
                                                    ? 'text.green'
                                                    : trend === 'down'
                                                    ? 'text.danger'
                                                    : 'text.primary',
                                        }}
                                    >
                                        {format === 'currency' && '$ '}
                                        {data[0].value.toFixed(2)}
                                    </Typography>
                                    {debouncedExpand ? (
                                        <ExpandLessOutlinedIcon
                                            sx={{
                                                fontSize: '16px',
                                            }}
                                        />
                                    ) : (
                                        <ExpandMoreOutlinedIcon
                                            sx={{
                                                fontSize: '16px',
                                            }}
                                        />
                                    )}
                                </Stack>
                            </Stack>
                            <SubContainer minimal sx={{ marginRight: '30px' }}>
                                <CompactLineChart id="qm-rating" leftAxisSteps={3} data={chartData} height={250} />
                            </SubContainer>
                        </Stack>
                    </Collapse>
                </Stack>
            ) : (
                <Stack direction="column" spacing={2}>
                    <Stack direction="row">
                        <Stack direction="row" width="75%" spacing={2} alignItems="center">
                            <Typography>{label}</Typography>
                            {tooltip && (
                                <Tooltip
                                    disableFocusListener
                                    disableTouchListener
                                    title={<Typography>{tooltip}</Typography>}
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                backgroundColor: 'primary.light',
                                                border: '1px solid #393E46',
                                                borderRadius: '10px',
                                                padding: '10px',
                                                fontSize: '14px',
                                            },
                                        },
                                    }}
                                >
                                    <InfoOutlinedIcon
                                        sx={{
                                            fontSize: '16px',
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </Stack>
                        <Stack direction="row" width="25%" spacing={2} alignItems="center" justifyContent="flex-end">
                            <Typography>{data.length > 0 ? data[0].value : '0'}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </CompactTableRowContainer>
    );
};
