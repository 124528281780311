import { FacilityDataPoint, FacilityIndex } from 'types/underwriter/underwriter.type';
import { compareDesc, parseISO } from 'date-fns';

import { FacilityTrendResponse } from '../../types/underwriter.types';
import { IndexTable } from '../index-table.component';
import { useMemo } from 'react';

type Props = {
    trendData: FacilityTrendResponse[];
};

export const RegulatoryTrends = ({ trendData }: Props) => {
    const data: FacilityIndex[] = useMemo(() => {
        if (trendData.length <= 0) return [];
        const indexes: FacilityIndex[] = [];
        const indexPoints: FacilityDataPoint[][] = [[], [], [], []];

        trendData.forEach(trend => {
            indexPoints[0].push({
                value: Number(trend.index_months_since_last_survey.toFixed(2)),
                date: trend.filedate,
            });
            indexPoints[1].push({
                value: Number(trend.index_number_of_facility_reported_incidents.toFixed(2)),
                date: trend.filedate,
            });
            indexPoints[2].push({
                value: Number(trend.index_number_of_substantiated_complaints.toFixed(2)),
                date: trend.filedate,
            });
            indexPoints[3].push({
                value: Number(trend.index_number_of_citations_from_infection_control_inspections.toFixed(2)),
                date: trend.filedate,
            });
        });

        indexPoints[0].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[1].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[2].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[3].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));

        indexes.push(
            {
                label: 'Index Months Since Last Survey',
                tooltip:
                    'The months since last survey as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[0],
            },
            {
                label: 'Index Number of Facility Reported Incidents',
                tooltip:
                    'The number of facility reported incidents as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[1],
            },
            {
                label: 'Index Number of Substantiated Complaints',
                tooltip:
                    'The number of substantiated complaints as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[2],
            },
            {
                label: 'Index Number of Citations from Infection Control Inspections',
                tooltip:
                    'The number of citations from infection control inspections as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[3],
            },
        );

        return indexes;
    }, [trendData]);

    return <IndexTable title="Regulatory" indexes={data} />;
};
