import { Backdrop, Breadcrumbs, Button, Grid, Link, Typography } from '@mui/material';
import { orionApiUrl, pdfApiUrl, setHeaders, setHeadersNoAuth } from 'util/api/api.util';
import { useCallback, useEffect, useState } from 'react';

import { ApplicantInformation } from './accordions/modules/application-information.component';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Buffer } from 'buffer';
import { Container } from 'components/general/layout/container.component';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { FileInformation } from './accordions/modules/files.component';
import { FilesApplicationAccordion } from './accordions/files-application-accordion.component';
import { LimitsInformation } from './accordions/modules/limits-liability.component';
import { LoadingIndicator } from 'components/general/loading/loading-indicator.component';
import { LossRunsInformation } from './accordions/modules/narrative-losses.component';
import { PageTitle } from 'components/general/layout/page-title.component';
import { PolicyApplicationAccordion } from './accordions/policy-application-accordion.component';
import { PolicyInformation } from './accordions/modules/policy.component';
import { QuoteInformation } from './accordions/modules/quote.component';
import { ScheduleInformation } from './accordions/modules/schedule.component';
import { Stack } from '@mui/system';
import { UnderwriterApplicationAccordion } from './accordions/underwriter-application-accordion.component';
import { handleError } from 'util/error/handleError';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

export type ApplicationData = PolicyInformation & {
    id?: string;
    claim_limit_policy?: number;
    facilities: FacilityInsuranceData[];
    schedules: ScheduleInformation[];
    attachments: FileInformation[];
    quote_summary?: ApplicationQuoteSummary;
};

export type FacilityInsuranceData = ApplicantInformation &
    QuoteInformation &
    LimitsInformation & {
        id?: string;
        application_id?: string;
        is_over_4_claims_per_year?: boolean;
        notes?: string; //Plus 4 year claim notes
        claims: LossRunsInformation[];
        //PDF ONLY
        historical_losses_incurred?: number;
        historical_policy_duration_months?: number;
        historical_losses_incurred_over_deductible?: number;
        historical_premium_paid?: number;
    };

export type ApplicationQuoteSummary = {
    total_quoted_rate: number;
    state_tax_rate: number;
    total_state_tax: number;
    total_premium: number;
    overhead_rate: number;
    total_overhead: number;
    accelerant_fee_rate: number;
    total_accelerant_fee: number;
    fronting_fee_rate: number;
    total_fronting_fee: number;
    margin_rate: number;
    total_margin: number;
    tria_fee_rate: number;
    total_tria_fee: number;
    technology_fee_rate: number;
    total_technology_fee: number;
    broker_fee_rate: number;
    total_broker_fee: number;
    mga_fee_rate: number;
    total_mga_fee: number;
    stamp_fee_rate: number;
    total_stamp_fee: number;
    total_manual_debit: number;
    total_mcare_fee: number;
};

type DocumentResponse = {
    statusCode: number;
    body: string;
};

export const ApplicationDetailsPage = () => {
    const [summary, setSummary] = useState<ApplicationData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingHint, setLoadingHint] = useState<string>('');
    const [canSave, setCanSave] = useState<boolean>(false);

    const [currentInsured, setCurrentInsured] = useState<string>('Insured Name');

    const { id, facility_id } = useParams();
    const navigate = useNavigate();

    const downloadFile = (arrayBuffer: Buffer, type: string) => {
        const blob = new Blob([arrayBuffer], { type: type });
        const url = URL.createObjectURL(blob);

        window.open(url);
    };

    const generatePdf = async (targetDocument: string) => {
        try {
            setLoadingHint('Generating Document');
            setLoading(true);
            setCanSave(false);

            const { data: documentData } = await pdfApiUrl.post<DocumentResponse>(
                '/generate-pdf',
                { type: targetDocument, data: { ...summary, target_facility: facility_id } },
                {
                    headers: { Accept: '*/*', 'Content-Type': 'application/json' },
                },
            );

            const fileBytes = Buffer.from(documentData.body, 'base64');

            downloadFile(fileBytes, 'application/pdf;charset=utf-8');

            setLoading(false);
            setCanSave(true);
        } catch (error: unknown) {
            handleError(error, () => {
                console.log(JSON.stringify(error, null, 2));
            });
        }
    };

    const getData = useCallback(async () => {
        try {
            setLoading(true);
            setCanSave(false);

            const { data } = await orionApiUrl.get<ApplicationData>(`/v1/application/${id}`, setHeaders());
            const targetFacility = data.facilities.find(facility => facility.id == facility_id);

            if (targetFacility != null && targetFacility.insured_name) setCurrentInsured(targetFacility.insured_name);

            setSummary(data);

            setLoading(false);
            setCanSave(true);
        } catch (error) {
            handleError(error, () => {
                console.log(JSON.stringify(error, null, 2));
            });
        }
    }, [facility_id]);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <>
            <PageTitle text="Applications" />
            <Stack direction="row">
                <Breadcrumbs
                    separator={<ArrowForwardIosIcon sx={{ height: '12px' }} />}
                    sx={{ color: 'text.primary', marginBottom: '24px' }}
                >
                    <Link underline="hover" key="1" color="text.primary" onClick={() => navigate('/applications')}>
                        Applications
                    </Link>
                    <Typography fontWeight={500} color="secondary.main">
                        {currentInsured}
                    </Typography>
                </Breadcrumbs>
                <Stack direction="row" justifyContent={'end'} spacing={2} sx={{ flexGrow: 1, height: '30px' }}>
                    <Button
                        variant="contained"
                        size="medium"
                        onClick={() => {
                            generatePdf('QUOTE');
                        }}
                        disabled={!canSave}
                        sx={{ backgroundColor: 'primary.main' }}
                        startIcon={<FileDownloadOutlinedIcon />}
                    >
                        <Typography sx={{ size: '14px', fontWeight: '500' }} onClick={() => {}}>
                            QUOTE
                        </Typography>
                    </Button>
                    <Button
                        variant="contained"
                        size="medium"
                        onClick={() => {
                            generatePdf('UNDERWRITING');
                        }}
                        disabled={!canSave}
                        sx={{ backgroundColor: 'primary.main' }}
                        startIcon={<FileDownloadOutlinedIcon />}
                    >
                        <Typography sx={{ size: '14px', fontWeight: '500' }}>UNDERWRITING</Typography>
                    </Button>
                    <Button
                        variant="contained"
                        size="medium"
                        onClick={() => {
                            generatePdf('POLICY');
                        }}
                        disabled={!canSave}
                        sx={{ backgroundColor: 'primary.main' }}
                        startIcon={<FileDownloadOutlinedIcon />}
                    >
                        <Typography sx={{ size: '14px', fontWeight: '500' }}>POLICY</Typography>
                    </Button>
                    <Button
                        variant="contained"
                        size="medium"
                        onClick={() => {
                            generatePdf('BILLING');
                        }}
                        disabled={!canSave}
                        sx={{ backgroundColor: 'primary.main' }}
                        startIcon={<FileDownloadOutlinedIcon />}
                    >
                        <Typography sx={{ size: '14px', fontWeight: '500' }}>BILLING</Typography>
                    </Button>
                    <Button
                        variant="contained"
                        size="medium"
                        onClick={() => {
                            generatePdf('TUG');
                        }}
                        disabled={!canSave}
                        sx={{ backgroundColor: 'primary.main' }}
                        startIcon={<FileDownloadOutlinedIcon />}
                    >
                        <Typography sx={{ size: '14px', fontWeight: '500' }}>TUG</Typography>
                    </Button>
                </Stack>
            </Stack>
            <Stack sx={{ paddingTop: '12px' }}>
                <Grid container spacing={2} alignItems="stretch">
                    {!summary && (
                        <Grid item xs={12}>
                            <Container>
                                <Stack direction="column" justifyItems="center" sx={{ width: '100%', height: '200px' }}>
                                    <LoadingIndicator />
                                </Stack>
                            </Container>
                        </Grid>
                    )}
                    {summary && (
                        <Stack direction="column" width="100%">
                            <Backdrop sx={{ zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
                                <LoadingIndicator color="#000000BF" text_hint={loadingHint} />
                            </Backdrop>
                            <Grid container spacing={2}>
                                <UnderwriterApplicationAccordion
                                    data={summary}
                                    onNoKnownLoss={() => generatePdf('NO_KNOWN_LOSS')}
                                    setLoadingState={(newState, hint) => {
                                        setLoadingHint(hint);
                                        setLoading(newState);
                                    }}
                                    onChange={() => {
                                        getData();
                                    }}
                                />
                                <PolicyApplicationAccordion
                                    data={summary}
                                    setLoadingState={(newState, hint) => {
                                        setLoadingHint(hint);
                                        setLoading(newState);
                                    }}
                                    onChange={() => {
                                        getData();
                                    }}
                                />
                                <FilesApplicationAccordion
                                    data={summary}
                                    setLoadingState={(newState, hint) => {
                                        setLoadingHint(hint);
                                        setLoading(newState);
                                    }}
                                    onChange={() => {
                                        getData();
                                    }}
                                />
                            </Grid>
                        </Stack>
                    )}
                </Grid>
            </Stack>
        </>
    );
};
