export const darkPallette = {
    common: {
        black: '#000',
        white: '#fff',
    },
    primary: {
        main: '#7A94D7',
        light: '#222831',
        gray: '#393E46',
        yellow: '#FFD369',
    },
    secondary: {
        main: '#fff',
    },
    divider: '#7A94D7',
    background: {
        paper: '#222831',
        default: '#393E46',
        dark: '#1E1E1E',
        deep: '#1E242D',
        backdrop: '#000BF',
        danger: '#8F2B2F',
    },
    text: {
        primary: '#EEEEEE',
        secondary: '#212121',
        discrete: '#B0B0B0',
        disabled: '#7a7a7a',
        danger: '#CC5C5C',
        green: '#67994F',
    },
    action: {
        hover: '#7A94D7',
        active: '#eeeeee',
        inactive: '#DEE5FF',
    },
};
