import { MenuItem, Typography } from '@mui/material';

import { InputFieldSelect } from 'components/pages/underwriter/facility-module/quote-dialogue/inputs/field-select.component';

interface Props {
    status: string | null;
    disabled?: boolean;
    discrete?: boolean;
    maxWidth?: string;
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export const SubmissionStatusSelector = ({
    status,
    disabled = false,
    discrete = false,
    maxWidth = 'none',
    onChange,
}: Props) => {
    return (
        <InputFieldSelect
            disabled={disabled}
            height="40px"
            color={discrete ? 'transparent' : '#8F2B2F'}
            borderColor={discrete ? 'text.disabled' : '#8F2B2F'}
            value={status ? status : 'placeholder'}
            id="submission_status"
            label=""
            onChange={onChange}
            sx={{
                width: maxWidth === 'none' ? undefined : maxWidth,
            }}
        >
            <MenuItem disabled value="placeholder">
                <Typography sx={{ color: 'text.discrete', fontStyle: 'oblique' }}>Submission Status</Typography>
            </MenuItem>
            <MenuItem value="Draft">Draft</MenuItem>
            <MenuItem value="Pending Quote">Pending Quote</MenuItem>
            <MenuItem value="Pending Acceptance">Pending Acceptance</MenuItem>
            <MenuItem value="Pending Authority">Pending Authority</MenuItem>
            <MenuItem value="Pending Binding">Pending Binding</MenuItem>
            <MenuItem value="Broker Declined">Broker Declined</MenuItem>
            <MenuItem value="Authority Declined">Authority Declined</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
        </InputFieldSelect>
    );
};
