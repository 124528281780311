import { MapBounds } from 'types/underwriter/underwriter.type';

export function getUsState(abbreviation: UsState) {
    return usStates[abbreviation];
}

export enum UsState {
    AL = 'AL',
    AK = 'AK',
    AR = 'AR',
    AZ = 'AZ',
    CA = 'CA',
    CO = 'CO',
    CT = 'CT',
    DC = 'DC',
    DE = 'DE',
    FL = 'FL',
    GA = 'GA',
    HI = 'HI',
    IA = 'IA',
    ID = 'ID',
    IL = 'IL',
    IN = 'IN',
    KS = 'KS',
    KY = 'KY',
    LA = 'LA',
    MA = 'MA',
    MD = 'MD',
    ME = 'ME',
    MI = 'MI',
    MN = 'MN',
    MO = 'MO',
    MS = 'MS',
    MT = 'MT',
    NC = 'NC',
    ND = 'ND',
    NE = 'NE',
    NH = 'NH',
    NJ = 'NJ',
    NM = 'NM',
    NV = 'NV',
    NY = 'NY',
    OK = 'OK',
    OH = 'OH',
    OR = 'OR',
    PA = 'PA',
    RI = 'RI',
    SC = 'SC',
    SD = 'SD',
    TN = 'TN',
    TX = 'TX',
    UT = 'UT',
    VA = 'VA',
    VT = 'VT',
    WA = 'WA',
    WI = 'WI',
    WV = 'WV',
    WY = 'WY',
}

const usStates: {
    [key: string]: { name: string; abbreviation: string; bounds: MapBounds };
} = {
    AL: {
        name: 'Alabama',
        abbreviation: 'AL',
        bounds: {
            north: 35.008028,
            south: 30.2234,
            west: -88.473226,
            east: -84.888246,
        },
    },
    AK: {
        name: 'Alaska',
        abbreviation: 'AK',
        bounds: {
            north: 71.5388,
            south: 51.21,
            west: -179.148909,
            east: 179.77847,
        },
    },
    AZ: {
        name: 'Arizona',
        abbreviation: 'AZ',
        bounds: {
            north: 37.004259,
            south: 31.332177,
            west: -114.818269,
            east: -109.045223,
        },
    },
    AR: {
        name: 'Arkansas',
        abbreviation: 'AR',
        bounds: {
            north: 36.499698,
            south: 33.004106,
            west: -94.617919,
            east: -89.644791,
        },
    },
    CA: {
        name: 'California',
        abbreviation: 'CA',
        bounds: {
            north: 42.009517,
            south: 32.528832,
            west: -124.482003,
            east: -114.131211,
        },
    },
    CO: {
        name: 'Colorado',
        abbreviation: 'CO',
        bounds: {
            north: 41.003444,
            south: 36.993076,
            west: -109.045223,
            east: -102.041524,
        },
    },
    CT: {
        name: 'Connecticut',
        abbreviation: 'CT',
        bounds: {
            north: 42.049518,
            south: 41.220554,
            west: -73.727775,
            east: -71.787038,
        },
    },
    DE: {
        name: 'Delaware',
        abbreviation: 'DE',
        bounds: {
            north: 39.839007,
            south: 38.451013,
            west: -75.78891,
            east: -75.048939,
        },
    },
    FL: {
        name: 'Florida',
        abbreviation: 'FL',
        bounds: {
            north: 31.000888,
            south: 24.396308,
            west: -87.634938,
            east: -80.031362,
        },
    },
    GA: {
        name: 'Georgia',
        abbreviation: 'GA',
        bounds: {
            north: 35.000659,
            south: 30.355644,
            west: -85.605165,
            east: -80.839729,
        },
    },
    HI: {
        name: 'Hawaii',
        abbreviation: 'HI',
        bounds: {
            north: 28.4056,
            south: 18.7763,
            west: -178.3689,
            east: -154.8067,
        },
    },
    ID: {
        name: 'Idaho',
        abbreviation: 'ID',
        bounds: {
            north: 49.000239,
            south: 41.988208,
            west: -117.243027,
            east: -111.043564,
        },
    },
    IL: {
        name: 'Illinois',
        abbreviation: 'IL',
        bounds: {
            north: 42.508337,
            south: 36.970298,
            west: -91.512974,
            east: -87.019935,
        },
    },
    IN: {
        name: 'Indiana',
        abbreviation: 'IN',
        bounds: {
            north: 41.760592,
            south: 37.777143,
            west: -88.09776,
            east: -84.784579,
        },
    },
    IA: {
        name: 'Iowa',
        abbreviation: 'IA',
        bounds: {
            north: 43.501196,
            south: 40.375501,
            west: -96.639704,
            east: -90.140061,
        },
    },
    KS: {
        name: 'Kansas',
        abbreviation: 'KS',
        bounds: {
            north: 40.003162,
            south: 36.993076,
            west: -102.050173,
            east: -94.588413,
        },
    },
    KY: {
        name: 'Kentucky',
        abbreviation: 'KY',
        bounds: {
            north: 39.147359,
            south: 36.497129,
            west: -89.415402,
            east: -81.964971,
        },
    },
    LA: {
        name: 'Louisiana',
        abbreviation: 'LA',
        bounds: {
            north: 33.019741,
            south: 28.855457,
            west: -94.043147,
            east: -88.758389,
        },
    },
    ME: {
        name: 'Maine',
        abbreviation: 'ME',
        bounds: {
            north: 47.459686,
            south: 42.977764,
            west: -71.083924,
            east: -66.93457,
        },
    },
    MD: {
        name: 'Maryland',
        abbreviation: 'MD',
        bounds: {
            north: 39.723037,
            south: 37.913897,
            west: -79.487651,
            east: -74.986124,
        },
    },
    MA: {
        name: 'Massachusetts',
        abbreviation: 'MA',
        bounds: {
            north: 42.886447,
            south: 41.18636,
            west: -73.508142,
            east: -69.929222,
        },
    },
    MI: {
        name: 'Michigan',
        abbreviation: 'MI',
        bounds: {
            north: 48.303307,
            south: 41.696118,
            west: -90.418136,
            east: -82.122104,
        },
    },
    MN: {
        name: 'Minnesota',
        abbreviation: 'MN',
        bounds: {
            north: 49.384358,
            south: 43.499356,
            west: -97.239209,
            east: -89.489226,
        },
    },
    MS: {
        name: 'Mississippi',
        abbreviation: 'MS',
        bounds: {
            north: 35.004102,
            south: 30.173943,
            west: -91.655009,
            east: -88.09776,
        },
    },
    MO: {
        name: 'Missouri',
        abbreviation: 'MO',
        bounds: {
            north: 40.61364,
            south: 35.995788,
            west: -95.774704,
            east: -89.100199,
        },
    },
    MT: {
        name: 'Montana',
        abbreviation: 'MT',
        bounds: {
            north: 49.00139,
            south: 44.358221,
            west: -116.04911,
            east: -104.039648,
        },
    },
    NE: {
        name: 'Nebraska',
        abbreviation: 'NE',
        bounds: {
            north: 43.001708,
            south: 39.999938,
            west: -104.053011,
            east: -95.30829,
        },
    },
    NV: {
        name: 'Nevada',
        abbreviation: 'NV',
        bounds: {
            north: 42.002207,
            south: 35.001857,
            west: -120.005746,
            east: -114.039648,
        },
    },
    NH: {
        name: 'New Hampshire',
        abbreviation: 'NH',
        bounds: {
            north: 45.304032,
            south: 42.696987,
            west: -72.558061,
            east: -70.561204,
        },
    },
    NJ: {
        name: 'New Jersey',
        abbreviation: 'NJ',
        bounds: {
            north: 41.357423,
            south: 38.928518,
            west: -75.563839,
            east: -73.88506,
        },
    },
    NM: {
        name: 'New Mexico',
        abbreviation: 'NM',
        bounds: {
            north: 37.004259,
            south: 31.332177,
            west: -109.045223,
            east: -103.002944,
        },
    },
    NY: {
        name: 'New York',
        abbreviation: 'NY',
        bounds: {
            north: 45.01585,
            south: 40.477398,
            west: -79.76259,
            east: -71.185083,
        },
    },
    NC: {
        name: 'North Carolina',
        abbreviation: 'NC',
        bounds: {
            north: 36.588117,
            south: 33.752879,
            west: -84.321869,
            east: -75.460621,
        },
    },
    ND: {
        name: 'North Dakota',
        abbreviation: 'ND',
        bounds: {
            north: 49.000239,
            south: 45.935072,
            west: -104.048901,
            east: -96.554507,
        },
    },
    OH: {
        name: 'Ohio',
        abbreviation: 'OH',
        bounds: {
            north: 41.977523,
            south: 38.403202,
            west: -85.805118,
            east: -80.518693,
        },
    },
    OK: {
        name: 'Oklahoma',
        abbreviation: 'OK',
        bounds: {
            north: 37.004259,
            south: 33.615832,
            west: -103.002944,
            east: -94.431108,
        },
    },
    OR: {
        name: 'Oregon',
        abbreviation: 'OR',
        bounds: {
            north: 46.289754,
            south: 41.991059,
            west: -124.566244,
            east: -116.463262,
        },
    },
    PA: {
        name: 'Pennsylvania',
        abbreviation: 'PA',
        bounds: {
            north: 42.26986,
            south: 39.7198,
            west: -80.519851,
            east: -74.689516,
        },
    },
    PR: {
        name: 'Puerto Rico',
        abbreviation: 'PR',
        bounds: {
            north: 18.5158,
            south: 17.9269,
            west: -67.9425,
            east: -65.2207,
        },
    },
    RI: {
        name: 'Rhode Island',
        abbreviation: 'RI',
        bounds: {
            north: 42.018798,
            south: 41.18636,
            west: -71.508275,
            east: -71.185083,
        },
    },
    SC: {
        name: 'South Carolina',
        abbreviation: 'SC',
        bounds: {
            north: 35.215402,
            south: 32.0346,
            west: -83.673316,
            east: -78.499301,
        },
    },
    SD: {
        name: 'South Dakota',
        abbreviation: 'SD',
        bounds: {
            north: 45.94545,
            south: 42.479635,
            west: -104.057698,
            east: -96.436589,
        },
    },
    TN: {
        name: 'Tennessee',
        abbreviation: 'TN',
        bounds: {
            north: 36.688223,
            south: 34.982924,
            west: -90.310298,
            east: -81.6469,
        },
    },
    TX: {
        name: 'Texas',
        abbreviation: 'TX',
        bounds: {
            north: 36.500704,
            south: 25.837377,
            west: -106.645646,
            east: -93.507021,
        },
    },
    UT: {
        name: 'Utah',
        abbreviation: 'UT',
        bounds: {
            north: 42.001567,
            south: 36.997084,
            west: -114.039648,
            east: -109.045223,
        },
    },
    VT: {
        name: 'Vermont',
        abbreviation: 'VT',
        bounds: {
            north: 45.016659,
            south: 42.726853,
            west: -73.433395,
            east: -71.465051,
        },
    },
    VI: {
        name: 'Virgin Islands',
        abbreviation: 'VI',
        bounds: {
            north: 18.3771,
            south: 17.674737,
            west: -65.1014,
            east: -64.5658,
        },
    },
    VA: {
        name: 'Virginia',
        abbreviation: 'VA',
        bounds: {
            north: 39.464886,
            south: 36.540738,
            west: -83.675049,
            east: -75.242266,
        },
    },
    WA: {
        name: 'Washington',
        abbreviation: 'WA',
        bounds: {
            north: 49.002494,
            south: 45.543541,
            west: -124.566244,
            east: -116.463262,
        },
    },
    WV: {
        name: 'West Virginia',
        abbreviation: 'WV',
        bounds: {
            north: 40.638801,
            south: 37.201483,
            west: -82.644739,
            east: -77.719519,
        },
    },
    WI: {
        name: 'Wisconsin',
        abbreviation: 'WI',
        bounds: {
            north: 47.3025,
            south: 42.491983,
            west: -92.889433,
            east: -86.805415,
        },
    },
    WY: {
        name: 'Wyoming',
        abbreviation: 'WY',
        bounds: {
            north: 45.00585,
            south: 40.994746,
            west: -111.045223,
            east: -104.039648,
        },
    },
};
