import { Box, SxProps, Theme } from '@mui/material';

interface Props {
    children: JSX.Element;
    sx?: SxProps<Theme>;
}

export const GraphTooltip = ({ children, sx }: Props) => {
    return (
        <Box
            sx={{
                padding: '5px',
                backgroundColor: 'primary.light',
                borderStyle: 'solid',
                borderRadius: '5px',
                borderWidth: '1px',
                borderColor: '#393E46',
                bottom: '10px',
                ...sx,
            }}
        >
            {children}
        </Box>
    );
};
