import { Box, InputAdornment, MenuItem, Stack, SxProps, TextField, Theme, Typography } from '@mui/material';

import { InputFieldSelect } from 'components/pages/underwriter/facility-module/quote-dialogue/inputs/field-select.component';

interface Props {
    value: string | string[];
    options: string[];
    id: string;
    label: string;
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    width?: string;
    smallField?: boolean;
    bold?: boolean;
    blue?: boolean;
    multiple?: boolean;
    start_spacing?: string;
    end_spacing?: string;
    sx?: SxProps<Theme>;
}

export const SimpleDropdownInput = ({
    value,
    options,
    id,
    label,
    onChange,
    width = '100%',
    smallField = false,
    bold = true,
    blue = false,
    multiple = false,
    start_spacing = '0%',
    end_spacing = '0%',
    sx,
}: Props) => {
    return (
        <Stack direction="row" spacing={2} alignItems="center" width={width}>
            <Typography sx={{ fontWeight: bold ? '500' : undefined, width: smallField ? '70%' : '50%' }}>
                {label}
            </Typography>
            <Box sx={{ width: start_spacing }}></Box>
            <TextField
                select
                fullWidth
                name={id}
                value={value}
                onChange={onChange}
                InputProps={{
                    sx: {
                        height: '40px',
                        color: blue ? 'primary.main' : undefined,
                        backgroundColor: 'background.deep',
                    },
                }}
                sx={{
                    height: '40px',
                    color: '#CC5C5C',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderStyle: 'none',
                    },
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                        display: 'none',
                    },
                    '& input[type=number]': {
                        MozAppearance: 'textfield',
                    },
                    ...sx,
                }}
                label=""
                SelectProps={{
                    multiple: multiple,
                }}
            >
                <MenuItem disabled value="placeholder">
                    <Typography sx={{ color: 'text.discrete', fontStyle: 'oblique' }}>{label}</Typography>
                </MenuItem>
                {options.map((option, index) => (
                    <MenuItem key={index} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
            <Box sx={{ width: end_spacing }}></Box>
        </Stack>
    );
};
