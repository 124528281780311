import {
    AgentInfo,
    AgentSearchInput,
    AgentSearchInputRef,
} from '../../../add-application/field-components/agent-search-input.component';
import {
    BrokerInfo,
    BrokerSearchInput,
    BrokerSearchInputRef,
} from '../../../add-application/field-components/broker-search-input.component';
import { Button, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { orionApiUrl, setHeaders } from 'util/api/api.util';
import { useEffect, useRef, useState } from 'react';

import { AcceptanceStatusSelector } from '../../../specific-components/acceptance-status.component';
import { FacilityInsuranceData } from '../../application-details-page.component';
import { FacilitySearchInput } from '../../../add-application/field-components/facility-search-input.component';
import { InputFieldSelect } from 'components/pages/underwriter/facility-module/quote-dialogue/inputs/field-select.component';
import { SimpleDateInput } from '../../../add-application/field-components/simple-date-input.component';
import { SimpleInput } from '../../../add-application/field-components/simple-input.component';
import { SubContainer } from 'components/general/layout/sub-container.component';
import { SubmissionStatusSelector } from '../../../specific-components/submission-status.component';
import { format } from 'date-fns';
import { handleError } from 'util/error/handleError';
import { useParams } from 'react-router-dom';

export function extractApplicantInformation(data: unknown): ApplicantInformation {
    const applicantInformation: Partial<ApplicantInformation> = {};
    const validKeys: Array<keyof ApplicantInformation> = [
        'submission_status',
        'insured_name',
        'insured_address',
        'facility_name',
        'facility_address',
        'facility_provider_number',
        'facility_beds_licensed',
        'facility_beds_occupied',
        'facility_rating',
        'submission_date',
        'retroactive_date',
        'renewal_date',
        'brokerage_name',
        'brokerage_address',
        'agent_name',
        'agent_email',
        'agent_id',
        'expiring_deductible',
    ];

    if (typeof data === 'object' && data !== null) {
        for (const key of validKeys) {
            if (key in data) {
                if (key != 'expiring_deductible') {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'unknown'.
                    applicantInformation[key] = data[key];
                } else if (key == 'expiring_deductible') {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'unknown'.
                    const value = typeof data[key] == 'number' ? data[key].toFixed(2) : data[key];

                    applicantInformation[key] = value == null ? 0 : value;
                }
            }
        }
    }

    return applicantInformation as ApplicantInformation;
}

export type ApplicantInformation = {
    submission_status?: string;
    insured_name?: string;
    insured_address?: string;
    facility_name?: string;
    facility_address?: string;
    facility_provider_number?: string;
    facility_beds_licensed?: number;
    facility_beds_occupied?: number;
    facility_rating?: string;
    submission_date?: string;
    renewal_date?: string;
    retroactive_date?: string;
    expiring_deductible?: number;
    agent_id?: string;
    brokerage_name?: string;
    brokerage_address?: string;
    agent_name?: string;
    agent_email?: string;
};

type Props = {
    data: ApplicantInformation;
    onChange: (changes: ApplicantInformation) => void;
    autocomplete?: boolean;
    autocompleteFacility?: boolean;
    hideStatus?: boolean;
    showRequired?: boolean;
    missingProperties?: string[];
};

export const ApplicantInformationComponent = ({
    data,
    onChange,
    autocomplete = false,
    autocompleteFacility = false,
    hideStatus = false,
    showRequired = false,
    missingProperties = [],
}: Props) => {
    const [brokerId, setBrokerId] = useState<string | null>(null);
    const [brokerInfo, setBrokerInfo] = useState<BrokerInfo | null>(null);
    const [agentInfo, setAgentInfo] = useState<AgentInfo | null>();
    const [canUnderwrite, setCanUnderwrite] = useState<boolean>(true);
    const [status, setStatus] = useState<string>('placeholder');
    const [loading, setLoading] = useState<boolean>(false);
    const brokerInputRef = useRef<BrokerSearchInputRef>(null);
    const agentInputRef = useRef<AgentSearchInputRef>(null);

    const { id, facility_id } = useParams();

    const handleFieldChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
        const { name, value } = event.target;
        const parsedValue = value.trim().length <= 0 ? null : value;

        onChange({
            ...data,
            [name]: parsedValue,
        });
    };

    const handleNumericFieldChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
        const { name, value } = event.target;
        const parsedValue: number = value.trim().length <= 0 ? 0 : +value;

        onChange({
            ...data,
            [name]: parsedValue,
        });
    };

    const handleDateFieldChange = (value: Date | null, key: string) => {
        onChange({
            ...data,
            [key]: value === null ? null : format(value, 'yyyy-MM-dd'),
        });
    };

    const handleClearBrokerFilter = () => {
        if (brokerInputRef.current) brokerInputRef.current.clearInput();
    };

    const handleClearAgentFilter = () => {
        if (agentInputRef.current) agentInputRef.current.clearInput();
    };

    const updateStatus = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const { name, value } = event.target;

            setLoading(true);
            const { data: result } = await orionApiUrl.patch<FacilityInsuranceData>(
                `/v1/application/${id}/facilities`,
                [
                    {
                        id: facility_id,
                        submission_status: value,
                    },
                ],
                setHeaders(),
            );

            setStatus(value);
            onChange({
                ...data,
                submission_status: value,
            });
            setLoading(false);
        } catch (error) {
            handleError(error, () => {
                setLoading(false);
                console.log(JSON.stringify(error, null, 2));
            });
        }
    };

    useEffect(() => {
        setStatus(data.submission_status != null ? data.submission_status : 'placeholder');
    }, [data]);

    return (
        <Grid item xs={12}>
            <SubContainer sx={{ padding: '20px 30px' }}>
                <Stack direction="column" spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography variant="h6" sx={{ flexGrow: '1' }}>
                            Applicant Information
                        </Typography>
                        {!hideStatus && (
                            <SubmissionStatusSelector status={status} disabled={loading} onChange={updateStatus} />
                        )}
                    </Stack>
                    <Grid container gap={5} sx={{ paddingTop: '0px' }}>
                        <Grid item xs={5}>
                            <Stack direction="column" spacing={1}>
                                <SimpleDateInput
                                    showMissing={missingProperties.includes('submission_date')}
                                    value={data.submission_date}
                                    label={`Submission Date${showRequired ? ' *' : ''}`}
                                    width="100%"
                                    onChange={newValue => handleDateFieldChange(newValue, 'submission_date')}
                                />
                                <SimpleDateInput
                                    showMissing={missingProperties.includes('renewal_date')}
                                    value={data.renewal_date}
                                    label={`Renewal Date${showRequired ? ' *' : ''}`}
                                    width="100%"
                                    onChange={newValue => handleDateFieldChange(newValue, 'renewal_date')}
                                />
                                <SimpleDateInput
                                    value={data.retroactive_date}
                                    label="Retro Date"
                                    width="100%"
                                    onChange={newValue => handleDateFieldChange(newValue, 'retroactive_date')}
                                />
                                <SimpleInput
                                    label="Expiring Deductible"
                                    id="expiring_deductible"
                                    type="currency"
                                    value={data.expiring_deductible}
                                    width="100%"
                                    onChange={handleNumericFieldChange}
                                />
                                {!autocomplete && (
                                    <SimpleInput
                                        label={`Brokerage${showRequired ? ' *' : ''}`}
                                        id="broker_name"
                                        value={data.brokerage_name}
                                        width="100%"
                                        onChange={handleFieldChange}
                                    />
                                )}
                                {autocomplete && (
                                    <BrokerSearchInput
                                        showMissing={missingProperties.includes('brokerage_name')}
                                        ref={brokerInputRef}
                                        label={`Brokerage${showRequired ? ' *' : ''}`}
                                        id="brokerage_name"
                                        width="100%"
                                        onClear={() => {
                                            setBrokerId(null);
                                            onChange({
                                                ...data,
                                                brokerage_name: '',
                                                brokerage_address: '',
                                                agent_id: '',
                                                agent_email: '',
                                            });
                                            handleClearBrokerFilter();
                                            handleClearAgentFilter();

                                            return;
                                        }}
                                        onChange={newValue => {
                                            setBrokerId(newValue ? newValue.id : null);
                                            onChange({
                                                ...data,
                                                brokerage_name: newValue ? newValue.name : 'N/A',
                                                brokerage_address: newValue ? newValue.address : 'N/A',
                                            });
                                        }}
                                    />
                                )}
                                <SimpleInput
                                    showMissing={missingProperties.includes('brokerage_address')}
                                    label={`Brokerage Address${showRequired ? ' *' : ''}`}
                                    id="brokerage_address"
                                    value={data.brokerage_address}
                                    width="100%"
                                    onChange={handleFieldChange}
                                />
                                {!autocomplete && (
                                    <SimpleInput
                                        label={`Agent${showRequired ? ' *' : ''}`}
                                        id="agent_name"
                                        value={data.agent_name}
                                        width="100%"
                                        onChange={handleFieldChange}
                                    />
                                )}
                                {autocomplete && (
                                    <AgentSearchInput
                                        showMissing={missingProperties.includes('agent_name')}
                                        ref={agentInputRef}
                                        label={`Agent${showRequired ? ' *' : ''}`}
                                        id="agent_name"
                                        brokerId={brokerId}
                                        width="100%"
                                        onChange={newValue => {
                                            onChange({
                                                ...data,
                                                agent_name: newValue ? newValue.name : 'N/A',
                                                agent_email: newValue ? newValue.email : 'N/A',
                                                agent_id: newValue?.id,
                                            });
                                        }}
                                        onClear={() => {
                                            onChange({
                                                ...data,
                                                agent_name: '',
                                                agent_email: '',
                                            });
                                            handleClearAgentFilter();

                                            return;
                                        }}
                                    />
                                )}
                                <SimpleInput
                                    showMissing={missingProperties.includes('agent_email')}
                                    label={`Agent Email${showRequired ? ' *' : ''}`}
                                    id="agent_email"
                                    value={data.agent_email}
                                    width="100%"
                                    onChange={handleFieldChange}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="column" spacing={1}>
                                <SimpleInput
                                    showMissing={missingProperties.includes('insured_name')}
                                    label={`Insured Name${showRequired ? ' *' : ''}`}
                                    id="insured_name"
                                    value={data.insured_name}
                                    width="100%"
                                    onChange={handleFieldChange}
                                />
                                <SimpleInput
                                    showMissing={missingProperties.includes('insured_address')}
                                    label={`Insured Address${showRequired ? ' *' : ''}`}
                                    id="insured_address"
                                    value={data.insured_address}
                                    width="100%"
                                    onChange={handleFieldChange}
                                />
                                {!autocompleteFacility && (
                                    <SimpleInput
                                        label={`Facility Name${showRequired ? ' *' : ''}`}
                                        id="facility_name"
                                        value={data.facility_name}
                                        width="100%"
                                        onChange={handleFieldChange}
                                    />
                                )}
                                {autocompleteFacility && (
                                    <FacilitySearchInput
                                        showMissing={missingProperties.includes('facility_name')}
                                        label={`Facility Name${showRequired ? ' *' : ''}`}
                                        id="facility_name"
                                        width="100%"
                                        onChange={(event, newValue) => {
                                            if (newValue != null) {
                                                setCanUnderwrite(newValue.has_pricing);
                                            }
                                            onChange({
                                                ...data,
                                                facility_name: newValue ? newValue.facility_name : 'N/A',
                                                facility_provider_number: newValue ? newValue.provider_number : 'N/A',
                                                facility_address: newValue
                                                    ? `${newValue.address}, ${newValue.state_code} ${newValue.zipcode}`
                                                    : 'N/A',
                                                facility_rating: newValue ? `${newValue.overall_rating}` : '0',
                                                facility_beds_licensed: newValue ? newValue.licensed_beds : 0,
                                                facility_beds_occupied: newValue ? newValue.occupied_beds : 0,
                                            });
                                        }}
                                    />
                                )}
                                <SimpleInput
                                    showMissing={missingProperties.includes('facility_provider_number')}
                                    label={`Provider #${showRequired ? ' *' : ''}`}
                                    id="facility_provider_number"
                                    type="numeric"
                                    value={data.facility_provider_number}
                                    width="100%"
                                    onChange={handleFieldChange}
                                />
                                <SimpleInput
                                    showMissing={missingProperties.includes('facility_address')}
                                    label={`Facility Address${showRequired ? ' *' : ''}`}
                                    id="facility_address"
                                    value={data.facility_address}
                                    width="100%"
                                    onChange={handleFieldChange}
                                />
                                <SimpleInput
                                    showMissing={missingProperties.includes('facility_rating')}
                                    label={`Overall Star Rating${showRequired ? ' *' : ''}`}
                                    id="facility_rating"
                                    value={data.facility_rating}
                                    type="numeric"
                                    width="100%"
                                    onChange={handleFieldChange}
                                />
                                <SimpleInput
                                    showMissing={missingProperties.includes('facility_beds_licensed')}
                                    label={`Licensed Beds${showRequired ? ' *' : ''}`}
                                    id="facility_beds_licensed"
                                    type="numeric"
                                    value={data.facility_beds_licensed}
                                    width="100%"
                                    onChange={handleFieldChange}
                                />
                                <SimpleInput
                                    showMissing={missingProperties.includes('facility_beds_occupied')}
                                    label={`Occupied Beds${showRequired ? ' *' : ''}`}
                                    id="facility_beds_occupied"
                                    type="numeric"
                                    value={data.facility_beds_occupied}
                                    width="100%"
                                    onChange={handleFieldChange}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                    {/* <Stack direction="row">
                        <Button sx={{ margingY: '24px' }}>
                            <Typography fontWeight={500}>+ ADD FACILITY</Typography>
                        </Button>
                    </Stack> */}
                </Stack>
            </SubContainer>
            {!canUnderwrite && (
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="center" paddingTop="12px">
                        <Typography sx={{ color: 'text.danger' }}>
                            Pricing information not available for this facility, please choose a different one.
                        </Typography>
                    </Stack>
                </Grid>
            )}
        </Grid>
    );
};
