import { FacilityDataPoint, FacilityIndex } from 'types/underwriter/underwriter.type';
import { compareDesc, parseISO } from 'date-fns';

import { FacilityTrendResponse } from '../../types/underwriter.types';
import { IndexTable } from '../index-table.component';
import { useMemo } from 'react';

type Props = {
    trendData: FacilityTrendResponse[];
};

export const StaffingTrends = ({ trendData }: Props) => {
    const data: FacilityIndex[] = useMemo(() => {
        if (trendData.length <= 0) return [];
        const indexes: FacilityIndex[] = [];
        const indexPoints: FacilityDataPoint[][] = [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []];

        trendData.forEach(trend => {
            indexPoints[0].push({ value: Number(trend?.index_staffing_rating.toFixed(2)), date: trend.filedate });
            indexPoints[1].push({
                value: Number((trend.index_reported_nurse_aide_staffing_hours_per_resident_per_day ?? 0).toFixed(2)),
                date: trend.filedate,
            });
            indexPoints[2].push({
                value: Number((trend.index_reported_lpn_staffing_hours_per_resident_per_day ?? 0).toFixed(2)),
                date: trend.filedate,
            });
            indexPoints[3].push({
                value: Number((trend.index_reported_rn_staffing_hours_per_resident_per_day ?? 0).toFixed(2)),
                date: trend.filedate,
            });
            indexPoints[4].push({
                value: Number((trend.index_reported_licensed_staffing_hours_per_resident_per_day ?? 0).toFixed(2)),
                date: trend.filedate,
            });
            indexPoints[5].push({
                value: Number((trend.index_reported_nurse_aide_staffing_hours_per_resident_per_day ?? 0).toFixed(2)),
                date: trend.filedate,
            });
            indexPoints[6].push({
                value: Number(
                    (trend.index_reported_physical_therapist_staffing_hours_per_resident_per_day ?? 0).toFixed(2),
                ),
                date: trend.filedate,
            });
            indexPoints[7].push({
                value: Number(
                    (trend.index_total_number_of_nurse_staff_hours_per_resident_per_day_on_the_weekend ?? 0).toFixed(2),
                ),
                date: trend.filedate,
            });
            indexPoints[8].push({
                value: Number((trend.index_registered_nurse_hours_per_resident_per_day_on_the_weekend ?? 0).toFixed(2)),
                date: trend.filedate,
            });
            indexPoints[9].push({
                value: Number((trend.index_case_mix_nurse_aide_staffing_hours_per_resident_per_day ?? 0).toFixed(2)),
                date: trend.filedate,
            });
            indexPoints[10].push({
                value: Number((trend.index_case_mix_lpn_staffing_hours_per_resident_per_day ?? 0).toFixed(2)),
                date: trend.filedate,
            });
            indexPoints[11].push({
                value: Number((trend.index_case_mix_rn_staffing_hours_per_resident_per_day ?? 0).toFixed(2)),
                date: trend.filedate,
            });
            indexPoints[12].push({
                value: Number((trend.index_case_mix_total_nurse_staffing_hours_per_resident_per_day ?? 0).toFixed(2)),
                date: trend.filedate,
            });
            indexPoints[13].push({
                value: Number((trend.index_total_nursing_staff_turnover ?? 0).toFixed(2)),
                date: trend.filedate,
            });
            indexPoints[14].push({
                value: Number((trend.index_registered_nurse_turnover ?? 0).toFixed(2)),
                date: trend.filedate,
            });
            indexPoints[15].push({
                value: Number((trend.index_number_of_administrators_who_have_left_the_nursing_home ?? 0).toFixed(2)),
                date: trend.filedate,
            });
        });

        indexPoints[0].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[1].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[2].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[3].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[4].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[5].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[6].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[7].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[8].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[9].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[10].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[11].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[12].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[13].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[14].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[15].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));

        indexes.push(
            {
                label: 'Index Staffing Rating',
                tooltip:
                    'The staffing rating as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[0],
            },
            {
                label: 'Index Reported Nurse Aide Staffing Hours per Resident per Day',
                tooltip:
                    'The reported nurse aide staffing hours per resident per day as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[1],
            },
            {
                label: 'Index Reported Lpn Staffing Hours per Resident per Day',
                tooltip:
                    'The reported LPN staffing hours per resident per day as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[2],
            },
            {
                label: 'Index Reported Rn Staffing Hours per Resident per Day',
                tooltip:
                    'The reported RN staffing hours per resident per day as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[3],
            },
            {
                label: 'Index Reported Licensed Staffing Hours per Resident per Day',
                tooltip:
                    'The reported licensed staffing hours per resident per day as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[4],
            },
            {
                label: 'Index Reported Total Nurse Staffing Hours per Resident per Day',
                tooltip:
                    'The reported total nurse staffing hours per resident per day as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[5],
            },
            {
                label: 'Index Reported Physical Therapist Staffing Hours per Resident per Day',
                tooltip:
                    'The reported physical therapist staffing hours per resident per day as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[6],
            },
            {
                label: 'Index Total Number of Nurse Staff Hours per Resident per Day on the Weekend',
                tooltip:
                    'The total number of nurse staff hours per resident per day on the weekend as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[7],
            },
            {
                label: 'Index Registered Nurse Hours per Resident per Day on the Weekend',
                tooltip:
                    'The registered nurse hours per resident per day on the weekend as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[8],
            },
            {
                label: 'Index Case Mix Nurse Aide Staffing Hours per Resident per Day',
                tooltip:
                    'The case-mix nurse aide staffing hours per resident per day as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[9],
            },
            {
                label: 'Index Case Mix Lpn Staffing Hours per Resident per Day',
                tooltip:
                    'The case-mix LPN staffing hours per resident per day as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[10],
            },
            {
                label: 'Index Case Mix Rn Staffing Hours per Resident per Day',
                tooltip:
                    'The case-mix RN staffing hours per resident per day as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[11],
            },
            {
                label: 'Index Case Mix Total Nurse Staffing Hours per Resident per Day',
                tooltip:
                    'The case-mix total nurse staffing hours per resident per day as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[12],
            },
            {
                label: 'Index Total Nursing Staff Turnover',
                tooltip:
                    'The total nursing staff turnover as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[13],
            },
            {
                label: 'Index Registered Nurse Turnover',
                tooltip:
                    'The registered nurse turnover as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[14],
            },
            {
                label: 'Index Number of Administrators Who Have Left the Nursing Home',
                tooltip:
                    'The number of administrators who have left the nursing home as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[15],
            },
        );

        return indexes;
    }, [trendData]);

    return <IndexTable title="Staffing" indexes={data} />;
};
