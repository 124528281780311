import { Backdrop, Box, Button, MenuItem, Paper, Stack, TextField, Typography } from '@mui/material';
import { PriceMatrixRowComponent, QuotedPrice } from './price-matrix-row.component';
import { orionApiUrl, setHeaders } from 'util/api/api.util';
import { useCallback, useEffect, useState } from 'react';

import { LoadingIndicator } from 'components/general/loading/loading-indicator.component';
import { LossRunsInformation } from './narrative-losses.component';
import { QuoteInformation } from './quote.component';
import { SimpleCheckboxInput } from '../../../add-application/field-components/simple-checkbox-input.component';
import { useParams } from 'react-router-dom';

type Props = {
    providerNumber: string;
    quoteData: QuoteInformation;
    open: boolean;
    claims: LossRunsInformation[];
    onClose: () => void;
    onPriceApplied: (
        price: number,
        lr_included: boolean,
        bedType: string,
        premium: number,
        lossRatio: number,
        manualDebit: number,
    ) => void;
};

export const PriceMatrixComponent = ({ open, quoteData, providerNumber, claims, onClose, onPriceApplied }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [errorLoading, setErrorLoading] = useState<boolean>(true);

    const [deductibleMin, setDeductibleMin] = useState<number>(0);
    const [deductibleMax, setDeductibleMax] = useState<number>(250000);
    const [licensed, setLicensed] = useState<boolean>(false);
    const [occupied, setOccupied] = useState<boolean>(false);
    const [lrIncluded, setLrIncluded] = useState<boolean>(false);
    const [lrExcluded, setLrExcluded] = useState<boolean>(false);

    const [prices, setPrices] = useState<QuotedPrice[]>([]);
    const [filteredPrices, setFilteredPrices] = useState<QuotedPrice[]>([]);

    const { id } = useParams();

    const getData = useCallback(async () => {
        try {
            setErrorLoading(false);
            setLoading(true);

            const { data, status } = await orionApiUrl.get<QuotedPrice[]>(
                `/v1/application/${id}/matrix?provider_number=${providerNumber}`,
                setHeaders(),
            );

            setPrices(data);
            setLoading(false);
        } catch (error) {
            setErrorLoading(true);
            setLoading(false);

            console.log(JSON.stringify(error, null, 2));
        }
    }, [quoteData, providerNumber]);

    const filterData = () => {
        const rows: QuotedPrice[] = [];

        for (const price of prices) {
            if (!lrExcluded && lrIncluded && price.include_loss_runs !== true) continue;
            if (lrExcluded && !lrIncluded && price.include_loss_runs === true) continue;
            if (price.specific_deductible < deductibleMin || price.specific_deductible > deductibleMax) continue;

            rows.push(price);
        }
        setFilteredPrices(rows);
    };

    useEffect(() => {
        if (open === true) getData();
    }, [open]);

    useEffect(() => {
        setLicensed(true);
        setOccupied(false);
        setLrExcluded(false);
        setLrIncluded(true);
        setDeductibleMax(250000);
        setDeductibleMin(0);
        filterData();
    }, [prices]);

    return (
        <Backdrop sx={{ zIndex: theme => theme.zIndex.drawer + 1 }} open={open} onClick={onClose}>
            <Box
                onClick={event => event.stopPropagation()}
                sx={{
                    borderRadius: '5px',
                    padding: '24px',
                    overflowX: 'auto',
                    border: '0px none',
                    borderColor: 'primary.gray',
                    backgroundColor: 'background.paper',
                    width: { xs: '90%', lg: '80%' },
                }}
            >
                <Stack direction="column" spacing={2}>
                    <Stack direction="row" spacing={3}>
                        {/* Deductible Selector */}
                        <TextField
                            select
                            value="placeholder"
                            onChange={() => {}}
                            InputProps={{
                                sx: {
                                    maxWidth: '300px',
                                    height: '30px',
                                    backgroundColor: 'background.deep',
                                },
                            }}
                            sx={{
                                height: '30px',
                                color: '#CC5C5C',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderStyle: 'none',
                                },
                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                    display: 'none',
                                },
                                '& input[type=number]': {
                                    MozAppearance: 'textfield',
                                },
                            }}
                            label=""
                        >
                            <MenuItem value="placeholder" sx={{ display: 'none' }}>
                                {`$${deductibleMin / 1000}k - $${deductibleMax / 1000}k Deductible`}
                            </MenuItem>
                            <MenuItem
                                disableRipple
                                onClick={event => event.stopPropagation()}
                                sx={{ backgroundColor: 'unset', '&:hover': { backgroundColor: 'unset' } }}
                            >
                                <Stack direction="column" spacing={1} onClick={event => event.stopPropagation()}>
                                    <Stack direction="row" justifyContent="center" spacing={1}>
                                        <Typography>Deductible Price ($)</Typography>
                                        <TextField
                                            value={deductibleMin}
                                            onChange={event => {
                                                const { value } = event.target;

                                                if (isNaN(parseInt(value))) return;
                                                setDeductibleMin(parseInt(value));
                                            }}
                                            onClick={event => event.stopPropagation()}
                                            type="number"
                                            InputProps={{
                                                sx: {
                                                    maxWidth: '100px',
                                                    height: '30px',
                                                    backgroundColor: 'background.deep',
                                                },
                                            }}
                                            sx={{
                                                height: '30px',
                                                color: '#CC5C5C',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderStyle: 'none',
                                                },
                                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                                                    {
                                                        display: 'none',
                                                    },
                                                '& input[type=number]': {
                                                    MozAppearance: 'textfield',
                                                },
                                            }}
                                        />
                                        <Typography> - </Typography>
                                        <TextField
                                            value={deductibleMax}
                                            onChange={event => {
                                                const { value } = event.target;

                                                if (isNaN(parseInt(value))) return;
                                                setDeductibleMax(parseInt(value));
                                            }}
                                            onClick={event => event.stopPropagation()}
                                            type="number"
                                            InputProps={{
                                                sx: {
                                                    maxWidth: '100px',
                                                    height: '30px',
                                                    backgroundColor: 'background.deep',
                                                },
                                            }}
                                            sx={{
                                                height: '30px',
                                                color: '#CC5C5C',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderStyle: 'none',
                                                },
                                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                                                    {
                                                        display: 'none',
                                                    },
                                                '& input[type=number]': {
                                                    MozAppearance: 'textfield',
                                                },
                                            }}
                                        />
                                    </Stack>
                                    <Button sx={{ margingY: '24px' }}>
                                        <Typography
                                            fontWeight={500}
                                            sx={{ color: 'text.primary' }}
                                            onClick={filterData}
                                        >
                                            APPLY
                                        </Typography>
                                    </Button>
                                </Stack>
                            </MenuItem>
                        </TextField>
                        {/* Beds Selector */}
                        <TextField
                            select
                            value="placeholder"
                            onChange={() => {}}
                            InputProps={{
                                sx: {
                                    maxWidth: '300px',
                                    height: '30px',
                                    backgroundColor: 'background.deep',
                                },
                            }}
                            sx={{
                                height: '30px',
                                color: '#CC5C5C',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderStyle: 'none',
                                },
                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                    display: 'none',
                                },
                                '& input[type=number]': {
                                    MozAppearance: 'textfield',
                                },
                            }}
                            label=""
                        >
                            <MenuItem value="placeholder" sx={{ display: 'none' }}>
                                {!licensed && !occupied ? (
                                    <Typography sx={{ fontStyle: 'oblique', color: 'text.discrete' }}>
                                        Bed Type
                                    </Typography>
                                ) : (
                                    ''
                                )}
                                {licensed && !occupied ? 'Licensed Beds' : ''}
                                {occupied && !licensed ? 'Occupied Beds' : ''}
                                {licensed && occupied ? 'Licensed & Occupied Beds' : ''}
                            </MenuItem>
                            <MenuItem
                                disableRipple
                                onClick={event => event.stopPropagation()}
                                sx={{ backgroundColor: 'unset', '&:hover': { backgroundColor: 'unset' } }}
                            >
                                <Stack
                                    direction="column"
                                    sx={{ width: '100%' }}
                                    spacing={1}
                                    onClick={event => event.stopPropagation()}
                                >
                                    <SimpleCheckboxInput
                                        value={licensed}
                                        id="licensed"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            const { checked } = event.target;

                                            setLicensed(checked);
                                        }}
                                        label="Licensed Beds"
                                    />
                                    <SimpleCheckboxInput
                                        value={occupied}
                                        id="certified"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            const { checked } = event.target;

                                            setOccupied(checked);
                                        }}
                                        label="Occupied Beds"
                                    />
                                    <Button fullWidth sx={{ margingY: '24px' }}>
                                        <Typography
                                            fontWeight={500}
                                            sx={{ color: 'text.primary' }}
                                            onClick={filterData}
                                        >
                                            APPLY
                                        </Typography>
                                    </Button>
                                </Stack>
                            </MenuItem>
                        </TextField>
                        {/* LR Selector */}
                        <TextField
                            select
                            value="placeholder"
                            onChange={() => {}}
                            InputProps={{
                                sx: {
                                    maxWidth: '300px',
                                    height: '30px',
                                    backgroundColor: 'background.deep',
                                },
                            }}
                            sx={{
                                height: '30px',
                                color: '#CC5C5C',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderStyle: 'none',
                                },
                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                    display: 'none',
                                },
                                '& input[type=number]': {
                                    MozAppearance: 'textfield',
                                },
                            }}
                            label=""
                        >
                            <MenuItem value="placeholder" sx={{ display: 'none' }}>
                                {!lrExcluded && !lrIncluded ? (
                                    <Typography sx={{ fontStyle: 'oblique', color: 'text.discrete' }}>
                                        LR Type
                                    </Typography>
                                ) : (
                                    ''
                                )}
                                {lrIncluded && !lrExcluded ? 'LR Included' : ''}
                                {lrExcluded && !lrIncluded ? 'LR Excluded' : ''}
                                {lrExcluded && lrIncluded ? 'LR Included & LR Excluded' : ''}
                            </MenuItem>
                            <MenuItem
                                disableRipple
                                onClick={event => event.stopPropagation()}
                                sx={{ backgroundColor: 'unset', '&:hover': { backgroundColor: 'unset' } }}
                            >
                                <Stack
                                    direction="column"
                                    sx={{ width: '100%' }}
                                    spacing={1}
                                    onClick={event => event.stopPropagation()}
                                >
                                    <SimpleCheckboxInput
                                        value={lrIncluded}
                                        id="included"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            const { checked } = event.target;

                                            setLrIncluded(checked);
                                        }}
                                        label="LR Included"
                                    />
                                    <SimpleCheckboxInput
                                        value={lrExcluded}
                                        id="excluded"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            const { checked } = event.target;

                                            setLrExcluded(checked);
                                        }}
                                        label="LR Excluded"
                                    />
                                    <Button fullWidth sx={{ margingY: '24px' }}>
                                        <Typography
                                            fontWeight={500}
                                            sx={{ color: 'text.primary' }}
                                            onClick={filterData}
                                        >
                                            APPLY
                                        </Typography>
                                    </Button>
                                </Stack>
                            </MenuItem>
                        </TextField>
                    </Stack>
                    {loading && <LoadingIndicator />}
                    {!loading && errorLoading && (
                        <Stack>
                            <Typography>Unable to generate price matrix for this facility.</Typography>
                        </Stack>
                    )}
                    {!loading && !errorLoading && (
                        <Stack direction="column">
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                                sx={{ width: '100%', height: '40px', backgroundColor: '#1E242D' }}
                            >
                                <Typography
                                    sx={{ fontWeight: 700, textAlign: 'right', width: '15%', paddingX: '12px' }}
                                >
                                    Specific Deductible
                                </Typography>
                                <Typography sx={{ fontWeight: 700, textAlign: 'left', width: '10%', paddingX: '12px' }}>
                                    Beds
                                </Typography>
                                <Typography sx={{ fontWeight: 700, textAlign: 'left', width: '10%', paddingX: '12px' }}>
                                    Loss Runs
                                </Typography>
                                <Typography sx={{ fontWeight: 700, textAlign: 'left', width: '15%', paddingX: '12px' }}>
                                    Imputed Loss Ratio %
                                </Typography>
                                <Typography
                                    sx={{ fontWeight: 700, textAlign: 'center', width: '10%', paddingX: '12px' }}
                                >
                                    Algorithm Rate
                                </Typography>
                                <Typography
                                    sx={{ fontWeight: 700, textAlign: 'center', width: '10%', paddingX: '12px' }}
                                >
                                    Manual Debit
                                </Typography>
                                <Typography
                                    sx={{ fontWeight: 700, textAlign: 'center', width: '10%', paddingX: '12px' }}
                                >
                                    Quoted Rate
                                </Typography>
                            </Stack>
                            {/* Row */}
                            <Paper
                                elevation={0}
                                sx={{
                                    maxHeight: '400px',
                                    overflowY: 'auto',
                                    backgroundColor: 'transparent',
                                    backgroundImage: 'none',
                                }}
                            >
                                {filteredPrices.map((price, index) => (
                                    <Stack direction="column" key={index}>
                                        <PriceMatrixRowComponent
                                            index={index}
                                            price={price}
                                            claims={claims}
                                            licensed={licensed}
                                            occupied={occupied}
                                            providerNumber={providerNumber}
                                            onPriceApplied={onPriceApplied}
                                        />
                                    </Stack>
                                ))}
                            </Paper>
                        </Stack>
                    )}
                </Stack>
            </Box>
        </Backdrop>
    );
};
