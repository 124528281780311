import { ApplicationSummary, FacilitySummary } from '../application-page.component';
/* eslint-disable indent */
import { Box, Collapse, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { orionApiUrl, setHeaders } from 'util/api/api.util';
import { useCallback, useEffect, useState } from 'react';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { FacilityInsuranceData } from '../application-details-page/application-details-page.component';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { SubmissionStatusSelector } from '../specific-components/submission-status.component';
import { handleError } from 'util/error/handleError';
import { useNavigate } from 'react-router-dom';

interface Props {
    data: ApplicationSummary;
    alt?: boolean;
}

export const ApplicationRow = ({ data, alt = false }: Props) => {
    const [open, setOpen] = useState<boolean>(false);
    const [status, setStatus] = useState<string>('placeholder');
    const navigate = useNavigate();

    const setSubmissionStatus = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            try {
                const { name, value } = event.target;
                const patchedFacilities = data.facilities.map(facility => {
                    return {
                        id: facility.id,
                        submission_status: value,
                    };
                });
                const { data: result } = await orionApiUrl.patch<FacilityInsuranceData>(
                    `/v1/application/${data.id}/facilities`,
                    patchedFacilities,
                    setHeaders(),
                );

                setStatus(value);
            } catch (error) {
                handleError(error, () => {
                    console.log(JSON.stringify(error, null, 2));
                });
            }
        },
        [data],
    );

    useEffect(() => {
        if (data.facilities != null && data.facilities.length > 0 && data.facilities[0].submission_status != null) {
            setStatus(data.facilities[0].submission_status);
        }
    }, [data]);

    const formatDate = useCallback((date: string | null) => {
        if (date == null) return 'N/A';

        const rawDate = parseISO(date);

        return format(rawDate, 'MM/dd/yyyy');
    }, []);

    const formatString = useCallback((value: string | null) => (value == null ? 'N/A' : value), []);

    const formatCurrency = useCallback((value: number | null) => {
        if (value == null) return '--';

        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }, []);

    const formatPercentage = useCallback((value: number | null) => {
        if (value == null) return '--';

        return value.toLocaleString('en-US', { style: 'percent', maximumFractionDigits: 2 });
    }, []);

    const getTrend = useCallback((expiringQuote: number | null, quotedPremium: number | null): string => {
        if (expiringQuote == null || quotedPremium == null) {
            return '';
        }
        const percentage = (expiringQuote - quotedPremium) / expiringQuote;
        const trend = percentage < 0 ? 'up' : percentage > 0 ? 'down' : 'none';

        return trend;
    }, []);

    return (
        <>
            <TableRow
                sx={{
                    '&:hover': {
                        cursor: 'pointer',
                    },
                    backgroundColor: alt ? 'background.deep' : undefined,
                }}
                onClick={() => setOpen(!open)}
            >
                <TableCell>{formatString(data.facilities[0].brokerage_name)}</TableCell>
                <TableCell>{formatString(data.facilities[0].agent_name)}</TableCell>
                <TableCell>
                    {data.facilities[0].renewal_date != null ? formatDate(data.facilities[0].renewal_date) : 'N/A'}
                </TableCell>
                <TableCell>{data.facilities[0].insured_name}</TableCell>
                <TableCell>
                    {data.quote_summary.is_needs_quote ? (
                        <Box
                            sx={{
                                backgroundColor: 'background.danger',
                                borderRadius: '10px',
                                width: '120px',
                                padding: '2px',
                            }}
                        >
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                                <Typography sx={{ fontSize: '11pt' }}>Needs Quote</Typography>
                                <ReportProblemIcon sx={{ fontSize: '11pt', color: 'primary.yellow' }} />
                            </Stack>
                        </Box>
                    ) : (
                        formatCurrency(
                            (data.quote_summary.total_quoted_rate || 0) + +(data.quote_summary.total_manual_debit || 0),
                        )
                    )}
                </TableCell>
                <TableCell>
                    {formatCurrency(
                        +(data.quote_summary.total_quoted_rate || 0) +
                            +(data.quote_summary.total_manual_debit || 0) +
                            +(data.quote_summary.total_technology_fee || 0) +
                            +(data.quote_summary.total_state_tax || 0) +
                            +(data.quote_summary.total_stamp_fee || 0),
                    )}
                </TableCell>
                <TableCell>{formatDate(data.facilities[0].submission_date)}</TableCell>
                <TableCell>{formatPercentage(data.facilities[0].imputed_loss_ratio)}</TableCell>
                <TableCell>
                    <SubmissionStatusSelector
                        discrete
                        maxWidth="120px"
                        status={status}
                        onChange={setSubmissionStatus}
                    />
                </TableCell>
                <TableCell>{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</TableCell>
            </TableRow>
            <TableRow
                sx={{
                    backgroundColor: alt ? 'background.deep' : undefined,
                }}
            >
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Facility</TableCell>
                                    <TableCell>Exp. Quote</TableCell>
                                    <TableCell>Quoted Premium</TableCell>
                                    <TableCell>Exp. Deductible</TableCell>
                                    <TableCell>Quoted Deductible</TableCell>
                                    <TableCell>State Tax</TableCell>
                                    <TableCell>TRIA</TableCell>
                                    <TableCell>Tech Fee</TableCell>
                                    <TableCell>Retro Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.facilities &&
                                    data.facilities.map((facility: FacilitySummary, index: number) => (
                                        <TableRow
                                            key={index}
                                            onClick={() => {
                                                navigate(`./${data.id}/${facility.id}`);
                                            }}
                                            sx={{
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                    backgroundColor: 'primary.main',
                                                    color: alt ? 'background.deep' : 'background.default',
                                                    '.trend': {
                                                        color: alt ? 'background.deep' : 'background.default',
                                                    },
                                                },
                                            }}
                                        >
                                            <TableCell sx={{ color: 'inherit' }}>
                                                {formatString(facility.facility_name)}
                                            </TableCell>
                                            <TableCell sx={{ color: 'inherit' }}>
                                                {formatCurrency(facility.expiring_quote)}
                                            </TableCell>
                                            <TableCell sx={{ color: 'inherit' }}>
                                                <Typography noWrap sx={{ fontSize: '11pt' }}>
                                                    {formatCurrency(
                                                        +(facility.quoted_rate || 0) + +(facility.manual_debit || 0),
                                                    )}{' '}
                                                    {getTrend(facility.expiring_quote, facility.premium) != 'none' &&
                                                        facility.expiring_quote != null &&
                                                        facility.premium != null && (
                                                            <Typography
                                                                display="inline"
                                                                noWrap
                                                                className="trend"
                                                                sx={{
                                                                    fontSize: '11pt',
                                                                    color:
                                                                        getTrend(
                                                                            facility.expiring_quote,
                                                                            facility.premium,
                                                                        ) === 'up'
                                                                            ? 'text.green'
                                                                            : 'text.danger',
                                                                }}
                                                            >
                                                                (
                                                                {getTrend(facility.expiring_quote, facility.premium) ===
                                                                'up' ? (
                                                                    <ArrowUpwardIcon sx={{ fontSize: '11pt' }} />
                                                                ) : (
                                                                    <ArrowDownwardIcon sx={{ fontSize: '11pt' }} />
                                                                )}
                                                                {Math.abs(
                                                                    (facility.expiring_quote - facility.premium) /
                                                                        facility.expiring_quote,
                                                                ).toLocaleString('en-US', {
                                                                    maximumFractionDigits: 0,
                                                                }) + '%'}
                                                                )
                                                            </Typography>
                                                        )}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ color: 'inherit' }}>
                                                {formatCurrency(facility.expiring_deductible)}
                                            </TableCell>
                                            <TableCell sx={{ color: 'inherit' }}>
                                                {formatCurrency(facility.specific_deductible)}
                                            </TableCell>
                                            <TableCell sx={{ color: 'inherit' }}>
                                                {formatPercentage(facility.state_tax_rate)}
                                            </TableCell>
                                            <TableCell sx={{ color: 'inherit' }}>
                                                {formatCurrency(facility.tria_fee)}
                                            </TableCell>
                                            <TableCell sx={{ color: 'inherit' }}>
                                                {formatCurrency(facility.technology_fee)}
                                            </TableCell>
                                            <TableCell sx={{ color: 'inherit' }}>
                                                {formatDate(facility.retroactive_date)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};
