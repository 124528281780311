import { SxProps, TextField, Theme } from '@mui/material';

import { ReactNode } from 'react';
import { border } from '@mui/system';

interface Props {
    value: string | string[] | undefined;
    id: string;
    label: string;
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    children: ReactNode;
    multiple?: boolean;
    fullWidth?: boolean;
    sx?: SxProps<Theme>;
    altBorder?: boolean;
    height?: string;
    color?: string;
    borderColor?: string;
    borderRadius?: string;
    disabled?: boolean;
}

export const InputFieldSelect = ({
    value,
    id,
    label,
    onChange,
    children,
    sx,
    multiple = false,
    fullWidth = false,
    altBorder = false,
    disabled = false,
    color,
    borderColor,
    borderRadius,
    height,
}: Props) => {
    return (
        <TextField
            disabled={disabled}
            select
            fullWidth={fullWidth}
            variant="outlined"
            value={value}
            name={id}
            label={label}
            onChange={onChange}
            SelectProps={{
                multiple: multiple,
            }}
            InputProps={{
                sx: {
                    height: height,
                },
            }}
            sx={{
                color: '#CC5C5C',
                height: height,
                backgroundColor: color !== undefined ? color : undefined,
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: borderColor !== undefined ? borderColor : altBorder ? '#393E46' : 'primary.main',
                    borderRadius: borderRadius !== undefined ? borderRadius : '4px',
                },
                label: {
                    color: 'text.primary',
                },
                borderRadius: borderRadius !== undefined ? borderRadius : '4px',
                ...sx,
            }}
        >
            {children}
        </TextField>
    );
};
