import { Backdrop, Button, Step, StepButton, Stepper, Typography } from '@mui/material';
import { Box, Stack, width } from '@mui/system';
import { FacilityData, FacilityDataPoint, FacilityIndex } from 'types/underwriter/underwriter.type';
import { FacilityDetails, FacilityLocationDetails, FacilityTrendResponse } from '../types/underwriter.types';
import { compareDesc, parse, parseISO } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

import { CompactLineChart } from './compact-line-chart.component';
import { Datum } from '@nivo/line';
import { QuoteStepper } from './quote-dialogue/quote-stepper.component';
import { SubContainer } from 'components/general/layout/sub-container.component';

type Props = {
    metadata: FacilityLocationDetails;
    details: FacilityDetails;
    trendData: FacilityTrendResponse[];
};

export const FacilityDetailsComponent = ({ metadata, details, trendData }: Props) => {
    const [occupancyData, setOccupancyData] = useState<Datum[]>([]);
    const [qmRatingData, setQmRatingData] = useState<Datum[]>([]);
    const [open, setOpen] = useState(false);

    const data: FacilityDataPoint[][] = useMemo(() => {
        if (trendData.length <= 0) return [[], []];
        const points: FacilityDataPoint[][] = [[], []];

        trendData.forEach(trend => {
            points[0].push({ value: Number(trend.occupancy.toFixed(4)), date: trend.filedate });
            points[1].push({
                value: Number(trend['long-stay_qm_rating'].toFixed(4)),
                date: trend.filedate,
            });

            points[0].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
            points[1].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        });

        return points;
    }, [trendData]);

    useEffect(() => {
        const mapData = (rawData: FacilityDataPoint[]) => {
            const data: Datum[] = rawData.map((dataPoint: FacilityDataPoint) => ({
                x: parse(dataPoint.date, 'yyyy-MM-dd', new Date()),
                y: dataPoint.value,
            }));

            return data;
        };
        const occupancy = mapData(data[0]);
        const qmRating = mapData(data[1]);

        setOccupancyData(occupancy);
        setQmRatingData(qmRating);
    }, [data]);

    const handleOpen = () => {
        document.body.style.overflow = 'hidden';
        setOpen(true);
    };

    const handleClose = () => {
        document.body.style.overflow = 'unset';
        setOpen(false);
    };

    return (
        <>
            <Backdrop
                mountOnEnter={true}
                unmountOnExit={true}
                open={open}
                sx={{ backgroundColor: 'background.backdrop', zIndex: theme => theme.zIndex.drawer + 1 }}
            >
                <QuoteStepper facilityId={metadata.id} onCancel={handleClose} />
            </Backdrop>
            <Stack spacing={2}>
                <Stack direction="row" alignItems="center">
                    <Typography variant="h6" sx={{ flexGrow: '1' }}>
                        {details.provider_name}
                    </Typography>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={handleOpen}
                        sx={{ backgroundColor: 'primary.main' }}
                    >
                        QUOTE
                    </Button>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography textAlign="left" sx={{ width: '33%' }}>
                        Address
                    </Typography>
                    <Typography textAlign="left" sx={{ width: '67%' }}>
                        {metadata.address}
                    </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography textAlign="left" sx={{ width: '33%' }}>
                        Status
                    </Typography>
                    <Typography
                        textAlign="left"
                        sx={{ width: '67%', color: details.geo_class === 'Urban' ? 'primary.main' : 'primary.yellow' }}
                    >
                        {details.geo_class}
                    </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography textAlign="left" sx={{ width: '33%' }}>
                        # Certified Beds
                    </Typography>
                    <Typography textAlign="left" sx={{ width: '67%' }}>
                        {details.number_of_certified_beds.toFixed(0)}
                    </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography textAlign="left" sx={{ width: '33%' }}>
                        Months Since Last Survey
                    </Typography>
                    <Typography textAlign="left" sx={{ width: '67%' }}>
                        {details.months_since_last_survey.toFixed(0)}
                    </Typography>
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row', lg: 'column', xl: 'row' }} spacing={2}>
                    <SubContainer minimal>
                        <Stack direction="column" spacing={2}>
                            <Typography textAlign="left">Occupancy</Typography>
                            <CompactLineChart id="occupancy" leftAxisSteps={5} data={occupancyData} height={250} />
                        </Stack>
                    </SubContainer>
                    <SubContainer minimal>
                        <Stack direction="column" spacing={2}>
                            <Typography textAlign="left">Qm Rating</Typography>
                            <CompactLineChart id="qm-rating" leftAxisSteps={3} data={qmRatingData} height={250} />
                        </Stack>
                    </SubContainer>
                </Stack>
            </Stack>
        </>
    );
};
