import { Box, Button, Grid, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { orionApiUrl, setHeaders } from 'util/api/api.util';
import { useCallback, useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { SimpleTextBoxInput } from '../../../add-application/field-components/simple-text-box-input.component';
import { SubContainer } from 'components/general/layout/sub-container.component';

export type ScheduleInformation = {
    id?: number;
    application_id?: number;
    name?: string;
    address?: string;
};

type Props = {
    data: ScheduleInformation[];
    onChange: (changes: ScheduleInformation[]) => void;
};

export const ApplicationScheduleComponent = ({ data, onChange }: Props) => {
    const handlScheduleFieldChange = (value: string | number | null, index: number, key: string) => {
        const newEntries = data.map((entry, i) => {
            if (i !== index) return entry;

            return {
                ...entry,
                [key]: value,
            };
        });

        onChange(newEntries);
    };

    const addSchedule = () => {
        onChange([...data, {}]);
    };

    const removeSchedule = async (index: number) => {
        try {
            if (data[index] && data[index].id) {
                const updateRequest = await orionApiUrl.delete(`/v1/schedule/${data[index].id}`, setHeaders());

                if (updateRequest.status !== 200) {
                    throw new Error('Unable to update');
                }
            }

            const newSchedules = data.filter((schedule: ScheduleInformation, i: number) => i !== index);

            onChange([...newSchedules]);
        } catch (error) {
            console.log(`unable to update schedule with index ${index}`);
        }
    };

    return (
        <Grid
            item
            xs={12}
            lg={6}
            sx={{
                '& .MuiBox-root': {
                    height: '100%',
                },
            }}
        >
            <SubContainer sx={{ padding: '20px 30px' }}>
                <Stack direction="column" sx={{ width: '100%' }} spacing={2}>
                    <Stack direction="row" alignItems="center">
                        <Typography variant="h6" sx={{ flexGrow: '1' }}>
                            Schedule of Additional Insureds
                        </Typography>
                        <Button>
                            <Typography fontWeight={500} onClick={addSchedule}>
                                + ADD SCHEDULE
                            </Typography>
                        </Button>
                    </Stack>
                    <Stack direction="row" sx={{ paddingTop: '12px', width: '100%' }} spacing={2}>
                        <Typography fontWeight={500} sx={{ width: '50%' }}>
                            Name of Person or Organization
                        </Typography>
                        <Typography fontWeight={500} sx={{ width: '50%' }}>
                            Location
                        </Typography>
                        <Box sx={{ width: '24px' }} />
                    </Stack>
                    {data.map((entry: ScheduleInformation, index: number) => (
                        <Stack key={index} direction="row" sx={{ width: '100%' }} spacing={2}>
                            <SimpleTextBoxInput
                                singleLine
                                id="name"
                                value={entry.name}
                                rows={1}
                                placeholder="Name"
                                onChange={newValue => handlScheduleFieldChange(newValue.target.value, index, 'name')}
                            />
                            <SimpleTextBoxInput
                                singleLine
                                id="address"
                                value={entry.address}
                                rows={1}
                                placeholder="Location"
                                onChange={newValue => handlScheduleFieldChange(newValue.target.value, index, 'address')}
                            />
                            <IconButton onClick={() => removeSchedule(index)}>
                                <DeleteIcon sx={{ color: 'text.danger' }} />
                            </IconButton>
                        </Stack>
                    ))}
                </Stack>
            </SubContainer>
        </Grid>
    );
};
