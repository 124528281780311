import { IconButton, Stack } from '@mui/material';

import { Box } from '@mui/system';
import { Close } from '@mui/icons-material';

type Props = {
    show: boolean;
    handleChange: () => void;
};

export const MobileBar = ({ show, handleChange }: Props) => {
    return (
        <Box
            position="fixed"
            display={{ xs: show ? 'block' : 'none', md: 'none' }}
            left="0"
            top="64px"
            width={{ xs: '100%', sm: '256px' }}
            height="calc(100vh - 64px)"
            zIndex={40}
            className="animate__animated animate__fadeInLeft"
            sx={{
                backgroundColor: 'background.paper',
                overflowY: 'scroll',
            }}
        >
            <Stack direction="row" justifyContent="end">
                <IconButton onClick={handleChange}>
                    <Close />
                </IconButton>
            </Stack>
            <Stack direction="column" width="95%" mx="auto" spacing={1}>
                {/* <Link
                    onClick={handleChange}
                    to="/clinical-audits"
                    style={{
                        borderRadius: '4px',
                        padding: '10px',
                        backgroundColor: '#FFD369',
                        display: 'flex',
                        alignItems: 'center',
                        color: '#212121',
                        textDecoration: 'none',
                        fontFamily: 'Roboto',
                    }}
                >
                    <ContentPaste sx={{ marginRight: '16px' }} /> Clinical Audits
                </Link> */}
            </Stack>
        </Box>
    );
};
