import { Button, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { format, parse, parseISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

import { DateRange } from '@mui/x-date-pickers-pro/internals/models';
import { InputFieldSelect } from 'components/pages/underwriter/facility-module/quote-dialogue/inputs/field-select.component';
import { SimpleCheckboxInput } from '../../../add-application/field-components/simple-checkbox-input.component';
import { SimpleDateInput } from '../../../add-application/field-components/simple-date-input.component';
import { SimpleDateRangeInput } from '../../../add-application/field-components/simple-date-range-input.component';
import { SimpleInput } from '../../../add-application/field-components/simple-input.component';
import { SimpleTextBoxInput } from '../../../add-application/field-components/simple-text-box-input.component';
import { SubContainer } from 'components/general/layout/sub-container.component';

export type PolicyInformation = {
    policy_form_name?: string;
    policy_start_date?: string | null;
    policy_end_date?: string | null;
    insurance_carrier?: string;
    declined_reason?: string;
    policy_rating?: string;
    credit_rating_agency?: string;
    policy_limit?: string;
    notes?: string;
    is_accelerant_referral?: boolean;
    is_declined?: boolean;
    policy_endorsements?: string[];
    policy_sublimits?: string[];
};

export function extractPolicyInformation(data: unknown): PolicyInformation {
    const applicantInformation: Partial<PolicyInformation> = {};
    const validKeys: Array<keyof PolicyInformation> = [
        'policy_form_name',
        'policy_start_date',
        'policy_end_date',
        'insurance_carrier',
        'declined_reason',
        'policy_rating',
        'credit_rating_agency',
        'notes',
        'is_accelerant_referral',
        'is_declined',
        'policy_endorsements',
        'policy_sublimits',
    ];

    if (typeof data === 'object' && data !== null) {
        for (const key of validKeys) {
            if (key in data) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'unknown'.
                applicantInformation[key] = data[key];
            }
        }
    }

    return applicantInformation as PolicyInformation;
}

export type DateRangeEntry = string | null | undefined;

type Props = {
    data: PolicyInformation;
    onChange: (changes: PolicyInformation) => void;
};

export const ApplicationPolicyComponent = ({ data, onChange }: Props) => {
    const handleFieldChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
        const { name, value } = event.target;
        const parsedValue = value.trim().length <= 0 ? null : value;

        onChange({
            ...data,
            [name]: parsedValue,
        });
    };

    const handleCheckBoxChange: React.ChangeEventHandler<HTMLInputElement> = event => {
        const { name, checked } = event.target;

        onChange({
            ...data,
            [name]: checked,
        });
    };

    const handleDateFieldChange = (value: Date | null, key: string) => {
        onChange({
            ...data,
            [key]: value === null ? null : `Claims-Made: (Retro Date: ${format(value, 'MM/dd/yyyy')})`,
        });
    };

    const extractAndParseDate = (inputString: string | undefined) => {
        if (inputString == null) return undefined;

        const regex = /Claims-Made: \(Retro Date: (\d{2}\/\d{2}\/\d{4})\)/;
        const match = inputString.match(regex);

        if (match && match[1]) {
            const date = parse(match[1], 'MM/dd/yyyy', new Date());

            return format(date, 'yyyy-MM-dd');
        }

        return undefined; // Return null if the date format is not found
    };

    const handleDateRangeFieldChange = (value: DateRange<Date>, startKey: string, endKey: string) => {
        const newValue: DateRangeEntry[] = value.map(date => (date == null ? null : format(date, 'yyyy-MM-dd')));

        onChange({
            ...data,
            [startKey]: newValue[0],
            [endKey]: newValue[1],
        });
    };

    const handleEndorsementChange: React.ChangeEventHandler<HTMLInputElement> = event => {
        const { name, checked } = event.target;

        if (data.policy_endorsements) {
            if (data.policy_endorsements.includes(name)) {
                if (checked) return;
                else {
                    onChange({
                        ...data,
                        policy_endorsements: data.policy_endorsements.filter(endorsement => endorsement !== name),
                    });
                }
            } else {
                if (checked) {
                    onChange({
                        ...data,
                        policy_endorsements: [...data.policy_endorsements, name],
                    });
                } else return;
            }
        } else {
            if (checked) {
                onChange({
                    ...data,
                    policy_endorsements: [name],
                });
            } else {
                return;
            }
        }
    };

    useEffect(() => {
        onChange(data);
    }, [data]);

    return (
        <Grid
            item
            lg={6}
            xs={12}
            sx={{
                '& .MuiBox-root': {
                    height: '100%',
                },
            }}
        >
            <SubContainer sx={{ padding: '20px 30px' }}>
                <Stack direction="column">
                    <Stack direction="row" alignItems="center">
                        <Typography variant="h6" sx={{ flexGrow: '1' }}>
                            Policy
                        </Typography>
                    </Stack>
                    <Grid container rowGap={4} sx={{ paddingTop: '24px' }}>
                        <Grid item xs={12}>
                            <Stack direction="column" spacing={1}>
                                {/* <SimpleDateInput
                                    value={extractAndParseDate(data.policy_form_name)}
                                    format="'Claims-Made: (Retro-Date': MM/dd/yyyy)"
                                    bold={false}
                                    smallField
                                    label="Policy Form"
                                    width="100%"
                                    onChange={newValue => handleDateFieldChange(newValue, 'policy_form_name')}
                                /> */}
                                {/* <SimpleInput
                                    bold={false}
                                    smallField
                                    label="Policy Form"
                                    value={data.policy_form_name}
                                    id="policy_form_name"
                                    width="100%"
                                    onChange={handleFieldChange}
                                /> */}
                                <SimpleDateRangeInput
                                    bold={false}
                                    smallField
                                    value={[data.policy_start_date, data.policy_end_date]}
                                    label="Policy Period"
                                    width="100%"
                                    onChange={newValue => {
                                        handleDateRangeFieldChange(newValue, 'policy_start_date', 'policy_end_date');
                                    }}
                                />
                                <SimpleInput
                                    bold={false}
                                    smallField
                                    label="Reason for Acceptance/Denial"
                                    value={data.declined_reason}
                                    id="declined_reason"
                                    width="100%"
                                    onChange={handleFieldChange}
                                />
                                <SimpleInput
                                    bold={false}
                                    smallField
                                    label="Carrier"
                                    id="insurance_carrier"
                                    value={data.insurance_carrier}
                                    width="100%"
                                    onChange={handleFieldChange}
                                />
                                <SimpleInput
                                    bold={false}
                                    smallField
                                    label="Rating Agency"
                                    id="credit_rating_agency"
                                    value={data.credit_rating_agency}
                                    width="100%"
                                    onChange={handleFieldChange}
                                />
                                <SimpleInput
                                    bold={false}
                                    smallField
                                    label="Rating"
                                    id="policy_rating"
                                    value={data.policy_rating}
                                    width="100%"
                                    onChange={handleFieldChange}
                                />
                                <SimpleTextBoxInput
                                    id="notes"
                                    value={data.notes}
                                    rows={2}
                                    placeholder="Notes"
                                    onChange={handleFieldChange}
                                />
                                <SimpleCheckboxInput
                                    bold={false}
                                    label="Accelerant Referal"
                                    id="is_accelerant_referral"
                                    value={data.is_accelerant_referral}
                                    width="100%"
                                    onChange={handleCheckBoxChange}
                                />
                                <SimpleCheckboxInput
                                    bold={false}
                                    label="Declination"
                                    id="is_declined"
                                    value={data.is_declined}
                                    width="100%"
                                    onChange={handleCheckBoxChange}
                                />
                                <Typography sx={{ fontWeight: 500, paddingTop: '12px' }}>Endorsements</Typography>
                                <SimpleCheckboxInput
                                    crossable
                                    bold={false}
                                    label="ADDITIONAL INSURED - MORTGAGEE"
                                    id="ADDITIONAL INSURED - MORTGAGE"
                                    value={
                                        data.policy_endorsements &&
                                        data.policy_endorsements.includes('ADDITIONAL INSURED - MORTGAGE')
                                    }
                                    width="100%"
                                    onChange={handleEndorsementChange}
                                />
                                <SimpleCheckboxInput
                                    crossable
                                    bold={false}
                                    label="DEMAND TRIGGER"
                                    id="DEMAND TRIGGER"
                                    value={
                                        data.policy_endorsements && data.policy_endorsements.includes('DEMAND TRIGGER')
                                    }
                                    width="100%"
                                    onChange={handleEndorsementChange}
                                />
                                <SimpleCheckboxInput
                                    crossable
                                    bold={false}
                                    label="EXCLUSION - ASBESTOS"
                                    id="EXCLUSION - ASBESTOS"
                                    value={
                                        data.policy_endorsements &&
                                        data.policy_endorsements.includes('EXCLUSION - ASBESTOS')
                                    }
                                    width="100%"
                                    onChange={handleEndorsementChange}
                                />
                                <SimpleCheckboxInput
                                    crossable
                                    bold={false}
                                    label="EXCLUSION - COMMUNICABLE DISEASE"
                                    id="EXCLUSION - COMMUNICABLE DISEASE"
                                    value={
                                        data.policy_endorsements &&
                                        data.policy_endorsements.includes('EXCLUSION - COMMUNICABLE DISEASE')
                                    }
                                    width="100%"
                                    onChange={handleEndorsementChange}
                                />
                                <SimpleCheckboxInput
                                    crossable
                                    bold={false}
                                    label="EXCLUSION - CROSS SUITS"
                                    id="EXCLUSION - CROSS SUITS"
                                    value={
                                        data.policy_endorsements &&
                                        data.policy_endorsements.includes('EXCLUSION - CROSS SUITS')
                                    }
                                    width="100%"
                                    onChange={handleEndorsementChange}
                                />
                                <SimpleCheckboxInput
                                    crossable
                                    bold={false}
                                    label="EXCLUSION - CYBER ACT, CYBER INCIDENT OR DATA BREACH"
                                    id="EXCLUSION - CYBER ACT, CYBER INCIDENT OR DATA BREACH"
                                    value={
                                        data.policy_endorsements &&
                                        data.policy_endorsements.includes(
                                            'EXCLUSION - CYBER ACT, CYBER INCIDENT OR DATA BREACH',
                                        )
                                    }
                                    width="100%"
                                    onChange={handleEndorsementChange}
                                />
                                <SimpleCheckboxInput
                                    crossable
                                    bold={false}
                                    label="EXCLUSION - FIREARMS AND WEAPONS"
                                    id="EXCLUSION - FIREARMS AND WEAPONS"
                                    value={
                                        data.policy_endorsements &&
                                        data.policy_endorsements.includes('EXCLUSION - FIREARMS AND WEAPONS')
                                    }
                                    width="100%"
                                    onChange={handleEndorsementChange}
                                />
                                <SimpleCheckboxInput
                                    crossable
                                    bold={false}
                                    label="EXCLUSION - LEAD"
                                    id="EXCLUSION - LEAD"
                                    value={
                                        data.policy_endorsements &&
                                        data.policy_endorsements.includes('EXCLUSION - LEAD')
                                    }
                                    width="100%"
                                    onChange={handleEndorsementChange}
                                />
                                <SimpleCheckboxInput
                                    crossable
                                    bold={false}
                                    label="EXCLUSION - PFAS"
                                    id="EXCLUSION - PFAS"
                                    value={
                                        data.policy_endorsements &&
                                        data.policy_endorsements.includes('EXCLUSION - PFAS')
                                    }
                                    width="100%"
                                    onChange={handleEndorsementChange}
                                />
                                <SimpleCheckboxInput
                                    crossable
                                    bold={false}
                                    label="EXCLUSION - POLLUTION"
                                    id="EXCLUSION - POLLUTION"
                                    value={
                                        data.policy_endorsements &&
                                        data.policy_endorsements.includes('EXCLUSION - POLLUTION')
                                    }
                                    width="100%"
                                    onChange={handleEndorsementChange}
                                />
                                <SimpleCheckboxInput
                                    crossable
                                    bold={false}
                                    label="EXCLUSION - PUNITIVE DAMAGES & DUTY TO DEFEND AMENDMENT"
                                    id="EXCLUSION - PUNITIVE DAMAGES & DUTY TO DEFEND AMENDMENT"
                                    value={
                                        data.policy_endorsements &&
                                        data.policy_endorsements.includes(
                                            'EXCLUSION - PUNITIVE DAMAGES & DUTY TO DEFEND AMENDMENT',
                                        )
                                    }
                                    width="100%"
                                    onChange={handleEndorsementChange}
                                />
                                <SimpleCheckboxInput
                                    crossable
                                    bold={false}
                                    label="OFAC"
                                    id="OFAC"
                                    value={data.policy_endorsements && data.policy_endorsements.includes('OFAC')}
                                    width="100%"
                                    onChange={handleEndorsementChange}
                                />
                                <SimpleCheckboxInput
                                    crossable
                                    bold={false}
                                    label="STATE AMENDATORIES"
                                    id="STATE AMENDATORIES"
                                    value={
                                        data.policy_endorsements &&
                                        data.policy_endorsements.includes('STATE AMENDATORIES')
                                    }
                                    width="100%"
                                    onChange={handleEndorsementChange}
                                />
                                <SimpleCheckboxInput
                                    crossable
                                    bold={false}
                                    label="TRIA"
                                    id="TRIA"
                                    value={data.policy_endorsements && data.policy_endorsements.includes('TRIA')}
                                    width="100%"
                                    onChange={handleEndorsementChange}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>
            </SubContainer>
        </Grid>
    );
};
