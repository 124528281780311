import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { BarDatum } from '@nivo/bar';
import { GraphTooltip } from './graph-tooltip.component';
import { LossRunsBarChart } from 'components/pages/applications/specific-components/loss-runs-bar-chart.component';
import { PolicyTrendObject } from '../application-details-page/accordions/modules/quote.component';
import { parseISO } from 'date-fns';

interface Props {
    policyData: PolicyTrendObject[] | undefined;
}

export const LossRunsTrend = ({ policyData }: Props) => {
    const getDatum = useMemo(() => {
        if (policyData == null || policyData.length <= 0)
            return {
                data: [],
                scale: [0, 20, 40, 60, 80, 100],
                max: 10,
            };

        const datum: {
            year: string;
            yearNumeral: number;
            value: number;
            carrier: string;
            provided: string;
            noLoses: string;
        }[] = [];
        // Determine the range of years
        const years = policyData.map(entry => entry.policy_year);
        const minYear = Math.min(...years);
        const maxYear = Math.max(...years);

        // Initialize entries for all years in the range
        for (let year = minYear; year <= maxYear; year++) {
            datum.push({
                year: String(year),
                yearNumeral: year,
                value: 0,
                carrier: 'N/A',
                provided: 'false',
                noLoses: 'false',
            });
        }

        // Update entries with actual data
        policyData.forEach(entry => {
            const yearIndex = datum.findIndex(d => d.year === String(entry.policy_year));
            const wasProvided = entry.is_provided ? entry.is_provided : false;
            const noLoses =
                entry.is_provided && entry.losses_incurred != null && entry.losses_incurred <= 0 ? true : false;

            if (yearIndex !== -1) {
                datum[yearIndex].value += entry.imputed_loss_ratio && wasProvided ? entry.imputed_loss_ratio * 100 : 0;
                datum[yearIndex].carrier = 'N/A'; // or set to actual carrier if available
                datum[yearIndex].provided = `${wasProvided}`;
                datum[yearIndex].noLoses = `${noLoses}`;
            }
        });

        // Determine the maximum value for scaling
        const maxValue = datum.reduce((max, curr) => Math.max(max, +curr.value), 0);

        return {
            scale:
                maxValue <= 5
                    ? [0, 1, 2, 3, 4, 5]
                    : maxValue <= 10
                    ? [0, 2, 4, 6, 8, 10]
                    : maxValue <= 25
                    ? [0, 5, 10, 15, 20, 25]
                    : maxValue <= 50
                    ? [0, 10, 20, 30, 40, 50]
                    : maxValue <= 75
                    ? [0, 15, 30, 45, 60, 75]
                    : [0, 20, 40, 60, 80, 100],
            max:
                maxValue <= 5
                    ? 5
                    : maxValue <= 10
                    ? 10
                    : maxValue <= 25
                    ? 25
                    : maxValue <= 50
                    ? 50
                    : maxValue <= 75
                    ? 75
                    : 100,
            data: datum,
        };
    }, [policyData]);

    return (
        <Box
            sx={{
                paddingX: '12px',
                paddingY: '12px',
                borderStyle: 'solid',
                borderRadius: '5px',
                borderWidth: '1px',
                borderColor: '#393E46',
            }}
        >
            <Stack direction="column">
                <Typography>Historical Loss Ratio %</Typography>
                <LossRunsBarChart
                    percentage
                    margin={{ top: 20, left: 40, bottom: 30, right: 20 }}
                    height={'300px'}
                    data={getDatum.data}
                    keys={['value']}
                    indexBy="year"
                    scale={getDatum.scale}
                    maxValue={getDatum.max}
                    onClick={() => {}}
                    tooltip={({ data, id }) => {
                        const value: string = typeof data.value == 'number' ? data.value.toFixed(2) : '';

                        return (
                            <GraphTooltip>
                                <Stack direction="column">
                                    <Typography display="inline">
                                        {+data.year - 1}-{data.year}
                                    </Typography>
                                    <Typography>Carrier: {data.carrier}</Typography>
                                    <Typography>Imputed LR: {value}%</Typography>
                                </Stack>
                            </GraphTooltip>
                        );
                    }}
                />
            </Stack>
        </Box>
    );
};
