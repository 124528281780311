import { Chip, Grid, InputAdornment, Stack, SxProps, TextField, Theme, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

interface Props {
    value: number[];
    label: string;
    onChange: (value: number[]) => void;
    width?: string;
    smallField?: boolean;
    bold?: boolean;
    blue?: boolean;
    sx?: SxProps<Theme>;
}

export const TagInput = ({
    value,
    label,
    onChange,
    width = '100%',
    smallField = false,
    bold = true,
    blue = false,
    sx,
}: Props) => {
    const [inputValue, setInputValue] = useState<string>('');

    const deleteValue = (index: number) => {
        const newValues = value.filter((entry, i) => i != index);

        onChange(newValues);
    };

    const addValue = () => {
        const parsedValue = parseFloat(inputValue.trim());
        const failure = isNaN(parsedValue);

        if (failure) return;

        onChange([...value, parsedValue]);
    };

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = event.target;
        const newValue = value.replace(/[\s,]+/g, '');

        setInputValue(newValue);
    };

    const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const { key } = event;

        if (key === ',' || key === 'Enter' || key === ' ') addValue();
    };

    const onBlur = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        addValue();
    };

    useEffect(() => {
        setInputValue('');
    }, [value]);

    return (
        <Stack direction="row" spacing={2} alignItems="center" width={width}>
            <Typography
                sx={{
                    fontWeight: bold ? '500' : undefined,
                    width: smallField ? '70%' : '50%',
                    color: blue ? 'primary.main' : undefined,
                }}
            >
                {label}
            </Typography>
            <Grid
                container
                columnSpacing={0.5}
                rowSpacing={0.5}
                sx={{ backgroundColor: 'background.deep', borderRadius: '5px' }}
            >
                {value.map((tag, index) => (
                    <Grid item key={index}>
                        <Chip
                            label={tag.toLocaleString('en-Us', { maximumFractionDigits: 2 })}
                            variant="outlined"
                            onDelete={() => deleteValue(index)}
                        />
                    </Grid>
                ))}
                <Grid item xs={true}>
                    <TextField
                        fullWidth
                        size="small"
                        type="number"
                        value={inputValue}
                        onChange={onInputChange}
                        onKeyDown={onKeyPress}
                        onBlur={onBlur}
                        InputProps={{
                            sx: {
                                color: blue ? 'primary.main' : undefined,
                                backgroundColor: 'background.deep',
                            },
                        }}
                        sx={{
                            color: '#CC5C5C',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderStyle: 'none',
                            },
                            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                display: 'none',
                            },
                            '& input[type=number]': {
                                MozAppearance: 'textfield',
                            },
                            ...sx,
                        }}
                    />
                </Grid>
            </Grid>
        </Stack>
    );
};
