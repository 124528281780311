import { Box, Button, InputAdornment, MenuItem, Paper, Radio, TextField, Typography } from '@mui/material';
import { DatePicker, DateValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { InputFieldDate } from './inputs/field-date.component';
import { InputFieldNumeric } from './inputs/field-numeric.component';
import { InputFieldSelect } from './inputs/field-select.component';
import { Stack } from '@mui/system';

type Props = {
    onChange: (newData: PriorLoss[]) => void;
};

type ClaimData = {
    claim_made_date: string | undefined;
    incident_date: string | undefined;
    loss_type: string[];
    liability_type: string[];
    deceased: boolean | undefined;
    claim_amount: number;
};

export type PriorLoss = {
    claim_made_date: string;
    incident_date: string;
    loss_type: string;
    liability_type: string;
    deceased: boolean;
    claim_amount: number;
};

type ClaimEntry = ClaimData & {
    id: string;
};

const defaultData: ClaimData = {
    claim_made_date: undefined,
    incident_date: undefined,
    loss_type: [],
    liability_type: [],
    deceased: undefined,
    claim_amount: 0,
};

export const QuoteStepClaims = ({ onChange }: Props) => {
    const [formData, setFormData] = useState<ClaimData>(defaultData);
    const [claims, setClaims] = useState<ClaimEntry[]>([]);
    const [addDisabled, setAddDisabled] = useState<boolean>(true);

    const handleDateFieldChange = (id: string, value: string | null, key: string) => {
        if (id === '-1') {
            setFormData({
                ...formData,
                [key]: value === null ? undefined : value,
            });

            return;
        }

        const updatedList: ClaimEntry[] = claims.map(claim => {
            if (claim.id !== id) return claim;

            return {
                ...claim,
                [key]: value === null ? undefined : value,
            };
        });

        setClaims(updatedList);
    };

    const handleMultiSelectFieldChange = (id: string, value: string[], loss: boolean) => {
        const newValue = value.filter(entry => entry !== 'placeholder');

        if (id === '-1') {
            setFormData({
                ...formData,
                [loss ? 'loss_type' : 'liability_type']: newValue,
            });

            return;
        }

        const isValid = newValue.length > 0;

        if (!isValid) return;

        const updatedList: ClaimEntry[] = claims.map(claim => {
            if (claim.id !== id) return claim;

            return {
                ...claim,
                [loss ? 'loss_type' : 'liability_type']: newValue,
            };
        });

        setClaims(updatedList);
    };

    const handleDeceased = (id: string, value: string) => {
        if (id === '-1') {
            setFormData({
                ...formData,
                ['deceased']: value === 'placeholder' ? undefined : value === 'true' ? true : false,
            });

            return;
        }

        const updatedList: ClaimEntry[] = claims.map(claim => {
            if (claim.id !== id) return claim;

            return {
                ...claim,
                ['deceased']: value === 'placeholder' ? undefined : value === 'true' ? true : false,
            };
        });

        setClaims(updatedList);
    };

    const handleNumericFieldChange = (id: string, value: string, name: string) => {
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (id === '-1') {
            setFormData({
                ...formData,
                [name]: value,
            });

            return;
        }

        const updatedList: ClaimEntry[] = claims.map(claim => {
            if (claim.id !== id) return claim;

            return {
                ...claim,
                [name]: value,
            };
        });

        setClaims(updatedList);
    };

    const handleAdd = () => {
        const valueToAdd: ClaimEntry = {
            ...formData,
            id: `${claims.length + 1}`,
        };

        setClaims([valueToAdd, ...claims]);
        setFormData(defaultData);
    };

    const handleRemove = (id: string) => {
        const updatedList = claims.filter(claim => claim.id !== id);

        setClaims(updatedList);
    };

    useEffect(() => {
        const losses: PriorLoss[] = [];

        claims.forEach(claim => {
            losses.push({
                claim_made_date: claim.claim_made_date === undefined ? '2023-01-01' : claim.claim_made_date,
                incident_date: claim.incident_date === undefined ? '2023-01-01' : claim.incident_date,
                loss_type: claim.loss_type.toString(),
                liability_type: claim.liability_type.toString(),
                deceased: claim.deceased === undefined ? false : claim.deceased,
                claim_amount: claim.claim_amount,
            });
        });
        onChange(losses);
    }, [claims]);

    useEffect(() => {
        const canAdd =
            formData.claim_made_date != undefined &&
            formData.incident_date != undefined &&
            formData.loss_type.length > 0 &&
            formData.liability_type.length > 0 &&
            formData.deceased !== undefined;

        setAddDisabled(!canAdd);
    }, [formData]);

    return (
        <Stack direction="column" spacing={2} alignItems="start" sx={{ paddingRight: '32px' }}>
            <Typography>Enter each claim</Typography>
            <Box
                sx={{
                    border: '1px solid',
                    borderRadius: '5px',
                    borderColor: 'primary.main',
                    width: '100%',
                    paddingY: '12px',
                    paddingX: '16px',
                }}
            >
                <Stack direction="column" spacing={2}>
                    <Stack direction="row" spacing={2}>
                        <Typography width="18%" variant="subtitle2">
                            Claim Made Date
                        </Typography>
                        <Typography width="18%" variant="subtitle2">
                            Incident Date
                        </Typography>
                        <Typography width="23%" variant="subtitle2">
                            Loss Type
                        </Typography>
                        <Typography width="12%" variant="subtitle2">
                            Liability Type
                        </Typography>
                        <Typography width="12%" variant="subtitle2">
                            Deceased?
                        </Typography>
                        <Typography width="12%" variant="subtitle2">
                            Claim Amount
                        </Typography>
                        <Box width="5%" />
                    </Stack>
                    <Paper
                        sx={{
                            width: '100%',
                            maxHeight: '350px',
                            overflowY: 'auto',
                            backgroundColor: 'transparent',
                            backgroundImage: 'none',
                        }}
                    >
                        <Stack direction="column" spacing={1}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Box width="18%">
                                    <InputFieldDate
                                        altBorder
                                        disableFuture
                                        value={formData.claim_made_date}
                                        onChange={newValue => handleDateFieldChange('-1', newValue, 'claim_made_date')}
                                    />
                                </Box>
                                <Box width="18%">
                                    <InputFieldDate
                                        altBorder
                                        disableFuture
                                        value={formData.incident_date}
                                        onChange={newValue => handleDateFieldChange('-1', newValue, 'incident_date')}
                                    />
                                </Box>
                                <Typography width="23%" variant="subtitle2">
                                    <InputFieldSelect
                                        altBorder
                                        fullWidth
                                        multiple
                                        value={formData.loss_type.length > 0 ? formData.loss_type : ['placeholder']}
                                        id="loss_type"
                                        label=""
                                        onChange={event => {
                                            const { value } = event.target;

                                            if (Array.isArray(value) && value.every(item => typeof item === 'string')) {
                                                handleMultiSelectFieldChange('-1', value, true);
                                            }
                                        }}
                                    >
                                        <MenuItem disabled value="placeholder">
                                            <Typography sx={{ color: 'text.discrete', fontStyle: 'oblique' }}>
                                                Select loss type(s)
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem value="Altercation">Altercation</MenuItem>
                                        <MenuItem value="Choking">Choking</MenuItem>
                                        <MenuItem value="Elopement">Elopement</MenuItem>
                                        <MenuItem value="Fall">Fall</MenuItem>
                                        <MenuItem value="Medication Errors">Medication Errors</MenuItem>
                                        <MenuItem value="Pressure Ulcer/Wound">Pressure Ulcer/Wound</MenuItem>
                                        <MenuItem value="Sepsis/Infection">Sepsis/Infection</MenuItem>
                                        <MenuItem value="Wrongful Death">Wrongful Death</MenuItem>
                                    </InputFieldSelect>
                                </Typography>
                                <Box width="12%">
                                    <InputFieldSelect
                                        fullWidth
                                        altBorder
                                        multiple
                                        value={
                                            formData.liability_type.length > 0
                                                ? formData.liability_type
                                                : ['placeholder']
                                        }
                                        id="liability_type"
                                        label=""
                                        onChange={event => {
                                            const { value } = event.target;

                                            if (Array.isArray(value) && value.every(item => typeof item === 'string')) {
                                                handleMultiSelectFieldChange('-1', value, false);
                                            }
                                        }}
                                    >
                                        <MenuItem disabled value="placeholder">
                                            <Typography sx={{ color: 'text.discrete', fontStyle: 'oblique' }}>
                                                Select
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem value="GL">GL</MenuItem>
                                        <MenuItem value="PL">PL</MenuItem>
                                    </InputFieldSelect>
                                </Box>
                                <Box width="12%">
                                    <InputFieldSelect
                                        fullWidth
                                        altBorder
                                        value={
                                            formData.deceased === undefined
                                                ? 'placeholder'
                                                : formData.deceased === true
                                                ? 'true'
                                                : 'false'
                                        }
                                        id="deceased"
                                        label=""
                                        onChange={event => {
                                            const { value } = event.target;

                                            handleDeceased('-1', value);
                                        }}
                                    >
                                        <MenuItem disabled value="placeholder">
                                            <Typography sx={{ color: 'text.discrete', fontStyle: 'oblique' }}>
                                                Select
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem value="true">Yes</MenuItem>
                                        <MenuItem value="false">No</MenuItem>
                                    </InputFieldSelect>
                                </Box>
                                <Box width="12%">
                                    <InputFieldNumeric
                                        fullWidth
                                        altBorder
                                        value={formData.claim_amount}
                                        id="claim_amount"
                                        type="currency"
                                        label=""
                                        onChange={event => {
                                            const { value, name } = event.target;

                                            handleNumericFieldChange('-1', value, name);
                                        }}
                                    />
                                </Box>
                                <Box width="5%" minHeight="100%">
                                    <Button
                                        variant="text"
                                        disabled={addDisabled}
                                        sx={{
                                            width: '100%',
                                            innerHeight: '100%',
                                            '&:disabled': { color: 'text.disabled' },
                                        }}
                                        onClick={handleAdd}
                                    >
                                        ADD
                                    </Button>
                                </Box>
                            </Stack>
                            {claims.map((claim, index) => {
                                return (
                                    <Stack key={`${index}`} direction="row" alignItems="center" spacing={2}>
                                        <Box width="18%">
                                            <InputFieldDate
                                                disableManualInput
                                                altBorder
                                                disableFuture
                                                value={claim.claim_made_date}
                                                onChange={newValue =>
                                                    handleDateFieldChange(claim.id, newValue, 'claim_made_date')
                                                }
                                            />
                                        </Box>
                                        <Box width="18%">
                                            <InputFieldDate
                                                disableManualInput
                                                altBorder
                                                disableFuture
                                                value={claim.incident_date}
                                                onChange={newValue =>
                                                    handleDateFieldChange(claim.id, newValue, 'incident_date')
                                                }
                                            />
                                        </Box>
                                        <Typography width="23%" variant="subtitle2">
                                            <InputFieldSelect
                                                altBorder
                                                fullWidth
                                                multiple
                                                value={claim.loss_type.length > 0 ? claim.loss_type : ['placeholder']}
                                                id="loss_type"
                                                label=""
                                                onChange={event => {
                                                    const { value } = event.target;

                                                    if (
                                                        Array.isArray(value) &&
                                                        value.every(item => typeof item === 'string')
                                                    ) {
                                                        handleMultiSelectFieldChange(claim.id, value, true);
                                                    }
                                                }}
                                            >
                                                <MenuItem disabled value="placeholder">
                                                    <Typography sx={{ color: 'text.discrete', fontStyle: 'oblique' }}>
                                                        Select loss type(s)
                                                    </Typography>
                                                </MenuItem>
                                                <MenuItem value="Altercation">Altercation</MenuItem>
                                                <MenuItem value="Choking">Choking</MenuItem>
                                                <MenuItem value="Elopement">Elopement</MenuItem>
                                                <MenuItem value="Fall">Fall</MenuItem>
                                                <MenuItem value="Medication Errors">Medication Errors</MenuItem>
                                                <MenuItem value="Pressure Ulcer/Wound">Pressure Ulcer/Wound</MenuItem>
                                                <MenuItem value="Sepsis/Infection">Sepsis/Infection</MenuItem>
                                                <MenuItem value="Wrongful Death">Wrongful Death</MenuItem>
                                            </InputFieldSelect>
                                        </Typography>
                                        <Box width="12%">
                                            <InputFieldSelect
                                                fullWidth
                                                altBorder
                                                multiple
                                                value={
                                                    claim.liability_type.length > 0
                                                        ? claim.liability_type
                                                        : ['placeholder']
                                                }
                                                id="liability_type"
                                                label=""
                                                onChange={event => {
                                                    const { value } = event.target;

                                                    if (
                                                        Array.isArray(value) &&
                                                        value.every(item => typeof item === 'string')
                                                    ) {
                                                        handleMultiSelectFieldChange(claim.id, value, false);
                                                    }
                                                }}
                                            >
                                                <MenuItem disabled value="placeholder">
                                                    <Typography sx={{ color: 'text.discrete', fontStyle: 'oblique' }}>
                                                        Select
                                                    </Typography>
                                                </MenuItem>
                                                <MenuItem value="GL">GL</MenuItem>
                                                <MenuItem value="PL">PL</MenuItem>
                                            </InputFieldSelect>
                                        </Box>
                                        <Box width="12%">
                                            <InputFieldSelect
                                                fullWidth
                                                altBorder
                                                value={
                                                    claim.deceased === undefined
                                                        ? 'placeholder'
                                                        : claim.deceased === true
                                                        ? 'true'
                                                        : 'false'
                                                }
                                                id="deceased"
                                                label=""
                                                onChange={event => {
                                                    const { value } = event.target;

                                                    handleDeceased(claim.id, value);
                                                }}
                                            >
                                                <MenuItem disabled value="placeholder">
                                                    <Typography sx={{ color: 'text.discrete', fontStyle: 'oblique' }}>
                                                        Select
                                                    </Typography>
                                                </MenuItem>
                                                <MenuItem value="true">Yes</MenuItem>
                                                <MenuItem value="false">No</MenuItem>
                                            </InputFieldSelect>
                                        </Box>
                                        <Box width="12%">
                                            <InputFieldNumeric
                                                fullWidth
                                                altBorder
                                                value={claim.claim_amount}
                                                id="claim_amount"
                                                type="currency"
                                                label=""
                                                onChange={event => {
                                                    const { value, name } = event.target;

                                                    handleNumericFieldChange(claim.id, value, name);
                                                }}
                                            />
                                        </Box>
                                        <Box width="5%" minHeight="100%">
                                            <Button
                                                variant="text"
                                                sx={{ width: '100%', innerHeight: '100%' }}
                                                onClick={() => {
                                                    handleRemove(claim.id);
                                                }}
                                            >
                                                <DeleteIcon sx={{ color: 'text.discrete' }} />
                                            </Button>
                                        </Box>
                                    </Stack>
                                );
                            })}
                        </Stack>
                    </Paper>
                </Stack>
            </Box>
        </Stack>
    );
};
