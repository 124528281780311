import { Typography } from '@mui/material';

type Props = {
    text: string;
};
export const PageTitle = ({ text }: Props) => {
    return (
        <Typography component="h4" sx={{ fontSize: '34px', marginBottom: '15px' }} color="text.primary" maxWidth="100%">
            {text}
        </Typography>
    );
};
