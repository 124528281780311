import { Paper, Typography } from '@mui/material';

import { AssumptionData } from './quote-step-assumptions.component';
import { ClaimsSummary } from './quote-step-quote.component';
import { CompactTableRowContainer } from '../compact-table-row-container.component';
import { QuoteSummary } from './quote-stepper.component';
import { Stack } from '@mui/system';

type Props = {
    quote: QuoteSummary;
    claimsData: ClaimsSummary;
    assumptions: AssumptionData;
    scrollable?: boolean;
    maxTableHeight?: string;
};

export const QuoteSummaryComponent = ({
    claimsData,
    assumptions,
    quote,
    scrollable = false,
    maxTableHeight = '310px',
}: Props) => {
    const formatCurrency = (value: number | string) => {
        const parsedValue = typeof value === 'string' ? parseInt(value) : value;

        return parsedValue.toLocaleString(undefined, {
            style: 'decimal',
            minimumFractionDigits: 2, // Minimum number of decimal places
            maximumFractionDigits: 2, // Maximum number of decimal places
        });
    };

    return (
        <Stack direction="column" spacing={2} alignItems="start" sx={{ paddingRight: '32px' }}>
            <Stack direction="row" width="100%" spacing={2}>
                <Stack direction="column" spacing={1} width="50%" padding="12px">
                    <Stack direction="row" width="100%">
                        <Typography width="50%" variant="subtitle2">
                            Assumptions
                        </Typography>
                    </Stack>
                    <Stack direction="row" width="100%">
                        <Typography width="50%" variant="body2">
                            Margin
                        </Typography>
                        <Typography width="50%" align="right" variant="body2">
                            {quote.margin_rate * 100}%
                        </Typography>
                    </Stack>
                    <Stack direction="row" width="100%">
                        <Typography width="50%" variant="body2">
                            Broker Fee
                        </Typography>
                        <Typography width="50%" align="right" variant="body2">
                            {assumptions.broker_fee}%
                        </Typography>
                    </Stack>
                    <Stack direction="row" width="100%">
                        <Typography width="50%" variant="body2">
                            MGA Fee
                        </Typography>
                        <Typography width="50%" align="right" variant="body2">
                            {assumptions.mga_fee}%
                        </Typography>
                    </Stack>
                    <Stack direction="row" width="100%">
                        <Typography width="50%" variant="body2">
                            Specific Deductible
                        </Typography>
                        <Typography width="50%" align="right" variant="body2">
                            ${formatCurrency(assumptions.specific_deductible)}
                        </Typography>
                    </Stack>
                    <Stack direction="row" width="100%">
                        <Typography width="50%" variant="body2">
                            Aggregate Deductible
                        </Typography>
                        <Typography width="50%" align="right" variant="body2">
                            ${formatCurrency(assumptions.agregate_deductible)}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack direction="column" spacing={1} width="50%" padding="12px">
                    <Stack direction="row" width="100%">
                        <Typography width="50%" variant="subtitle2">
                            Loss Runs
                        </Typography>
                    </Stack>
                    <Stack direction="row" width="100%">
                        <Typography width="70%" variant="body2">
                            # Incident of Years
                        </Typography>
                        <Typography width="30%" align="right" variant="body2">
                            {formatCurrency(claimsData.incidents_of_years)}
                        </Typography>
                    </Stack>
                    <Stack direction="row" width="100%">
                        <Typography width="70%" variant="body2">
                            Prio Year Total Paid
                        </Typography>
                        <Typography width="30%" align="right" variant="body2">
                            ${formatCurrency(claimsData.prior_year_total_paid)}
                        </Typography>
                    </Stack>
                    <Stack direction="row" width="100%">
                        <Typography width="70%" variant="body2">
                            Prior Year Number Claim
                        </Typography>
                        <Typography width="30%" align="right" variant="body2">
                            {formatCurrency(claimsData.prior_year_total_claims)}
                        </Typography>
                    </Stack>
                    <Stack direction="row" width="100%">
                        <Typography width="70%" variant="body2">
                            Avg. Amount Paid Anually
                        </Typography>
                        <Typography width="30%" align="right" variant="body2">
                            ${formatCurrency(claimsData.avg_amount_paid_annually)}
                        </Typography>
                    </Stack>
                    <Stack direction="row" width="100%">
                        <Typography width="70%" variant="body2">
                            Max Amount Paid
                        </Typography>
                        <Typography width="30%" align="right" variant="body2">
                            ${formatCurrency(claimsData.max_amount_paid)}
                        </Typography>
                    </Stack>
                    <Stack direction="row" width="100%">
                        <Typography width="70%" variant="body2">
                            Max # of Claims Per Year
                        </Typography>
                        <Typography width="30%" align="right" variant="body2">
                            {formatCurrency(claimsData.max_claims_per_year)}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
            <Paper
                sx={{
                    width: '100%',
                    maxHeight: scrollable ? maxTableHeight : undefined,
                    overflowY: 'auto',
                    backgroundColor: 'transparent',
                    backgroundImage: 'none',
                }}
            >
                <Stack width="100%" direction="column">
                    <CompactTableRowContainer alt>
                        <Stack direction="row">
                            <Typography width="70%" variant="body2">
                                Total Quoted Rate
                            </Typography>
                            <Typography
                                width="30%"
                                variant="body2"
                                align="right"
                                sx={{ fontWeight: '500', color: 'primary.main' }}
                            >
                                ${formatCurrency(quote.total_quoted_rate)}
                            </Typography>
                        </Stack>
                    </CompactTableRowContainer>
                    <CompactTableRowContainer>
                        <Stack direction="row">
                            <Typography width="70%" variant="body2">
                                Projected Loss Cost
                            </Typography>
                            <Typography width="30%" variant="body2" align="right">
                                ${formatCurrency(quote.projected_loss_cost_per_bed)}
                            </Typography>
                        </Stack>
                    </CompactTableRowContainer>
                    <CompactTableRowContainer alt>
                        <Stack direction="row">
                            <Typography width="70%" variant="body2">
                                Quoted per Bed Rate
                            </Typography>
                            <Typography width="30%" variant="body2" align="right">
                                ${formatCurrency(quote.quoted_per_bed_rate)}
                            </Typography>
                        </Stack>
                    </CompactTableRowContainer>
                    <CompactTableRowContainer>
                        <Stack direction="row">
                            <Typography width="70%" variant="body2">
                                Tax Rate
                            </Typography>
                            <Typography width="30%" variant="body2" align="right">
                                {quote.tax_rate * 100}%
                            </Typography>
                        </Stack>
                    </CompactTableRowContainer>
                    <CompactTableRowContainer alt>
                        <Stack direction="row">
                            <Typography width="70%" variant="body2">
                                Tax Amount
                            </Typography>
                            <Typography width="30%" variant="body2" align="right">
                                ${formatCurrency(quote.tax_amount)}
                            </Typography>
                        </Stack>
                    </CompactTableRowContainer>
                    <CompactTableRowContainer>
                        <Stack direction="row">
                            <Typography width="70%" variant="body2">
                                Overhead Rate
                            </Typography>
                            <Typography width="30%" variant="body2" align="right">
                                {quote.overhead_rate * 100}%
                            </Typography>
                        </Stack>
                    </CompactTableRowContainer>
                    <CompactTableRowContainer alt>
                        <Stack direction="row">
                            <Typography width="70%" variant="body2">
                                Overhead Amount
                            </Typography>
                            <Typography width="30%" variant="body2" align="right">
                                ${formatCurrency(quote.overhead_amount)}
                            </Typography>
                        </Stack>
                    </CompactTableRowContainer>
                    <CompactTableRowContainer>
                        <Stack direction="row">
                            <Typography width="70%" variant="body2">
                                Margin Rate
                            </Typography>
                            <Typography width="30%" variant="body2" align="right">
                                {quote.margin_rate * 100}%
                            </Typography>
                        </Stack>
                    </CompactTableRowContainer>
                    <CompactTableRowContainer alt>
                        <Stack direction="row">
                            <Typography width="70%" variant="body2">
                                Margin Amount
                            </Typography>
                            <Typography width="30%" variant="body2" align="right">
                                ${formatCurrency(quote.margin_amount)}
                            </Typography>
                        </Stack>
                    </CompactTableRowContainer>
                    <CompactTableRowContainer>
                        <Stack direction="row">
                            <Typography width="70%" variant="body2">
                                Broker Fee Rate
                            </Typography>
                            <Typography width="30%" variant="body2" align="right">
                                {quote.broker_fee_rate * 100}%
                            </Typography>
                        </Stack>
                    </CompactTableRowContainer>
                    <CompactTableRowContainer alt>
                        <Stack direction="row">
                            <Typography width="70%" variant="body2">
                                Broker Fee Amount
                            </Typography>
                            <Typography width="30%" variant="body2" align="right">
                                ${formatCurrency(quote.broker_fee_amount)}
                            </Typography>
                        </Stack>
                    </CompactTableRowContainer>
                    <CompactTableRowContainer>
                        <Stack direction="row">
                            <Typography width="70%" variant="body2">
                                Mga Fee Rate
                            </Typography>
                            <Typography width="30%" variant="body2" align="right">
                                {quote.mga_fee_rate * 100}%
                            </Typography>
                        </Stack>
                    </CompactTableRowContainer>
                    <CompactTableRowContainer alt>
                        <Stack direction="row">
                            <Typography width="70%" variant="body2">
                                Mga Fee Amount
                            </Typography>
                            <Typography width="30%" variant="body2" align="right">
                                ${formatCurrency(quote.mga_fee_amount)}
                            </Typography>
                        </Stack>
                    </CompactTableRowContainer>
                    <CompactTableRowContainer>
                        <Stack direction="row">
                            <Typography width="70%" variant="body2">
                                Estimated Market per Bed Rate
                            </Typography>
                            <Typography width="30%" variant="body2" align="right">
                                ${formatCurrency(quote.estimated_market_per_bed_rate)}
                            </Typography>
                        </Stack>
                    </CompactTableRowContainer>
                </Stack>
            </Paper>
        </Stack>
    );
};
