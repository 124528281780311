import { FacilityIndex } from 'types/underwriter/underwriter.type';
import { IndexRow } from './index-row.component';
import { Stack } from '@mui/system';
import { SubContainer } from 'components/general/layout/sub-container.component';
import { Typography } from '@mui/material';

type Props = {
    title: string;
    indexes: FacilityIndex[];
};

export const IndexTable = ({ title, indexes }: Props) => {
    return (
        <SubContainer sx={{ padding: '20px' }}>
            <Stack direction="column" spacing={2}>
                <Typography>{title}</Typography>
                <Stack direction="column">
                    {indexes.map((row: FacilityIndex, index: number) => {
                        return <IndexRow alt={index % 2 === 0} index={row} key={row.label + index} />;
                    })}
                </Stack>
            </Stack>
        </SubContainer>
    );
};
