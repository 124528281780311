import { Autocomplete, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { FacilityData, StateData, StateFacilitySummary } from 'types/underwriter/underwriter.type';
import { FacilityLocationDetails, StateFacility } from './types/underwriter.types';
import { MockFacility, MockState } from './mock-data';
import { apiUrl, setHeadersNoAuth } from 'util/api/api.util';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Container } from 'components/general/layout/container.component';
import { PageTitle } from 'components/general/layout/page-title.component';
import { Search } from '@mui/icons-material';
import { SearchBar } from 'components/general/search-bar.component';
import { Stack } from '@mui/system';
import { UnderwriterFacility } from './facility-module/underwriter-facility.component';
import { UnderwriterState } from './state-module/underwriter-state.component';
import { UsState } from 'util/api/state-map.util';
import { useDebounce } from 'hooks/useDebounce';

type Props = {
    onFacilityClick: (newFacility: StateFacility) => void;
};

//TO DO: Make clearable, clear on map selection change

export const FacilitySearchBar = ({ onFacilityClick }: Props) => {
    const [options, setOptions] = useState<StateFacility[]>([]);
    const [value, setValue] = useState<StateFacility | undefined>(undefined);
    const [inputValue, setInputValue] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const previousController = useRef<AbortController>();
    const debouncedSearch = useDebounce(inputValue, 300);

    const getData = useCallback(async (searchTerm: string) => {
        if (searchTerm.trim() === '') {
            setOptions([]);

            return;
        }

        try {
            if (previousController.current) {
                previousController.current.abort();
            }

            const controller = new AbortController();
            const signal = controller.signal;

            previousController.current = controller;
            setSearchLoading(true);

            const { data } = await apiUrl.get<StateFacility[]>(
                `/list_underwritable_facilities?search_query=${searchTerm}`,
                setHeadersNoAuth(),
            );

            //setOptions(data.length > 0 ? (data.length > 10 ? data.slice(0, 10) : data) : []);
            setOptions(data.length > 0 ? data : []);

            setSearchLoading(false);
        } catch (error) {
            setSearchLoading(false);
            setOptions([]);
        }
    }, []);

    useEffect(() => {
        getData(debouncedSearch);
    }, [getData, debouncedSearch]);

    return (
        <>
            <Autocomplete
                id="facilities"
                getOptionLabel={(option: string | StateFacility) =>
                    typeof option === 'string' ? option : option.facility_name
                }
                filterOptions={x => x}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={value}
                noOptionsText={<Typography sx={{ color: 'text.primary' }}>No Results</Typography>}
                onChange={(event, newValue: undefined | StateFacility) => {
                    setValue(newValue);
                    if (newValue !== undefined) onFacilityClick(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                disableClearable={true}
                loading={searchLoading}
                loadingText={<Typography sx={{ color: 'text.primary' }}>Loading...</Typography>}
                renderOption={(props, option: StateFacility, selected) => (
                    <li {...props}>
                        <Typography sx={{ color: 'text.primary' }}>{option.facility_name}</Typography>
                    </li>
                )}
                renderInput={params => {
                    return (
                        <TextField
                            {...params}
                            InputLabelProps={{
                                style: {
                                    color: 'background.paper',
                                    border: 'none',
                                    fontStyle: 'normal',
                                },
                            }}
                            size="small"
                            placeholder="Facility Name"
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                style: {
                                    border: 'none',
                                },
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search sx={{ color: 'text.primary' }} />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                backgroundColor: 'background.paper',
                                borderRadius: '10px',
                                minWidth: { md: '320px' },
                                '&::placeholder': {
                                    color: 'text.discrete',
                                    fontStyle: 'normal !important',
                                    opacity: 1 /* Firefox */,
                                },

                                ':-ms-input-placeholder': {
                                    /* Internet Explorer 10-11 */ color: 'text.discrete',
                                    fontStyle: 'normal !important',
                                },

                                '::-ms-input-placeholder': {
                                    /* Microsoft Edge */ color: 'text.discrete',
                                    fontStyle: 'normal !important',
                                },

                                '& .MuiOutlinedInput-root': {
                                    '& ::placeholder': {
                                        fontStyle: 'normal',
                                    },
                                    '& fieldset': {
                                        border: 'none',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'common.white',
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: 'none',
                                    },
                                },
                            }}
                        />
                    );
                }}
            />
        </>
    );
};
