import { Assignment, Calculate } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';

import { SideBarLink } from './side-bar-link.component';
import { useLocation } from 'react-router-dom';

export const SideBar = () => {
    const { pathname } = useLocation();

    return (
        <>
            <Box
                bgcolor="background.dark"
                sx={{
                    display: { xs: 'none', md: 'block' },
                    minHeight: '(100vh - 64px)',
                    minWidth: '90px',
                }}
            >
                <Stack width="95%" mx="auto" height="100%" spacing={2} pt="60px">
                    {/* <SideBarLink
                        icon={<Calculate />}
                        text="Underwriter"
                        active={pathname.includes('underwriter')}
                        href="/underwriter"
                    /> */}
                    <SideBarLink
                        icon={<Assignment />}
                        text="Apps"
                        active={pathname.includes('applications')}
                        href="/applications"
                    />
                </Stack>
            </Box>
        </>
    );
};
