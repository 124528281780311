/* eslint-disable indent */
import { Autocomplete, Box, InputAdornment, Stack, SxProps, TextField, Theme, Typography } from '@mui/material';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { orionApiUrl, setHeaders } from 'util/api/api.util';

import { AddAgentComponent } from '../add-agent-popup.component';
import { Search } from '@mui/icons-material';
import { useDebounce } from 'hooks/useDebounce';

interface Props {
    id: string;
    label: string;
    brokerId: null | string;
    onChange: (newValue: undefined | AgentInfo) => void;
    onClear: () => void;
    width?: string;
    smallField?: boolean;
    bold?: boolean;
    filter?: boolean;
    showMissing?: boolean;
    sx?: SxProps<Theme>;
}

export interface AgentSearchInputRef {
    clearInput: () => void;
}

export type AgentInfo = {
    id: string;
    brokerage_id: string;
    name: string;
    email: string;
};

export const AgentSearchInput = forwardRef<AgentSearchInputRef, Props>(
    (
        {
            id,
            label,
            brokerId,
            onChange,
            onClear,
            width = '100%',
            smallField = false,
            bold = true,
            filter = false,
            showMissing = false,
            sx,
        }: Props,
        ref,
    ) => {
        const [options, setOptions] = useState<AgentInfo[]>([]);
        const [value, setValue] = useState<AgentInfo | undefined>(undefined);
        const [inputValue, setInputValue] = useState<string>('');
        const [searchLoading, setSearchLoading] = useState(false);
        const [open, setOpen] = useState<boolean>(false);
        const [displayPopper, setDisplayPopper] = useState<boolean>(true);
        const previousController = useRef<AbortController>();
        const debouncedSearch = useDebounce(inputValue, 300);

        const clearInput = () => {
            setValue(undefined);
            setInputValue('');
        };

        useImperativeHandle(ref, () => ({
            clearInput,
        }));

        const getData = useCallback(
            async (searchTerm: string) => {
                if (searchTerm.trim() === '') {
                    setOptions([]);

                    return;
                }

                try {
                    if (previousController.current) {
                        previousController.current.abort();
                    }

                    const controller = new AbortController();
                    const signal = controller.signal;

                    previousController.current = controller;
                    setSearchLoading(true);

                    const { data } = await orionApiUrl.get<AgentInfo[]>(
                        `/v1/agent/?${brokerId != null ? `brokerage_id=${brokerId}&` : ''}search=${searchTerm}`,
                        setHeaders(),
                    );

                    setOptions(data.length > 0 ? data : []);

                    setSearchLoading(false);
                } catch (error) {
                    setSearchLoading(false);
                    setOptions([]);
                }
            },
            [brokerId],
        );

        useEffect(() => {
            getData(debouncedSearch);
        }, [getData, debouncedSearch]);

        useEffect(() => {
            if (debouncedSearch == null || debouncedSearch.length <= 0) {
                onClear();
            }
        }, [debouncedSearch]);

        return (
            <>
                <AddAgentComponent
                    open={open}
                    nameValue={inputValue}
                    broker_id={brokerId ? brokerId : 'N/A'}
                    onClose={() => {
                        setDisplayPopper(true);
                        setOpen(false);
                    }}
                    onNewAgent={(newAgent: AgentInfo) => {
                        const isPresent = options.includes(newAgent);

                        if (!isPresent) {
                            setOptions([newAgent, ...options]);
                        }
                        setValue(newAgent);
                        setInputValue(newAgent.name);
                        onChange(newAgent);
                    }}
                />
                <Stack direction="row" spacing={2} alignItems={filter ? 'unset' : 'center'} width={width}>
                    {!filter && (
                        <Typography sx={{ fontWeight: bold ? '500' : undefined, width: smallField ? '70%' : '50%' }}>
                            {label}
                        </Typography>
                    )}
                    {!filter && <Box></Box>}
                    <Autocomplete
                        fullWidth
                        freeSolo
                        componentsProps={{ popper: { open: displayPopper } }}
                        id={id}
                        disabled={brokerId == null}
                        getOptionLabel={(option: string | AgentInfo) =>
                            typeof option === 'string' ? option : option.name
                        }
                        value={value}
                        inputValue={inputValue}
                        filterOptions={x => x}
                        options={
                            filter
                                ? [...options]
                                : [
                                      {
                                          id: 'add-new',
                                          name: 'Add New',
                                          brokerage_id: brokerId != null ? brokerId : 'N/A',
                                          email: 'email',
                                      },
                                      ...options,
                                  ]
                        }
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        noOptionsText={<Typography sx={{ color: 'text.primary' }}>No Results</Typography>}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                setInputValue(newValue);
                            } else if (newValue && newValue.id) {
                                setValue(newValue);
                                onChange(newValue);
                            } else {
                                setValue(newValue);
                                onChange(newValue);
                            }
                        }}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        disableClearable={true}
                        loading={searchLoading}
                        loadingText={<Typography sx={{ color: 'text.primary' }}>Loading...</Typography>}
                        renderOption={(props, option: AgentInfo, selected) => {
                            if (option.id == 'add-new') {
                                return (
                                    <li
                                        {...props}
                                        onClick={event => {
                                            setTimeout(() => {
                                                if (document.activeElement instanceof HTMLElement)
                                                    document.activeElement.blur();
                                            }, 0);
                                            event.stopPropagation();
                                            setDisplayPopper(false);
                                            setOpen(true);
                                        }}
                                    >
                                        <Typography sx={{ color: 'text.primary' }}>+ Add New Agent</Typography>
                                    </li>
                                );
                            } else {
                                return (
                                    <li {...props}>
                                        {option.id == 'add-new' ? (
                                            <Typography sx={{ color: 'text.primary' }}>+ Add New Broker</Typography>
                                        ) : (
                                            <Typography sx={{ color: 'text.primary' }}>{option.name}</Typography>
                                        )}
                                    </li>
                                );
                            }
                        }}
                        renderInput={params => {
                            return (
                                <TextField
                                    {...params}
                                    size="small"
                                    placeholder={filter ? 'Agent' : undefined}
                                    InputLabelProps={{
                                        style: {
                                            color: 'background.paper',
                                            border: 'none',
                                            fontStyle: 'normal',
                                        },
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                        sx: {
                                            backgroundColor: 'background.deep',
                                        },
                                        style: {
                                            border: showMissing ? '1px solid #CC5C5C' : 'none',
                                        },
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <Search sx={{ color: 'text.primary' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        borderRadius: filter ? '8px' : undefined,
                                        backgroundColor: 'background.paper',
                                        '&::placeholder': {
                                            color: 'text.discrete',
                                            fontStyle: 'normal !important',
                                            opacity: 1 /* Firefox */,
                                        },

                                        ':-ms-input-placeholder': {
                                            /* Internet Explorer 10-11 */ color: 'text.discrete',
                                            fontStyle: 'normal !important',
                                        },

                                        '::-ms-input-placeholder': {
                                            /* Microsoft Edge */ color: 'text.discrete',
                                            fontStyle: 'normal !important',
                                        },

                                        '& .MuiOutlinedInput-root': {
                                            '& ::placeholder': {
                                                fontStyle: 'normal',
                                            },
                                            '& fieldset': {
                                                border: 'none',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'common.white',
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: 'none',
                                            },
                                        },
                                    }}
                                />
                            );
                        }}
                    />
                    {!filter && <Box></Box>}
                </Stack>
            </>
        );
    },
);

AgentSearchInput.displayName = 'AgentSearchInput';
