import './index.css';
import 'animate.css';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import App from './App';
import { LicenseInfo } from '@mui/x-license-pro';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { store } from 'store/store';

LicenseInfo.setLicenseKey(
    'efa2529369b3694c5bf423f957cad71fTz01NjczNixFPTE3MDM3ODQ1MDg1NjAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// Surround App component with redux store so we can use it anywhere in the application
root.render(
    <Provider store={store}>
        <React.StrictMode>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <App />
            </LocalizationProvider>
        </React.StrictMode>
    </Provider>,
);
