/* eslint-disable @typescript-eslint/no-non-null-assertion */

import Cookies from 'js-cookie';
import axios from 'axios';

export const geminiApiUrl = axios.create({
    baseURL: process.env.REACT_APP_GEMINI_API,
});

export const apiUrl = axios.create({
    baseURL: process.env.REACT_APP_ORION_API,
});

export const orionApiUrl = axios.create({
    baseURL: process.env.REACT_APP_ORION_API_V2,
});

export const pdfApiUrl = axios.create({
    baseURL: process.env.REACT_APP_PDF_API,
});

export const setHeaders = () => {
    const user = JSON.parse(Cookies.get('token')!);

    return {
        headers: {
            Authorization: `Bearer ${user.token}`,
        },
    };
};

export const setHeadersNoAuth = () => {
    return {
        headers: { Accept: '*/*' },
    };
};
