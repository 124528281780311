import { NumberFormatValues, NumericFormat } from 'react-number-format';

import { InputBaseComponentProps } from '@mui/material';
import React from 'react';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

export const CurrencyInputSlot = React.forwardRef<HTMLInputElement, CustomProps>(function CurrencyInputSlot(
    props,
    ref,
) {
    const { onChange, name, ...other } = props;

    return (
        <NumericFormat
            {...other}
            defaultValue={'number'}
            getInputRef={ref}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            onValueChange={values => {
                onChange({
                    target: {
                        name: name,
                        value: values.value,
                    },
                });
            }}
            valueIsNumericString
        />
    );
});
