/* eslint-disable indent */
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { orionApiUrl, setHeaders } from 'util/api/api.util';
import { useCallback, useEffect, useState } from 'react';

import { DateRange } from '@mui/x-date-pickers-pro';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LossRunsInformation } from '../../application-details-page/accordions/modules/narrative-losses.component';
import { SimpleDateRangeInput } from './simple-date-range-input.component';
import { SimpleDropdownInput } from './simple-dropdown-input.component';
import { SimpleInput } from './simple-input.component';
import { TagInput } from './tag-input.component';
import { handleError } from 'util/error/handleError';
import { useParams } from 'react-router-dom';

type Props = {
    data: LossRunsInformation;
    providerNumber: string;
    onChange: (changes: LossRunsInformation) => void;
    onRemove: () => void;
};

export const LossRunsItem = ({ data, providerNumber, onChange, onRemove }: Props) => {
    const [state, setState] = useState<LossRunsInformation>(data);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [dialog, setDialog] = useState<boolean>(false);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { id } = useParams();

    const handleFieldChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
        const { name, value } = event.target;

        setState({
            ...state,
            [name]: value,
        });
    };

    const handleDateRange = (value: DateRange<Date>) => {
        setState({
            ...state,
            effective_start_date: value[0] == null ? undefined : format(value[0], 'yyyy-MM-dd'),
            effective_end_date: value[1] == null ? undefined : format(value[1], 'yyyy-MM-dd'),
        });
    };

    const handleLossRunStatus: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
        const { value } = event.target;
        const newStatus: 'Loss Runs Provided' | 'No Loss Runs Provided' | 'No Loss Runs Available' =
            value === 'Yes'
                ? 'Loss Runs Provided'
                : value === 'No'
                ? 'No Loss Runs Available'
                : 'No Loss Runs Provided';

        setState({
            ...state,
            status: newStatus,
            amounts: newStatus == 'Loss Runs Provided' ? state.amounts : [],
            total_amount: newStatus == 'Loss Runs Provided' ? state.total_amount : 0,
        });
    };

    const handleAmountArray = (value: number[]) => {
        const newTotal = value.length > 0 ? value.reduce((a: number, b: number) => a + b) : 0;

        setState({
            ...state,
            amounts: value,
            total_amount: newTotal,
        });
    };

    const checkForChanges = (a: LossRunsInformation, b: LossRunsInformation): boolean => {
        if (a.id !== b.id) return false;
        if (a.application_id !== b.application_id) return false;
        if (a.facility_provider_number !== b.facility_provider_number) return false;
        if (a.carrier_name !== b.carrier_name) return false;
        if (a.effective_start_date !== b.effective_start_date) return false;
        if (a.effective_end_date !== b.effective_end_date) return false;
        if (a.status !== b.status) return false;
        if (a.total_amount !== b.total_amount) return false;

        if (a.amounts && b.amounts) {
            if (a.amounts.length !== b.amounts.length) return false;
            for (let i = 0; i < a.amounts.length; i++) {
                if (a.amounts[i] !== b.amounts[i]) return false;
            }
        } else if (a.amounts || b.amounts) {
            return false;
        }

        return true;
    };

    const handleSave = useCallback(async () => {
        try {
            setLoading(true);
            if (state.id) {
                const { data } = await orionApiUrl.patch<LossRunsInformation>(
                    `/v1/claim/${state.id}`,
                    {
                        id: state.id,
                        facility_provider_number: state.facility_provider_number ?? providerNumber,
                        application_id: id,
                        carrier_name: state.carrier_name,
                        status: state.status,
                        effective_start_date: state.effective_start_date,
                        effective_end_date: state.effective_end_date,
                        amounts: state.amounts ?? [],
                        total_amount: state.total_amount,
                    },
                    setHeaders(),
                );

                onChange(data);
                setLoading(false);

                return;
            } else {
                const { data } = await orionApiUrl.post<LossRunsInformation>(
                    '/v1/claim/',
                    {
                        application_id: id,
                        facility_provider_number: providerNumber,
                        carrier_name: state.carrier_name,
                        status: state.status,
                        effective_start_date: state.effective_start_date,
                        effective_end_date: state.effective_end_date,
                        amounts: state.amounts ?? [],
                        total_amount: state.total_amount,
                    },
                    setHeaders(),
                );

                onChange(data);
                setLoading(false);

                return;
            }
        } catch (error) {
            handleError(error, () => {
                setLoading(false);
                console.log(JSON.stringify(error, null, 2));
            });
        }
    }, [id, state]);

    useEffect(() => {
        setState(data);
    }, [data]);

    useEffect(() => {
        const isTheSame = checkForChanges(data, state);
        const isCarrierEmpty = state.carrier_name == null;
        const hasNullDates = state.effective_start_date == null || state.effective_end_date == null;

        setHasChanges(!isTheSame && !isCarrierEmpty && !hasNullDates);
    }, [data, state]);

    return (
        <Accordion
            expanded={expanded}
            sx={{ border: '1px solid #393E46', borderRadius: '5px', padding: '12px', position: 'inherit' }}
            onChange={() => {
                setExpanded(!expanded);
            }}
        >
            <AccordionSummary>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                    <Stack direction="row" spacing={2}>
                        <Typography>{state.id ? 'Loss Runs' : 'New Loss Runs'}</Typography>
                        <Typography sx={{ color: 'text.disabled' }}>
                            {state.effective_start_date && state.effective_end_date
                                ? `(${format(parseISO(state.effective_start_date), 'MM/dd/yyyy')}-${format(
                                      parseISO(state.effective_end_date),
                                      'MM/dd/yyyy',
                                  )})`
                                : '--/--/--'}
                        </Typography>
                    </Stack>
                    <IconButton size="small" sx={{ color: '#B0B0B0' }} onClick={() => {}}>
                        {!expanded && <ExpandMoreIcon />}
                        {expanded && <ExpandLessIcon />}
                    </IconButton>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={2}>
                    <SimpleInput
                        smallField
                        type="none"
                        label="Carrier"
                        id="carrier_name"
                        value={state.carrier_name}
                        width="100%"
                        onChange={handleFieldChange}
                    />
                    <SimpleDateRangeInput
                        value={[state.effective_start_date, state.effective_end_date]}
                        bold={false}
                        smallField
                        label="Effective Date Range"
                        width="100%"
                        onChange={handleDateRange}
                    />
                    <SimpleDropdownInput
                        bold={false}
                        smallField
                        label="Were there losses for this policy?"
                        id="include_loss_runs"
                        value={
                            state.status === 'Loss Runs Provided'
                                ? 'Yes'
                                : state.status === 'No Loss Runs Available'
                                ? 'No'
                                : 'No LR Provided'
                        }
                        options={['Yes', 'No', 'No LR Provided']}
                        width="100%"
                        onChange={handleLossRunStatus}
                    />
                    {state.status === 'Loss Runs Provided' && (
                        <TagInput
                            smallField
                            label="Losses Incurred"
                            value={state.amounts ? state.amounts : []}
                            onChange={handleAmountArray}
                        />
                    )}
                    {state.status === 'Loss Runs Provided' && (
                        <SimpleInput
                            bold={false}
                            readOnly
                            smallField
                            type="currency"
                            label="Total Incurred"
                            id="total_amount"
                            value={state.total_amount}
                            width="75%"
                            onChange={handleFieldChange}
                        />
                    )}
                    <Stack width="100%" spacing={2} direction="row" alignContent="center" justifyContent="flex-end">
                        {!loading && (
                            <Button sx={{ margingY: '24px' }} onClick={() => setDialog(true)}>
                                <Typography fontWeight={500} color={'text.danger'}>
                                    REMOVE
                                </Typography>
                            </Button>
                        )}
                        {hasChanges && (
                            <Button
                                disabled={loading}
                                variant="contained"
                                sx={{ margingY: '24px' }}
                                onClick={handleSave}
                            >
                                {loading && <CircularProgress color="primary" size={20} />}
                                {!loading && <Typography fontWeight={500}>SAVE</Typography>}
                            </Button>
                        )}
                    </Stack>
                </Stack>
                <Dialog
                    open={dialog}
                    onClose={() => setDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Remove Loss Runs</DialogTitle>
                    <DialogContent>
                        <Typography>Are you sure you want to remove these loss runs?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDialog(false)}>NO</Button>
                        <Button
                            onClick={() => {
                                onRemove();
                                setDialog(false);
                            }}
                            autoFocus
                        >
                            YES
                        </Button>
                    </DialogActions>
                </Dialog>
            </AccordionDetails>
        </Accordion>
    );
};
