import { FacilityDataPoint, FacilityIndex } from 'types/underwriter/underwriter.type';
import { compareDesc, parseISO } from 'date-fns';

import { FacilityTrendResponse } from '../../types/underwriter.types';
import { IndexTable } from '../index-table.component';
import { useMemo } from 'react';

type Props = {
    trendData: FacilityTrendResponse[];
};

export const OccupancyTrends = ({ trendData }: Props) => {
    const occupancyData: FacilityIndex[] = useMemo(() => {
        if (trendData.length <= 0) return [];
        const indexes: FacilityIndex[] = [];
        const indexPoints: FacilityDataPoint[][] = [[], [], []];

        trendData.forEach(trend => {
            indexPoints[0].push({ value: Number(trend.index_occupancy.toFixed(2)), date: trend.filedate });
            indexPoints[1].push({
                value: Number(trend.index_number_of_certified_beds.toFixed(2)),
                date: trend.filedate,
            });
            indexPoints[2].push({
                value: Number(trend.index_average_number_of_residents_per_day.toFixed(2)),
                date: trend.filedate,
            });
        });

        indexPoints[0].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[1].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
        indexPoints[2].sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));

        indexes.push(
            {
                label: 'Index Ocupancy',
                tooltip:
                    'The occupancy as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[0],
            },
            {
                label: 'Index Number of Certified Beds',
                tooltip:
                    'The number of certified beds as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[1],
            },
            {
                label: 'Index Average Number of Residents per Day',
                tooltip:
                    'The average number of residents per day as reported by CMS, indexed against the average facility in the same state and geography class (urban or rural). 1.0 is represents an average value, 2.0 is twice the average, and 0.5 is half the average.',
                data: indexPoints[2],
            },
        );

        return indexes;
    }, [trendData]);

    return <IndexTable title="Occupancy" indexes={occupancyData} />;
};
