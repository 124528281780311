import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Stack, Typography } from '@mui/material';
import { ApplicationData, ApplicationQuoteSummary } from '../application-details-page.component';
import {
    ApplicationLimitsComponent,
    LimitsInformation,
    extractLimitsInformation,
} from './modules/limits-liability.component';
import { ApplicationPolicyComponent, PolicyInformation, extractPolicyInformation } from './modules/policy.component';
import { ApplicationScheduleComponent, ScheduleInformation } from './modules/schedule.component';
import { orionApiUrl, setHeaders } from 'util/api/api.util';
import { useEffect, useState } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LossessInformation } from './modules/narrative-losses.component';
import SaveIcon from '@mui/icons-material/Save';
import { handleError } from 'util/error/handleError';
import { useParams } from 'react-router-dom';

type Props = {
    data: ApplicationData;
    setLoadingState: (loadingState: boolean, hintIndicator: string) => void;
    onChange: () => void;
};

export const PolicyApplicationAccordion = ({ data, setLoadingState, onChange }: Props) => {
    const [open, setOpen] = useState<boolean>(false);

    const [policy, setPolicy] = useState<PolicyInformation>();
    const [schedules, setSchedules] = useState<ScheduleInformation[]>([]);

    const { id, facility_id } = useParams();

    useEffect(() => {
        setPolicy(extractPolicyInformation(data));
        setSchedules([...data.schedules]);
    }, [data, facility_id]);

    const updateData = async () => {
        try {
            setLoadingState(true, 'Saving Changes');

            const updatedSchedules: ScheduleInformation[] = [];

            for (const schedule of schedules) {
                if (schedule.id) {
                    const { data: patchData } = await orionApiUrl.patch<ScheduleInformation>(
                        `/v1/schedule/${schedule.id}`,
                        {
                            id: schedule.id,
                            application_id: id,
                            name: schedule.name ? schedule.name : 'N/A',
                            address: schedule.address ? schedule.address : 'N/A',
                        },
                        setHeaders(),
                    );

                    updatedSchedules.push(patchData);
                    continue;
                }

                const { data } = await orionApiUrl.post<ScheduleInformation>(
                    '/v1/schedule/',
                    {
                        application_id: id,
                        name: schedule.name ? schedule.name : 'N/A',
                        address: schedule.address ? schedule.address : 'N/A',
                    },
                    setHeaders(),
                );

                updatedSchedules.push(data);
            }

            const { data: patchedData } = await orionApiUrl.patch<ApplicationData>(
                `/v1/application/${id}`,
                {
                    ...policy,
                },
                setHeaders(),
            );

            setLoadingState(false, '');
            onChange();
        } catch (error) {
            handleError(error, () => {
                setLoadingState(false, '');
                console.log(JSON.stringify(error, null, 2));
            });
        }
    };

    return (
        <Grid item xs={12}>
            <Accordion
                expanded={open}
                sx={{
                    borderRadius: '5px',
                    padding: 0,
                    backgroundColor: 'background.default',
                    width: '100%',
                }}
            >
                <AccordionSummary>
                    <Stack direction="row" spacing={2} sx={{ width: '100%' }} alignItems="center">
                        <Stack
                            direction="column"
                            onClick={() => {
                                setOpen(!open);
                            }}
                        >
                            <Typography variant="h6">Policy</Typography>
                            {!open && <Typography>Policy information and endorsements</Typography>}
                        </Stack>
                        <Box
                            onClick={() => {
                                setOpen(!open);
                            }}
                            sx={{ flexGrow: 1, height: '100%' }}
                        />
                        <Typography
                            sx={{
                                color: '#EEEEEE',
                            }}
                            onClick={() => {
                                setOpen(!open);
                            }}
                        >
                            {!open && <ExpandMoreIcon />}
                            {open && <ExpandLessIcon />}
                        </Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        {policy && <ApplicationPolicyComponent data={policy} onChange={setPolicy} />}
                        <ApplicationScheduleComponent data={schedules} onChange={setSchedules} />
                        <Grid item xs={12}>
                            <Stack direction="row" sx={{ width: '100%' }} justifyContent="center" spacing={2}>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    onClick={updateData}
                                    sx={{ backgroundColor: 'primary.main' }}
                                >
                                    <Stack direction="row" alignItems="center">
                                        <SaveIcon sx={{ mr: 1 }} />
                                        <Typography sx={{ fontWeight: 500 }}>SAVE</Typography>
                                    </Stack>
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};
