import { ApplicationSummary, FacilitySummary } from '../application-page.component';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { ApplicationRow } from './application-row.component';
import { SubContainer } from 'components/general/layout/sub-container.component';
import { tableCellClasses } from '@mui/material/TableCell';
import { useState } from 'react';

interface Props {
    data: ApplicationSummary[];
    canLoadMore: boolean;
    onLoadMore: () => void;
}

export const ApplicationsTable = ({ data, canLoadMore, onLoadMore }: Props) => {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <SubContainer>
            <TableContainer>
                <Table
                    sx={{
                        [`& .${tableCellClasses.root}`]: {
                            borderBottom: 'none',
                        },
                    }}
                >
                    <TableHead>
                        <TableRow sx={{ '& > *': { border: 'none' } }}>
                            <TableCell>Brokerage</TableCell>
                            <TableCell>Agent</TableCell>
                            <TableCell>Renewal Date</TableCell>
                            <TableCell>Insured</TableCell>
                            <TableCell>Combined Quote</TableCell>
                            <TableCell>Combined Total Cost</TableCell>
                            <TableCell>Submitted On</TableCell>
                            <TableCell>Historical LR%</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((application: ApplicationSummary, index: number) => (
                            <ApplicationRow alt={index % 2 == 0} key={index} data={application} />
                        ))}
                        {canLoadMore && (
                            <TableRow>
                                <TableCell colSpan={12} align="center">
                                    <Box
                                        sx={{
                                            '&:hover': {
                                                cursor: 'pointer',
                                            },
                                        }}
                                        onClick={onLoadMore}
                                    >
                                        <Typography>Load More</Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </SubContainer>
    );
};
