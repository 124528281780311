import { Box, SxProps, Theme } from '@mui/material';

import { ReactNode } from 'react';

type Props = {
    children: ReactNode;
    minimal?: boolean;
    sx?: SxProps<Theme>;
};

export const SubContainer = ({ children, minimal, sx }: Props) => {
    return (
        <Box
            sx={{
                borderRadius: '5px',
                padding: { xs: '10px' },
                overflowX: 'auto',
                border: minimal ? '1px solid' : '0px none',
                borderColor: 'primary.gray',
                backgroundColor: 'background.paper',
                ...sx,
            }}
            width="100%"
        >
            {children}
        </Box>
    );
};
