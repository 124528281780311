import { Backdrop, Box, Button, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

import { InputFieldSelect } from 'components/pages/underwriter/facility-module/quote-dialogue/inputs/field-select.component';
import { LossRunsInformation } from './narrative-losses.component';
import { LossRunsTrend } from 'components/pages/applications/specific-components/loss-runs-trend.component';
import { PriceMatrixComponent } from './price-matrix.component';
import { QuoteSummary } from 'components/pages/underwriter/facility-module/quote-dialogue/quote-stepper.component';
import { SimpleCheckboxInput } from '../../../add-application/field-components/simple-checkbox-input.component';
import { SimpleDateInput } from '../../../add-application/field-components/simple-date-input.component';
import { SimpleDropdownInput } from '../../../add-application/field-components/simple-dropdown-input.component';
import { SimpleInput } from '../../../add-application/field-components/simple-input.component';
import { SubContainer } from 'components/general/layout/sub-container.component';
import { ValuePercentageInput } from 'components/pages/applications/add-application/field-components/value-percentage-input.component';

export type QuoteInformation = {
    facility_state_code?: string;
    //Loss runs summary info
    prior_year_number_of_incidents?: number;
    prior_year_number_of_claims?: number;
    prior_year_total_settlement_amount?: number;
    prior_year_average_settlement_amount?: number;
    first_claim_date?: string;
    number_of_years_with_incidents?: number;
    total_settlement_amount?: number;
    total_number_of_claims?: number;
    average_settlement_amount?: number;
    max_settlement_amount?: number;
    max_number_of_claims_per_year?: number;
    //Quote info
    imputed_loss_ratio?: number;
    overhead?: number;
    overhead_rate?: number;
    quoted_rate?: number;
    premium?: number;
    quoted_rate_per_bed?: number;
    manual_debit?: number;
    mcare_fee?: number;
    mcare_fee_rate?: number;
    state_tax?: number;
    state_tax_rate?: number;
    stamp_fee?: number;
    stamp_fee_rate?: number;
    technology_fee?: number;
    tria_fee?: number;
    tria_fee_rate?: number;
    mga_fee?: number;
    mga_fee_rate?: number;
    fronting_fee?: number;
    fronting_fee_rate?: number;
    broker_fee?: number;
    broker_fee_rate?: number;
    accelerant_fee?: number;
    accelerant_fee_rate?: number;
    margin?: number;
    margin_rate?: number;
    include_loss_runs?: boolean;
    bed_type?: string;
    corridor_deductible?: number;
    specific_deductible?: number;
    expiring_deductible?: number;
    expiring_quote?: number;
    policy_year_summary?: PolicyTrendObject[];
};

export type PolicyTrendObject = {
    policy_year: number;
    premium_paid: number | null;
    losses_incurred: number | null;
    losses_incurred_over_deductible: number | null;
    is_provided: boolean;
    imputed_loss_ratio: number | null;
};

export function extractQuoteInformation(data: unknown): QuoteInformation {
    const applicantInformation: Partial<QuoteInformation> = {};
    const validKeys: Array<keyof QuoteInformation> = [
        'facility_state_code',
        'prior_year_number_of_incidents',
        'prior_year_number_of_claims',
        'prior_year_total_settlement_amount',
        'prior_year_average_settlement_amount',
        'first_claim_date',
        'number_of_years_with_incidents',
        'total_settlement_amount',
        'total_number_of_claims',
        'average_settlement_amount',
        'max_settlement_amount',
        'max_number_of_claims_per_year',
        'imputed_loss_ratio',
        'overhead',
        'overhead_rate',
        'quoted_rate',
        'premium',
        'quoted_rate_per_bed',
        'manual_debit',
        'mcare_fee',
        'mcare_fee_rate',
        'stamp_fee',
        'stamp_fee_rate',
        'state_tax',
        'state_tax_rate',
        'technology_fee',
        'tria_fee',
        'tria_fee_rate',
        'mga_fee',
        'mga_fee_rate',
        'fronting_fee',
        'fronting_fee_rate',
        'broker_fee',
        'broker_fee_rate',
        'accelerant_fee',
        'accelerant_fee_rate',
        'margin',
        'margin_rate',
        'include_loss_runs',
        'bed_type',
        'corridor_deductible',
        'specific_deductible',
        'expiring_deductible',
        'expiring_quote',
        'policy_year_summary',
    ];

    if (typeof data === 'object' && data !== null) {
        for (const key of validKeys) {
            if (key in data) {
                if (key.includes('rate')) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'unknown'.
                    applicantInformation[key] = typeof data[key] == 'number' ? data[key].toFixed(4) : data[key];
                } else {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'unknown'.
                    applicantInformation[key] = typeof data[key] == 'number' ? data[key].toFixed(2) : data[key];
                }
            }
        }
    }

    return applicantInformation as QuoteInformation;
}

type Props = {
    facilityName: string;
    providerNumber: string;
    data: QuoteInformation;
    claims: LossRunsInformation[];
    onChange: (changes: QuoteInformation) => void;
    onPriceApplied: (
        price: number,
        lr_included: boolean,
        bed_type: string,
        premium: number,
        lossRatio: number,
        manualDebit: number,
    ) => void;
};

export const ApplicationQuoteComponent = ({
    facilityName,
    providerNumber,
    data,
    claims,
    onChange,
    onPriceApplied,
}: Props) => {
    const [open, setOpen] = useState<boolean>(false);
    const [premium, setPremium] = useState<number>(0);

    const handleFieldChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
        const { name, value } = event.target;
        const parsedValue = value.trim().length <= 0 ? null : value;

        onChange({
            ...data,
            [name]: parsedValue,
        });
    };

    const handleCheckBoxChange: React.ChangeEventHandler<HTMLInputElement> = event => {
        const { name, checked } = event.target;

        onChange({
            ...data,
            [name]: checked,
        });
    };

    const handlePremiumChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
        const { name, value } = event.target;

        onChange({
            ...data,
            [name]: value,
            premium: +(value || 0) + +(data.quoted_rate || 0),
        });
    };

    const handlePercentageChange = (key: string, amountKey: string, value: string) => {
        onChange({
            ...data,
            [key]: value,
        });
    };

    useEffect(() => {
        setPremium(+(data.manual_debit || 0) + +(data.quoted_rate || 1));
        onChange({
            ...data,
            premium: premium,
        });
    }, [data.quoted_rate, data.manual_debit]);

    useEffect(() => {
        //const margin = premium * (data.margin_rate || 0);
        const brokerFee = premium * (data.broker_fee_rate || 0);
        const mga = premium * (data.mga_fee_rate || 0);
        const stateTax = premium * (data.state_tax_rate || 0);
        const stampFee = premium * (data.stamp_fee_rate || 0);
        const mcare = premium * (data.mcare_fee_rate || 0);
        const tria = premium * (data.tria_fee_rate || 0);

        onChange({
            ...data,
            //margin: margin,
            broker_fee: brokerFee,
            mga_fee: mga,
            state_tax: stateTax,
            stamp_fee: stampFee,
            mcare_fee: mcare,
            tria_fee: tria,
            technology_fee: data.technology_fee,
        });
    }, [
        premium,
        data.margin_rate,
        data.broker_fee_rate,
        data.mga_fee_rate,
        data.state_tax_rate,
        data.stamp_fee_rate,
        data.mcare_fee_rate,
        data.tria_fee_rate,
        data.technology_fee,
    ]);

    const total_cost = useMemo(() => {
        const value: number =
            premium +
            +(data.state_tax || 0) +
            +(data.stamp_fee || 0) +
            +(data.technology_fee || 0) +
            (data.facility_state_code === 'PA' ? +(data.mcare_fee || 0) : 0);

        return Math.round(value * 100) / 100;
    }, [data.state_tax, data.stamp_fee, data.technology_fee, data.facility_state_code, data.mcare_fee]);

    return (
        <>
            <PriceMatrixComponent
                open={open}
                quoteData={data}
                claims={claims}
                providerNumber={providerNumber}
                onClose={() => {
                    document.body.style.overflow = 'unset';
                    setOpen(false);
                }}
                onPriceApplied={(price, lr, bedType, premium, lossRatio, manualDebit) => {
                    setOpen(false);
                    onPriceApplied(price, lr, bedType, premium, lossRatio, manualDebit);
                }}
            />
            <Grid item lg={6} xs={12}>
                <SubContainer sx={{ padding: '20px 30px' }}>
                    <Stack direction="column" spacing={1}>
                        <Stack direction="row" alignItems="center" alignContent="center">
                            <Typography variant="h6" height="100%" sx={{ flexGrow: 1 }}>
                                Quote
                                {/* <Typography display="inline">
                                    {`  - Expires ${
                                        data.quote && data.quote.expiration_date != null
                                            ? format(parseISO(data.quote.expiration_date), 'dd/MM/yyyy')
                                            : '--/--/--'
                                    }`}
                                </Typography> */}
                            </Typography>
                            <Button sx={{ margingY: '24px' }}>
                                <Typography
                                    fontWeight={500}
                                    onClick={() => {
                                        if (typeof window != undefined && window.document) {
                                            document.body.style.overflow = 'hidden';
                                        }
                                        setOpen(true);
                                    }}
                                >
                                    VIEW MATRIX
                                </Typography>
                            </Button>
                        </Stack>
                        <Grid container gap={5} sx={{ paddingTop: '12px' }}>
                            <Grid item xs={12}>
                                <Stack direction="column" spacing={1}>
                                    <Typography sx={{ fontWeight: 500 }}> {facilityName}</Typography>
                                    <SimpleCheckboxInput
                                        bold={false}
                                        label="Include Loss Runs"
                                        id="include_loss_runs"
                                        value={data.include_loss_runs}
                                        width="75%"
                                        onChange={handleCheckBoxChange}
                                    />
                                    <SimpleDropdownInput
                                        smallField
                                        bold={false}
                                        label="Bed Type"
                                        id="bed_type"
                                        value={data.bed_type == null ? 'placeholder' : data.bed_type}
                                        options={['Licensed', 'Occupied']}
                                        onChange={handleFieldChange}
                                        width="92%"
                                        start_spacing="20%"
                                        end_spacing="50%"
                                    />
                                    <SimpleInput
                                        bold={false}
                                        smallField
                                        rightAlign
                                        type="currency"
                                        label="Specific Deductible"
                                        id="specific_deductible"
                                        value={data.specific_deductible}
                                        onChange={handleFieldChange}
                                        width="92%"
                                        start_spacing="20%"
                                        end_spacing="50%"
                                    />
                                    <SimpleInput
                                        bold={false}
                                        smallField
                                        rightAlign
                                        type="currency"
                                        label="Corridor Deductible"
                                        id="corridor_deductible"
                                        value={data.corridor_deductible}
                                        onChange={handleFieldChange}
                                        width="92%"
                                        start_spacing="20%"
                                        end_spacing="50%"
                                    />
                                    <SimpleInput
                                        bold={false}
                                        smallField
                                        readOnly
                                        rightAlign
                                        type="percentage"
                                        label="Quoted Loss Ratio %"
                                        id="imputed_loss_ratio"
                                        value={data.imputed_loss_ratio}
                                        width="70%"
                                        onChange={handleFieldChange}
                                    />
                                    <SimpleInput
                                        bold={false}
                                        smallField
                                        readOnly
                                        rightAlign
                                        type="currency"
                                        label="Quoted Price/Bed"
                                        id="quoted_rate_per_bed"
                                        value={data.quoted_rate_per_bed}
                                        width="70%"
                                        onChange={handleFieldChange}
                                    />
                                    <SimpleInput
                                        bold={false}
                                        blue
                                        smallField
                                        readOnly
                                        rightAlign
                                        type="currency"
                                        label="Quoted Rate (tax/fees excluded)"
                                        id="quoted_rate"
                                        value={premium}
                                        width="70%"
                                        onChange={handleFieldChange}
                                    />
                                    <ValuePercentageInput
                                        smallField
                                        bold={false}
                                        label="Broker Fee"
                                        percentageId="broker_fee_rate"
                                        valueType="currency"
                                        percentageType="percentage"
                                        value={data.broker_fee}
                                        percentageValue={data.broker_fee_rate}
                                        onPercentageChange={handleFieldChange}
                                        onPercentageMaskedChange={(key, value) => {
                                            handlePercentageChange(key, 'broker_fee', value);
                                        }}
                                    />
                                    <ValuePercentageInput
                                        smallField
                                        bold={false}
                                        label="MGA Fee"
                                        percentageId="mga_fee_rate"
                                        valueType="currency"
                                        percentageType="percentage"
                                        value={data.mga_fee}
                                        percentageValue={data.mga_fee_rate}
                                        onPercentageChange={handleFieldChange}
                                        onPercentageMaskedChange={(key, value) => {
                                            handlePercentageChange(key, 'mga_fee', value);
                                        }}
                                    />
                                    <ValuePercentageInput
                                        smallField
                                        bold={false}
                                        label="State Tax"
                                        percentageId="state_tax_rate"
                                        valueType="currency"
                                        percentageType="percentage"
                                        value={data.state_tax}
                                        percentageValue={data.state_tax_rate}
                                        onPercentageChange={handleFieldChange}
                                        onPercentageMaskedChange={(key, value) => {
                                            handlePercentageChange(key, 'state_tax', value);
                                        }}
                                    />
                                    <ValuePercentageInput
                                        smallField
                                        bold={false}
                                        label="Stamp Fee Tax"
                                        percentageId="stamp_fee_rate"
                                        valueType="currency"
                                        percentageType="percentage"
                                        value={data.stamp_fee}
                                        percentageValue={data.stamp_fee_rate}
                                        onPercentageChange={handleFieldChange}
                                        onPercentageMaskedChange={(key, value) => {
                                            handlePercentageChange(key, 'stamp_fee', value);
                                        }}
                                    />
                                    {data.facility_state_code === 'PA' && (
                                        <ValuePercentageInput
                                            smallField
                                            bold={false}
                                            label="Mcare (PA only)"
                                            percentageId="mcare_fee_rate"
                                            valueType="currency"
                                            percentageType="percentage"
                                            value={data.mcare_fee}
                                            percentageValue={data.mcare_fee_rate}
                                            onPercentageChange={handleFieldChange}
                                            onPercentageMaskedChange={(key, value) => {
                                                handlePercentageChange(key, 'mcare_fee', value);
                                            }}
                                        />
                                    )}
                                    <SimpleInput
                                        bold={false}
                                        smallField
                                        rightAlign
                                        type="currency"
                                        label="Technology Fee"
                                        id="technology_fee"
                                        value={data.technology_fee}
                                        onChange={handleFieldChange}
                                        width="92%"
                                        start_spacing="20%"
                                        end_spacing="50%"
                                    />
                                    <SimpleInput
                                        bold={false}
                                        smallField
                                        rightAlign
                                        type="currency"
                                        label="Manual Debit"
                                        id="manual_debit"
                                        value={data.manual_debit}
                                        onChange={handlePremiumChange}
                                        width="92%"
                                        start_spacing="20%"
                                        end_spacing="50%"
                                    />
                                    <ValuePercentageInput
                                        smallField
                                        bold={false}
                                        label="TRIA (not included in quote or total)"
                                        percentageId="tria_fee_rate"
                                        valueType="currency"
                                        percentageType="percentage"
                                        value={data.tria_fee}
                                        percentageValue={data.tria_fee_rate}
                                        onPercentageChange={handleFieldChange}
                                        onPercentageMaskedChange={(key, value) => {
                                            handlePercentageChange(key, 'tria_fee', value);
                                        }}
                                    />
                                    <SimpleInput
                                        readOnly
                                        bold={true}
                                        smallField
                                        rightAlign
                                        type="currency"
                                        label="Total Cost (tax/fees included)"
                                        id="rate_quoted"
                                        value={total_cost}
                                        width="70%"
                                        onChange={handleFieldChange}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h6">Loss Runs Summary</Typography>
                                    <LossRunsTrend policyData={data.policy_year_summary} />
                                    <SimpleInput
                                        readOnly
                                        bold={false}
                                        smallField
                                        rightAlign
                                        type="numeric"
                                        label="# Incident of Years"
                                        id="number_of_years_with_incidents"
                                        value={data.number_of_years_with_incidents}
                                        width="70%"
                                        onChange={handleFieldChange}
                                    />
                                    <SimpleInput
                                        readOnly
                                        bold={false}
                                        smallField
                                        rightAlign
                                        type="currency"
                                        label="Prior Year Total Paid"
                                        id="prior_year_total_settlement_amount"
                                        value={data.prior_year_total_settlement_amount}
                                        width="70%"
                                        onChange={handleFieldChange}
                                    />
                                    <SimpleInput
                                        readOnly
                                        bold={false}
                                        smallField
                                        rightAlign
                                        type="numeric"
                                        label="Prior Year Number Claim"
                                        id="prior_year_number_of_claims"
                                        value={data.prior_year_number_of_claims}
                                        width="70%"
                                        onChange={handleFieldChange}
                                    />
                                    <SimpleInput
                                        readOnly
                                        bold={false}
                                        smallField
                                        rightAlign
                                        type="currency"
                                        label="Avg. Amount Paid Anually"
                                        id="average_settlement_amount"
                                        value={
                                            data.average_settlement_amount === null ? 0 : data.average_settlement_amount
                                        }
                                        width="70%"
                                        onChange={handleFieldChange}
                                    />
                                    <SimpleInput
                                        readOnly
                                        bold={false}
                                        smallField
                                        rightAlign
                                        type="currency"
                                        label="Max Amount Paid"
                                        id="max_settlement_amount"
                                        value={data.max_settlement_amount}
                                        width="70%"
                                        onChange={handleFieldChange}
                                    />
                                    <SimpleInput
                                        readOnly
                                        bold={false}
                                        smallField
                                        rightAlign
                                        type="numeric"
                                        label="Max # of Claims Per Year"
                                        id="max_number_of_claims_per_year"
                                        value={data.max_number_of_claims_per_year}
                                        width="70%"
                                        onChange={handleFieldChange}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                </SubContainer>
            </Grid>
        </>
    );
};
