import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { UserResponse } from 'types/user.interface';

export type AuthState = {
    user: UserResponse | null;
    loading: boolean;
};

const initialState: AuthState = {
    user: null,
    loading: true,
};

export const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authLogin: (state: AuthState, action: PayloadAction<UserResponse>) => {
            return {
                ...state,
                user: action.payload,
            };
        },
        authLogout: (state: AuthState) => {
            return {
                ...state,
                user: null,
            };
        },
        authStopLoading: (state: AuthState) => {
            return {
                ...state,
                loading: false,
            };
        },
    },
});

export const AuthSliceReducer = AuthSlice.reducer;
export const { authLogin, authLogout, authStopLoading } = AuthSlice.actions;
