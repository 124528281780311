import { Box, SxProps, Theme } from '@mui/material';

import { ReactNode } from 'react';

type Props = {
    children: ReactNode;
    sx?: SxProps<Theme>;
};

export const Container = ({ children, sx }: Props) => {
    return (
        <Box
            sx={{
                borderRadius: '5px',
                padding: { xs: '10px', md: '30px 34px' },
                overflowX: 'auto',
                ...sx,
            }}
            width="100%"
            bgcolor="background.default"
        >
            {children}
        </Box>
    );
};
