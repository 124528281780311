import { Fragment, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

export const HomePage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/applications');
    }, [navigate]);

    return <Fragment></Fragment>;
};
