/* eslint-disable indent */

import {
    AgentInfo,
    AgentSearchInput,
    AgentSearchInputRef,
} from './add-application/field-components/agent-search-input.component';
import { Autocomplete, Button, Grid, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import {
    BrokerInfo,
    BrokerSearchInput,
    BrokerSearchInputRef,
} from './add-application/field-components/broker-search-input.component';
import { FacilityData, StateData, StateFacilitySummary } from 'types/underwriter/underwriter.type';
import { FacilityLocationDetails, StateFacility } from '../underwriter/types/underwriter.types';
import { format, parseISO } from 'date-fns';
import { orionApiUrl, setHeaders } from 'util/api/api.util';
import { useCallback, useEffect, useRef, useState } from 'react';

import { AcceptanceStatusSelector } from './specific-components/acceptance-status.component';
import AddIcon from '@mui/icons-material/Add';
import { ApplicationsTable } from './applications-list/application-table.component';
import { Container } from 'components/general/layout/container.component';
import { DateRangeEntry } from './application-details-page/accordions/modules/policy.component';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { FacilitySearchBar } from '../underwriter/facility-search.component';
import { InputFieldSelect } from '../underwriter/facility-module/quote-dialogue/inputs/field-select.component';
import { LoadingIndicator } from 'components/general/loading/loading-indicator.component';
import { PageTitle } from 'components/general/layout/page-title.component';
import SearchIcon from '@mui/icons-material/Search';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { SubContainer } from 'components/general/layout/sub-container.component';
import { SubmissionStatusSelector } from './specific-components/submission-status.component';
import { UsState } from 'util/api/state-map.util';
import { authLogout } from 'store/features/auth/auth.slice';
import { handleError } from 'util/error/handleError';
import { useAppDispatch } from 'store/hooks/store.hook';
import { useDebounce } from 'hooks/useDebounce';
import { useNavigate } from 'react-router';

export type QuoteSummary = {
    total_quoted_rate: number | null;
    total_state_tax: number | null;
    total_premium: number | null;
    total_overhead: number | null;
    total_accelerant_fee: number | null;
    total_fronting_fee: number | null;
    total_margin: number | null;
    total_tria_fee: number | null;
    total_technology_fee: number | null;
    total_broker_fee: number | null;
    total_mga_fee: number | null;
    total_manual_debit: number | null;
    total_stamp_fee: number | null;
    is_needs_quote: boolean;
    overhead_rate: number | null;
    accelerant_fee_rate: number | null;
    fronting_fee_rate: number | null;
    margin_rate: number | null;
    tria_fee_rate: number | null;
    technology_fee_rate: number | null;
    broker_fee_rate: number | null;
    mga_fee_rate: number | null;
    stamp_fee_rate: number | null;
};

export type FacilitySummary = {
    id: number;
    application_id: number | null;
    submission_date: string | null;
    renewal_date: string | null;
    retroactive_date: string | null;
    submission_status: string | null;
    insured_name: string | null;
    insured_address: string | null;
    brokerage_name: string | null;
    brokerage_address: string | null;
    agent_id: string | null;
    agent_name: string | null;
    agent_email: string | null;
    facility_name: string | null;
    facility_address: string | null;
    facility_provider_number: string | null;
    facility_beds_licensed: number | null;
    facility_beds_occupied: number | null;
    facility_rating: number | null;
    specific_deductible: number | null;
    corridor_deductible: number | null;
    expiring_quote: number | null;
    expiring_deductible: number | null;
    quoted_rate: number | null;
    premium: number | null;
    state_tax: number | null;
    state_tax_rate: number | null;
    tria_fee: number | null;
    technology_fee: number | null;
    imputed_loss_ratio: number | null;
    overhead: number | null;
    accelerant_fee: number | null;
    fronting_fee: number | null;
    margin: number | null;
    broker_fee: number | null;
    mga_fee: number | null;
    manual_debit: number | null;
    stamp_fee: number | null;
    notes: string | null;
};

export type ApplicationSummary = {
    id: number;
    claim_limit: number | null;
    claim_limit_annual: number | null;
    claim_limit_policy: number | null;
    medical_pay: number | null;
    professional_general_deductible: number | null;
    employee_benefits_deductible: number | null;
    insurance_carrier: string | null;
    credit_rating_agency: string | null;
    policy_rating: string | null;
    policy_form_name: string | null;
    policy_start_date: string | null;
    policy_end_date: string | null;
    policy_endorsements: string[];
    policy_sublimits: string[];
    is_accelerant_referral: boolean | null;
    is_declined: boolean | null;
    declined_reason: string | null;
    notes: string | null;
    facilities: FacilitySummary[];
    quote_summary: QuoteSummary;
};

export const ApplicationsPage = () => {
    const [applications, setApplications] = useState<ApplicationSummary[]>([]);
    const [searchResults, setSearchResults] = useState<ApplicationSummary[]>([]);
    const [canLoadMore, setCanLoadMore] = useState<boolean>(false);
    const [loadOffset, setLoadOffset] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchValue, setSearchValue] = useState<string>('');
    const [status, setStatus] = useState<string>('placeholder');
    const [state, setState] = useState<string>('placeholder');
    const [brokerage, setBrokerage] = useState<string | null>(null);
    const [agent, setAgent] = useState<string>('');
    const [submissionRange, setSubmissionRange] = useState<DateRangeEntry[]>([]);
    const [renewalRange, setRenewalRange] = useState<DateRangeEntry[]>([]);
    const brokerInputRef = useRef<BrokerSearchInputRef>(null);
    const agentInputRef = useRef<AgentSearchInputRef>(null);

    const debouncedSearch = useDebounce(searchValue, 300);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleClearFilter = () => {
        setSearchValue('');
        setStatus('placeholder');
        setRenewalRange([]);
        setSubmissionRange([]);
        setState('placeholder');
        setBrokerage(null);
        setAgent('');
        handleClearBrokerFilter();
        handleClearAgentFilter();
    };

    const fetchData = useCallback(
        async ({ isLoadMore = false, isSearch = false }) => {
            try {
                setLoading(true);
                const loadRange = 10;
                const newOffset = isLoadMore ? loadOffset + loadRange : 0;
                const searchQuery =
                    isSearch && debouncedSearch.trim().length >= 3 ? `search=${debouncedSearch.trim()}&` : '';
                const statusQuery = status !== 'placeholder' ? `status=${status}&` : '';
                const stateQuery = state !== 'placeholder' ? `state=${state}&` : '';
                const brokerageQuery =
                    brokerage != null && brokerage.trim().length > 1 ? `brokerage_id=${brokerage}&` : '';
                const agentQuery = brokerage != null && agent.trim().length > 1 ? `agent_id=${agent}&` : '';
                const submissionQuery =
                    submissionRange != null &&
                    submissionRange.length >= 2 &&
                    submissionRange[0] != null &&
                    submissionRange[1] != null
                        ? `submission_start=${submissionRange[0]}&submission_end=${submissionRange[1]}&`
                        : '';
                const renewalQuery =
                    renewalRange != null &&
                    renewalRange.length >= 2 &&
                    renewalRange[0] != null &&
                    renewalRange[1] != null
                        ? `renewal_start=${renewalRange[0]}&renewal_end=${renewalRange[1]}&`
                        : '';
                const { data } = await orionApiUrl.get<ApplicationSummary[]>(
                    `/v1/application/?${statusQuery}${searchQuery}${brokerageQuery}${agentQuery}${stateQuery}${renewalQuery}${submissionQuery}limit=${loadRange}&offset=${newOffset}`,
                    setHeaders(),
                );

                if (isLoadMore) {
                    setApplications(prev => [...prev, ...data]);
                    setLoadOffset(newOffset);
                } else {
                    isSearch ? setSearchResults(data) : setApplications(data);
                }
                setCanLoadMore(data.length >= loadRange);
                setLoading(false);
            } catch (error) {
                handleError(error, () => {
                    dispatch(authLogout());
                });
            }
        },
        [status, debouncedSearch, submissionRange, renewalRange, brokerage, state, agent, loadOffset],
    );

    const handleClearBrokerFilter = () => {
        if (brokerInputRef.current) brokerInputRef.current.clearInput();
    };

    const handleClearAgentFilter = () => {
        if (agentInputRef.current) agentInputRef.current.clearInput();
    };

    useEffect(() => {
        if (debouncedSearch.trim().length >= 3) {
            fetchData({ isSearch: true });
        } else {
            fetchData({});
        }
    }, [status, submissionRange, renewalRange, agent, brokerage, state, debouncedSearch]);

    useEffect(() => {
        fetchData({});
    }, []);

    return (
        <>
            <Stack direction="row" sx={{ marginBottom: '15px' }}>
                <PageTitle text="Applications" />
                <Box sx={{ flexGrow: '1' }} />
                <Stack sx={{ height: '100%' }} direction="column" alignSelf="center">
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => navigate('./new')}
                        sx={{ backgroundColor: 'primary.main', height: '40px' }}
                        startIcon={<AddIcon />}
                    >
                        <Typography sx={{ fontWeight: '500' }} noWrap>
                            ADD APPLICATION
                        </Typography>
                    </Button>
                </Stack>
            </Stack>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack direction="column" spacing={2}>
                            <Stack direction="row" spacing={2} width="100%">
                                <TextField
                                    size="small"
                                    value={searchValue}
                                    onChange={e => {
                                        const { name, value } = e.target;

                                        setSearchValue(value);
                                    }}
                                    placeholder="Insured, Facility Name, ID, Address"
                                    InputProps={{
                                        sx: {
                                            backgroundColor: 'background.deep',
                                        },
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Typography>
                                                    <SearchIcon
                                                        sx={{
                                                            fontSize: '18px',
                                                            marginTop: '6px',
                                                            color: 'text.primary',
                                                        }}
                                                    />
                                                </Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        width: '320px',
                                        color: '#CC5C5C',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderStyle: 'none',
                                        },
                                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                            display: 'none',
                                        },
                                        '& input[type=number]': {
                                            MozAppearance: 'textfield',
                                        },
                                    }}
                                />
                                <InputFieldSelect
                                    height="40px"
                                    color="background.paper"
                                    borderColor="background.paper"
                                    label=""
                                    id="submited_on"
                                    value={status}
                                    onChange={e => {
                                        const { name, value } = e.target;

                                        setStatus(value);
                                    }}
                                >
                                    <MenuItem value="placeholder">
                                        <Typography
                                            sx={{ color: 'text.discrete', fontStyle: 'oblique', overflow: 'clip' }}
                                        >
                                            Submission Status
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem value="Draft">Draft</MenuItem>
                                    <MenuItem value="Pending Quote">Pending Quote</MenuItem>
                                    <MenuItem value="Pending Acceptance">Pending Acceptance</MenuItem>
                                    <MenuItem value="Pending Authority">Pending Authority</MenuItem>
                                    <MenuItem value="Pending Binding">Pending Binding</MenuItem>
                                    <MenuItem value="Broker Declined">Broker Declined</MenuItem>
                                    <MenuItem value="Authority Declined">Authority Declined</MenuItem>
                                    <MenuItem value="Completed">Completed</MenuItem>
                                </InputFieldSelect>
                                <DateRangePicker
                                    value={
                                        renewalRange
                                            ? [
                                                  renewalRange[0] == null ? null : parseISO(renewalRange[0]),
                                                  renewalRange[1] == null ? null : parseISO(renewalRange[1]),
                                              ]
                                            : undefined
                                    }
                                    onChange={value => {
                                        const newValue: DateRangeEntry[] = value.map(date =>
                                            date == null ? null : format(date, 'yyyy-MM-dd'),
                                        );

                                        setRenewalRange([
                                            newValue[0] ? newValue[0] : null,
                                            newValue[1] ? newValue[1] : null,
                                        ]);
                                    }}
                                    slots={{ field: SingleInputDateRangeField }}
                                    slotProps={{
                                        layout: {
                                            sx: {
                                                '& .MuiDayCalendar-weekDayLabel': {
                                                    color: 'text.discrete',
                                                },
                                            },
                                        },
                                        textField: {
                                            size: 'small',
                                            InputProps: {
                                                sx: { backgroundColor: 'background.deep' },
                                                placeholder: 'Renewal',
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <DateRangeIcon sx={{ color: 'text.primary' }} />
                                                    </InputAdornment>
                                                ),
                                            },
                                        },
                                        day: {
                                            sx: {
                                                '&:disabled:not(.Mui-selected)': {
                                                    color: 'text.discrete',
                                                },
                                            },
                                        },
                                    }}
                                    sx={{
                                        width: '180px',
                                        color: '#CC5C5C',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderStyle: 'none',
                                        },
                                        label: {
                                            color: 'text.primary',
                                        },
                                    }}
                                />
                                <DateRangePicker
                                    value={
                                        submissionRange
                                            ? [
                                                  submissionRange[0] == null ? null : parseISO(submissionRange[0]),
                                                  submissionRange[1] == null ? null : parseISO(submissionRange[1]),
                                              ]
                                            : undefined
                                    }
                                    onChange={value => {
                                        const newValue: DateRangeEntry[] = value.map(date =>
                                            date == null ? null : format(date, 'yyyy-MM-dd'),
                                        );

                                        setSubmissionRange([
                                            newValue[0] ? newValue[0] : null,
                                            newValue[1] ? newValue[1] : null,
                                        ]);
                                    }}
                                    slots={{ field: SingleInputDateRangeField }}
                                    slotProps={{
                                        layout: {
                                            sx: {
                                                '& .MuiDayCalendar-weekDayLabel': {
                                                    color: 'text.discrete',
                                                },
                                            },
                                        },
                                        textField: {
                                            size: 'small',
                                            InputProps: {
                                                sx: { backgroundColor: 'background.deep' },
                                                placeholder: 'Submitted On',
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <DateRangeIcon sx={{ color: 'text.primary' }} />
                                                    </InputAdornment>
                                                ),
                                            },
                                        },
                                        day: {
                                            sx: {
                                                '&:disabled:not(.Mui-selected)': {
                                                    color: 'text.discrete',
                                                },
                                            },
                                        },
                                    }}
                                    sx={{
                                        width: '210px',
                                        color: '#CC5C5C',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderStyle: 'none',
                                        },
                                        label: {
                                            color: 'text.primary',
                                        },
                                    }}
                                />
                                <InputFieldSelect
                                    height="40px"
                                    color="background.paper"
                                    borderColor="background.paper"
                                    label=""
                                    id="submited_on"
                                    value={state}
                                    onChange={e => {
                                        const { name, value } = e.target;

                                        setState(value);
                                    }}
                                >
                                    <MenuItem value="placeholder">
                                        <Typography sx={{ color: 'text.discrete', fontStyle: 'oblique' }}>
                                            State
                                        </Typography>
                                    </MenuItem>
                                    {Object.values(UsState).map(state => (
                                        <MenuItem key={state} value={state}>
                                            {state}
                                        </MenuItem>
                                    ))}
                                </InputFieldSelect>
                                <BrokerSearchInput
                                    filter
                                    ref={brokerInputRef}
                                    width={'150px'}
                                    id={'test'}
                                    label=""
                                    onChange={(newValue: undefined | BrokerInfo) => {
                                        if (newValue != null) setBrokerage(newValue.id);
                                    }}
                                    onClear={() => {
                                        setBrokerage(null);
                                        setAgent('');
                                        handleClearAgentFilter();
                                    }}
                                />
                                <AgentSearchInput
                                    filter
                                    ref={agentInputRef}
                                    brokerId={brokerage}
                                    width={'150px'}
                                    id={'test'}
                                    label=""
                                    onChange={(newValue: undefined | AgentInfo) => {
                                        if (newValue != null) setAgent(newValue.id);
                                    }}
                                    onClear={() => {
                                        setAgent('');
                                    }}
                                />
                                <Button variant="text" size="small" onClick={handleClearFilter}>
                                    <Typography fontSize={12} fontWeight={500} color={'text.primary'}>
                                        CLEAR FILTERS
                                    </Typography>
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="column" spacing={2}>
                            {loading && (
                                <SubContainer>
                                    <Stack
                                        direction="column"
                                        justifyItems="center"
                                        sx={{ width: '100%', height: '200px' }}
                                    >
                                        <LoadingIndicator />
                                    </Stack>
                                </SubContainer>
                            )}
                            {!loading && applications.length > 0 && (
                                <ApplicationsTable
                                    data={
                                        debouncedSearch.trim() !== '' && debouncedSearch.trim().length >= 3
                                            ? searchResults
                                            : applications
                                    }
                                    canLoadMore={canLoadMore}
                                    onLoadMore={() => fetchData({ isLoadMore: true })}
                                />
                            )}
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};
