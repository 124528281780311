import { Link, Stack } from '@mui/material';

import { BlockText } from 'components/general/layout/block-text.component';
import { Container } from 'components/general/layout/container.component';
import { Layout } from 'components/general/layout/layout.component';
import { PageTitle } from 'components/general/layout/page-title.component';
import { Link as RouterLink } from 'react-router-dom';

type Props = {
    eraseError: () => void;
};

export const ErrorPage = ({ eraseError }: Props) => {
    return (
        <Layout>
            <PageTitle text="Sorry!" />
            <Container>
                <Stack direction="column" justifyContent="center" alignItems="center">
                    <BlockText mt label="Unexpected Error Ocurred!" />
                    <BlockText mt label="Please contact support@gcm.com" />
                    <Link
                        component={RouterLink}
                        to="/"
                        onClick={() => eraseError()}
                        sx={{
                            display: 'block',
                            mt: '8px',
                            fontFamily: 'roboto',
                            textDecoration: 'none',
                            fontSize: '14px',
                        }}
                    >
                        Go back to Home
                    </Link>
                </Stack>
            </Container>
        </Layout>
    );
};
