import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AddApplicationFacilityPage } from 'components/pages/applications/add-application/add-application-facility-page.component';
import { AddApplicationPage } from 'components/pages/applications/add-application/add-application-page.component';
import { ApplicationDetailsPage } from 'components/pages/applications/application-details-page/application-details-page.component';
import { ApplicationsPage } from 'components/pages/applications/application-page.component';
import ErrorBoundary from 'util/error/error-boundary.component';
import { ErrorPage } from 'components/pages/error/error-page.component';
import { HomePage } from 'components/pages/home/home-page.component';
import { LoginPage } from 'components/pages/auth/login-page.component';
import { PrivateRoutes } from './private-routes.component';
import { UnderwriterPage } from 'components/pages/underwriter/underwriter-page.component';

export const MainRouter = () => {
    return (
        <BrowserRouter>
            <ErrorBoundary>
                <Routes>
                    <Route path="/" element={<PrivateRoutes />}>
                        <Route index element={<HomePage />} />
                        <Route path="underwriter" element={<UnderwriterPage />} />
                        <Route path="applications" element={<ApplicationsPage />} />
                        <Route path="applications/new" element={<AddApplicationPage />} />
                        <Route path="applications/:id/:facility_id" element={<ApplicationDetailsPage />} />
                        <Route path="applications/:id/new" element={<AddApplicationFacilityPage />} />
                    </Route>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="*" element={<ErrorPage eraseError={() => {}} />} />
                </Routes>
            </ErrorBoundary>
        </BrowserRouter>
    );
};
