import axios, { AxiosError } from 'axios';

import Cookies from 'js-cookie';
import { authStopLoading } from 'store/features/auth/auth.slice';
import { toast } from 'react-toastify';

export const handleError = (error: unknown, dispatch: () => void) => {
    const parsedError = error as AxiosError | Error;

    if (axios.isAxiosError(parsedError)) {
        toast.error(`Error ${parsedError.code}: ${parsedError.message}`);
    }

    dispatch();
};
