import { Backdrop, Box, Button, Grid, Menu, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { orionApiUrl, setHeaders } from 'util/api/api.util';

import { FacilityInsuranceData } from '../../application-details-page.component';
import { FilesDisplay } from '../../../add-application/field-components/files-display.component';
import { LoadingIndicator } from 'components/general/loading/loading-indicator.component';
import { SimpleCheckboxInput } from '../../../add-application/field-components/simple-checkbox-input.component';
import { SimpleTextBoxInput } from '../../../add-application/field-components/simple-text-box-input.component';
import { SubContainer } from 'components/general/layout/sub-container.component';
import { UploadButton } from '../../../add-application/field-components/upload-button.component';
import { handleError } from 'util/error/handleError';
import { useState } from 'react';

export type FileInformation = {
    id?: number;
    application_id?: number;
    attachment_type?: string;
    file_name?: string;
    facilities: string[];
    description?: string;
    modified_by?: number;
    created_at?: string;
    updated_at?: string;
    presigned_url?: string;
};

type Props = {
    applicationId: string;
    uploadId: string;
    facilities: FacilityInsuranceData[];
    data: FileInformation[];
    onChange: (newValue: FileInformation[]) => void;
    byInsured?: boolean;
};

export const ApplicationFilesComponent = ({
    applicationId,
    uploadId,
    facilities,
    data,
    onChange,
    byInsured = false,
}: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [loadingHint, setLoadingHint] = useState<string>('');
    const [uploadDialogue, setUploadDialogue] = useState<boolean>(false);

    const [fileType, setFileType] = useState<string>('placeholder');
    const [targets, setTargets] = useState<string[]>([]);
    const [all, setAll] = useState<boolean>(false);
    const [description, setDescription] = useState<string>('');

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setUploadDialogue(true);
    };

    const handleClose = () => {
        setUploadDialogue(false);
        setFileType('placeholder');
        setAll(false);
        setTargets([]);
        setDescription('');
    };

    const handleFilesChange = (newValues: FileInformation[]) => {
        onChange([...newValues]);
    };

    const handleFileSubmit = async (file: File) => {
        try {
            const uploadData = new FormData();

            uploadData.append('application_id', applicationId);
            uploadData.append('attachment_type', fileType);
            uploadData.append('description', description);
            uploadData.append('file', file);

            targets.forEach(target => {
                uploadData.append('facilities', target);
            });
            setLoadingHint('Uploading File...');
            setLoading(true);

            const { data: newAttachment } = await orionApiUrl.post<FileInformation>(
                '/v1/attachment/',
                uploadData,
                setHeaders(),
            );

            handleClose();
            const newAttachments = [...data, newAttachment];

            onChange(newAttachments);
            setLoading(false);
        } catch (error) {
            handleError(error, () => {
                setLoading(false);
                console.log(`Oops..something went wrong uploading the file: ${file.name}`);
            });
        }
    };

    return (
        <>
            <Backdrop sx={{ zIndex: theme => theme.zIndex.drawer + 1 }} open={uploadDialogue}>
                <Box
                    sx={{
                        borderRadius: '5px',
                        padding: '24px',
                        overflowX: 'auto',
                        border: '0px none',
                        borderColor: 'primary.gray',
                        backgroundColor: 'background.paper',
                        width: '500px',
                    }}
                >
                    <Stack direction="column" spacing={2}>
                        <TextField
                            select
                            value={fileType}
                            onChange={event => {
                                const { value } = event.target;

                                setFileType(value);
                            }}
                            InputProps={{
                                sx: {
                                    height: '30px',
                                    backgroundColor: 'background.deep',
                                },
                            }}
                            sx={{
                                height: '30px',
                                color: '#CC5C5C',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderStyle: 'none',
                                },
                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                    display: 'none',
                                },
                                '& input[type=number]': {
                                    MozAppearance: 'textfield',
                                },
                            }}
                            label=""
                        >
                            <MenuItem disabled value="placeholder">
                                <Typography
                                    sx={{
                                        color: 'text.discrete',
                                        fontStyle: 'oblique',
                                    }}
                                >
                                    DocumentType
                                </Typography>
                            </MenuItem>
                            {byInsured && (
                                <MenuItem value="FIVE_YEARS_LOSS_RUNS">
                                    <Stack direction="row">
                                        <Typography>5 yrs Loss Runs</Typography>
                                    </Stack>
                                </MenuItem>
                            )}
                            {byInsured && (
                                <MenuItem value="ACORD_125_AND_126">
                                    <Stack direction="row">
                                        <Typography>ACORD 125 and 126 (for CGL)</Typography>
                                    </Stack>
                                </MenuItem>
                            )}
                            {!byInsured && (
                                <MenuItem value="BINDER">
                                    <Stack direction="row">
                                        <Typography>Binder(s)</Typography>
                                    </Stack>
                                </MenuItem>
                            )}
                            {byInsured && (
                                <MenuItem value="CASPER_REPORTS">
                                    <Stack direction="row">
                                        <Typography>CASPER Reports</Typography>
                                    </Stack>
                                </MenuItem>
                            )}
                            {!byInsured && (
                                <MenuItem value="COMPLETE_COPY_OF_POLICY_AS_ISSUED">
                                    <Stack direction="row">
                                        <Typography>Complete Copy of Policy as Issued</Typography>
                                    </Stack>
                                </MenuItem>
                            )}
                            {byInsured && (
                                <MenuItem value="CVS">
                                    <Stack direction="row">
                                        <Typography>CVs</Typography>
                                    </Stack>
                                </MenuItem>
                            )}
                            {byInsured && (
                                <MenuItem value="CURRENT_STATE_SURVEY">
                                    <Stack direction="row">
                                        <Typography>Current State Survey</Typography>
                                    </Stack>
                                </MenuItem>
                            )}
                            {byInsured && (
                                <MenuItem value="CURRENT_FACILITY_LICENSE">
                                    <Stack direction="row">
                                        <Typography>Current Facility License</Typography>
                                    </Stack>
                                </MenuItem>
                            )}
                            {!byInsured && (
                                <MenuItem value="DECLINATIONS">
                                    <Stack direction="row">
                                        <Typography>Declination(s)</Typography>
                                    </Stack>
                                </MenuItem>
                            )}
                            {!byInsured && (
                                <MenuItem value="ENDORSEMENTS">
                                    <Stack direction="row">
                                        <Typography>Endorsements</Typography>
                                    </Stack>
                                </MenuItem>
                            )}
                            {byInsured && (
                                <MenuItem value="FINANCIAL_STATEMENTS">
                                    <Stack direction="row">
                                        <Typography>Financial Statements</Typography>
                                    </Stack>
                                </MenuItem>
                            )}
                            {byInsured && (
                                <MenuItem value="OIG_PROPRIETARY_SUPPLEMENTAL_APPLICATION">
                                    <Stack direction="row">
                                        <Typography>OIG Proprietary Supplemental Application</Typography>
                                    </Stack>
                                </MenuItem>
                            )}
                            {!byInsured && (
                                <MenuItem value="NARRATIVE_EXPLANATIONS">
                                    <Stack direction="row">
                                        <Typography>Narrative Explanations</Typography>
                                    </Stack>
                                </MenuItem>
                            )}
                            {!byInsured && (
                                <MenuItem value="QUOTES">
                                    <Stack direction="row">
                                        <Typography>Quote(s)</Typography>
                                    </Stack>
                                </MenuItem>
                            )}
                            {!byInsured && (
                                <MenuItem value="TERRORISM_REJECTION_FORM">
                                    <Stack direction="row">
                                        <Typography>Terrorism Rejection</Typography>
                                    </Stack>
                                </MenuItem>
                            )}
                        </TextField>
                        <Stack direction="column" spacing={0} sx={{ width: '100%' }}>
                            <Typography>Check facilities associated to this document:</Typography>
                            <SimpleCheckboxInput
                                value={all}
                                id={'all'}
                                label={'Select All'}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const { checked } = event.target;
                                    const updatedTargets: string[] = [];

                                    if (checked) {
                                        facilities.forEach(fac => {
                                            const search: string = fac.facility_provider_number ?? 'N/A';

                                            updatedTargets.push(search);
                                        });
                                    }

                                    setTargets(updatedTargets);
                                    setAll(checked);
                                }}
                            />
                            {facilities.map((facility: FacilityInsuranceData, index: number) => (
                                <SimpleCheckboxInput
                                    key={index}
                                    value={targets.includes(facility.facility_provider_number ?? 'N/A')}
                                    id={facility.id ?? 'N/A'}
                                    label={facility.facility_name ?? `Facility #${facility.id}`}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const { checked } = event.target;
                                        const search: string = facility.facility_provider_number ?? 'N/A';
                                        const updatedTargets: string[] = [];

                                        targets.forEach(target => {
                                            if (target != search) updatedTargets.push(target);
                                        });

                                        if (checked) {
                                            updatedTargets.push(search);
                                        }

                                        setTargets(updatedTargets);
                                    }}
                                />
                            ))}
                        </Stack>
                        <SimpleTextBoxInput
                            id="description"
                            value={description}
                            rows={2}
                            placeholder="Notes"
                            onChange={event => {
                                const { name, value } = event.target;

                                setDescription(value);
                            }}
                        />
                        <Stack direction="row" justifyContent="flex-end" spacing={2}>
                            <Button onClick={handleClose}>
                                <Typography fontWeight={500} color={'text.primary'}>
                                    Cancel
                                </Typography>
                            </Button>
                            <UploadButton
                                id={uploadId}
                                disabled={fileType == 'placeholder' || targets.length <= 0}
                                onOpen={() => {}}
                                onSubmit={e => {
                                    if (!uploadDialogue) return;
                                    const event = e.target as HTMLInputElement;
                                    const file = event.files && event.files.length > 0 ? event.files[0] : null;

                                    if (file == null) {
                                        return;
                                    }

                                    console.log(`${file.name} | ${file.size}`);

                                    handleFileSubmit(file);

                                    event.files = null;
                                }}
                            />
                        </Stack>
                    </Stack>
                </Box>
            </Backdrop>
            <Grid
                item
                lg={6}
                xs={12}
                sx={{
                    '& .MuiBox-root': {
                        height: '100%',
                    },
                }}
            >
                <SubContainer sx={{ padding: '20px 30px' }}>
                    <Backdrop sx={{ zIndex: theme => theme.zIndex.drawer + 2 }} open={loading}>
                        <LoadingIndicator color="#000000BF" text_hint={loadingHint} />
                    </Backdrop>
                    <Stack direction="column" spacing={2}>
                        <Stack direction="row" alignItems="center">
                            <Typography variant="h6" sx={{ flexGrow: '1' }}>
                                {byInsured ? 'Files By Insured' : 'Files By Obsidian'}
                            </Typography>
                            <Button onClick={handleClick}>
                                <Typography fontWeight={500}>+ ADD FILE</Typography>
                            </Button>
                        </Stack>
                        <Grid container rowGap={4} sx={{ paddingTop: '24px' }}>
                            <Grid item xs={12}>
                                <Stack direction="column" spacing={2}>
                                    {byInsured && (
                                        <FilesDisplay
                                            facilities={facilities}
                                            readonly={loading}
                                            bold={false}
                                            label="5 yrs Loss Runs"
                                            attachment_type="FIVE_YEARS_LOSS_RUNS"
                                            value={data}
                                            width="100%"
                                            onChange={handleFilesChange}
                                            setLoading={(value: boolean, message: string) => {
                                                setLoadingHint(message);
                                                setLoading(value);
                                            }}
                                        />
                                    )}
                                    {byInsured && (
                                        <FilesDisplay
                                            facilities={facilities}
                                            readonly={loading}
                                            bold={false}
                                            label="ACORD 125 and 126 (for CGL)"
                                            attachment_type="ACORD_125_AND_126"
                                            value={data}
                                            width="100%"
                                            onChange={handleFilesChange}
                                            setLoading={(value: boolean, message: string) => {
                                                setLoadingHint(message);
                                                setLoading(value);
                                            }}
                                        />
                                    )}
                                    {!byInsured && (
                                        <FilesDisplay
                                            facilities={facilities}
                                            readonly={loading}
                                            bold={false}
                                            label="Binder(s)"
                                            attachment_type="BINDER"
                                            value={data}
                                            width="100%"
                                            onChange={handleFilesChange}
                                            setLoading={(value: boolean, message: string) => {
                                                setLoadingHint(message);
                                                setLoading(value);
                                            }}
                                        />
                                    )}
                                    {byInsured && (
                                        <FilesDisplay
                                            facilities={facilities}
                                            readonly={loading}
                                            bold={false}
                                            label="CASPER Reports"
                                            attachment_type="CASPER_REPORTS"
                                            value={data}
                                            width="100%"
                                            onChange={handleFilesChange}
                                            setLoading={(value: boolean, message: string) => {
                                                setLoadingHint(message);
                                                setLoading(value);
                                            }}
                                        />
                                    )}
                                    {!byInsured && (
                                        <FilesDisplay
                                            facilities={facilities}
                                            readonly={loading}
                                            bold={false}
                                            label="Complete copy of policy as issued"
                                            attachment_type="COMPLETE_COPY_OF_POLICY_AS_ISSUED"
                                            value={data}
                                            width="100%"
                                            onChange={handleFilesChange}
                                            setLoading={(value: boolean, message: string) => {
                                                setLoadingHint(message);
                                                setLoading(value);
                                            }}
                                        />
                                    )}
                                    {byInsured && (
                                        <FilesDisplay
                                            facilities={facilities}
                                            readonly={loading}
                                            bold={false}
                                            label="CVs"
                                            attachment_type="CVS"
                                            value={data}
                                            width="100%"
                                            onChange={handleFilesChange}
                                            setLoading={(value: boolean, message: string) => {
                                                setLoadingHint(message);
                                                setLoading(value);
                                            }}
                                        />
                                    )}
                                    {byInsured && (
                                        <FilesDisplay
                                            facilities={facilities}
                                            readonly={loading}
                                            bold={false}
                                            label="Current State Survey"
                                            attachment_type="CURRENT_STATE_SURVEY"
                                            value={data}
                                            width="100%"
                                            onChange={handleFilesChange}
                                            setLoading={(value: boolean, message: string) => {
                                                setLoadingHint(message);
                                                setLoading(value);
                                            }}
                                        />
                                    )}
                                    {byInsured && (
                                        <FilesDisplay
                                            facilities={facilities}
                                            readonly={loading}
                                            bold={false}
                                            label="Current Facility License"
                                            attachment_type="CURRENT_FACILITY_LICENSE"
                                            value={data}
                                            width="100%"
                                            onChange={handleFilesChange}
                                            setLoading={(value: boolean, message: string) => {
                                                setLoadingHint(message);
                                                setLoading(value);
                                            }}
                                        />
                                    )}
                                    {!byInsured && (
                                        <FilesDisplay
                                            facilities={facilities}
                                            readonly={loading}
                                            bold={false}
                                            label="Declination(s)"
                                            attachment_type="DECLINATIONS"
                                            value={data}
                                            width="100%"
                                            onChange={handleFilesChange}
                                            setLoading={(value: boolean, message: string) => {
                                                setLoadingHint(message);
                                                setLoading(value);
                                            }}
                                        />
                                    )}
                                    {!byInsured && (
                                        <FilesDisplay
                                            facilities={facilities}
                                            readonly={loading}
                                            bold={false}
                                            label="Endorsements"
                                            attachment_type="ENDORSEMENTS"
                                            value={data}
                                            width="100%"
                                            onChange={handleFilesChange}
                                            setLoading={(value: boolean, message: string) => {
                                                setLoadingHint(message);
                                                setLoading(value);
                                            }}
                                        />
                                    )}
                                    {byInsured && (
                                        <FilesDisplay
                                            facilities={facilities}
                                            readonly={loading}
                                            bold={false}
                                            label="Financial Statements"
                                            attachment_type="FINANCIAL_STATEMENTS"
                                            value={data}
                                            width="100%"
                                            onChange={handleFilesChange}
                                            setLoading={(value: boolean, message: string) => {
                                                setLoadingHint(message);
                                                setLoading(value);
                                            }}
                                        />
                                    )}
                                    {byInsured && (
                                        <FilesDisplay
                                            facilities={facilities}
                                            readonly={loading}
                                            bold={false}
                                            label="OIG proprietary supplemental application"
                                            attachment_type="OIG_PROPRIETARY_SUPPLEMENTAL_APPLICATION"
                                            value={data}
                                            width="100%"
                                            onChange={handleFilesChange}
                                            setLoading={(value: boolean, message: string) => {
                                                setLoadingHint(message);
                                                setLoading(value);
                                            }}
                                        />
                                    )}
                                    {!byInsured && (
                                        <FilesDisplay
                                            facilities={facilities}
                                            readonly={loading}
                                            bold={false}
                                            label="Narrative explanations"
                                            attachment_type="NARRATIVE_EXPLANATIONS"
                                            value={data}
                                            width="100%"
                                            onChange={handleFilesChange}
                                            setLoading={(value: boolean, message: string) => {
                                                setLoadingHint(message);
                                                setLoading(value);
                                            }}
                                        />
                                    )}
                                    {!byInsured && (
                                        <FilesDisplay
                                            facilities={facilities}
                                            readonly={loading}
                                            bold={false}
                                            label="Quote(s)"
                                            attachment_type="QUOTES"
                                            value={data}
                                            width="100%"
                                            onChange={handleFilesChange}
                                            setLoading={(value: boolean, message: string) => {
                                                setLoadingHint(message);
                                                setLoading(value);
                                            }}
                                        />
                                    )}
                                    {!byInsured && (
                                        <FilesDisplay
                                            facilities={facilities}
                                            readonly={loading}
                                            bold={false}
                                            label="Terrorism rejection form"
                                            attachment_type="TERRORISM_REJECTION_FORM"
                                            value={data}
                                            width="100%"
                                            onChange={handleFilesChange}
                                            setLoading={(value: boolean, message: string) => {
                                                setLoadingHint(message);
                                                setLoading(value);
                                            }}
                                        />
                                    )}
                                    {/* <FilesDisplay
                                    bold={false}
                                    label="Misc."
                                    attachment_type="misc"
                                    value={data}
                                    width="100%"
                                    onChange={handleFilesChange}
                                /> */}
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                </SubContainer>
            </Grid>
        </>
    );
};
